import React, { useId, useState } from 'react';
import './PageEditor.styles.css';
import PageList from './PageList';
import PagePreview from './PagePreview';

function PageEditor({ docId }) {
  const subscriberId = useId();
  const [currentPage, setCurrentPage] = useState(null);

  return (
    <div className='eclipse-page-editor-container'>
      <div className='eclipse-page-editor-left'>
        <PageList
          docId={docId}
          setCurrentPage={setCurrentPage}
          currentPage={currentPage}
          subscriberId={subscriberId}
        />
      </div>
      <div className='eclipse-page-editor-right'>
        <PagePreview docId={docId} currentPage={currentPage} />
      </div>
    </div>
  );
}

export default PageEditor;

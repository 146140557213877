import React from 'react';
import PDFRenderer from './PDFRenderer';

function PDFPreview({ gridContext, rowData, colData, pageConfig }) {
  const { gridApi } = gridContext || {};

  if (!gridApi) return null;

  return (
    <PDFRenderer
      rowData={rowData}
      colData={colData}
      pageConfig={pageConfig}
      gridContext={gridContext}
    />
  );
}

export default PDFPreview;

import { createSlice } from '@reduxjs/toolkit';

export const gridSlice = createSlice({
  name: 'grid',
  initialState: {
    scrollToNewRow: {
      rowId: null,
      gridId: null,
    },
  },
  reducers: {
    setScrollToNewRow: (state, action) => {
      state.scrollToNewRow = action.payload;
    },
    clearScrollToNewRow: (state) => {
      state.scrollToNewRow = {
        rowId: null,
        gridId: null,
      };
    },
  },
});

export const { setScrollToNewRow, clearScrollToNewRow } = gridSlice.actions;

export default gridSlice.reducer;

export const selectGrid = (state) => state.grid;
export const selectScrollToNewRow = (state) => state.grid.scrollToNewRow;

import { Avatar, Loader, Tooltip } from '@mantine/core';
import React, { forwardRef } from 'react';
import { useSelector } from 'react-redux';
import { useGetUserQuery } from '../../app/services/coreApi';
import { selectUserData } from '../../features/user/userSlice';

const usernameToColor = (username) => {
  const hash = username
    .split('')
    .map((char) => char.charCodeAt(0))
    .reduce((a, b) => a + b, 0);
  const colors = [
    'red',
    'pink',
    'grape',
    'violet',
    'indigo',
    'blue',
    'cyan',
    'teal',
    'green',
    'lime',
    'yellow',
    'orange',
  ];
  return colors[hash % colors.length];
};

const UserAvatar = forwardRef(({ user, suppressTooltip = false, ...rest }, ref) => {
  const stytchUser = useSelector(selectUserData);
  const { data: selfData } = useGetUserQuery(stytchUser.user.user_id, { skip: user });

  const userData = selfData || user;

  // Get users initials up to 3 characters
  const initials = userData?.display_name
    .split(' ')
    .map((name) => name[0])
    .join('')
    .toUpperCase()
    .slice(0, 3);

  if (!userData) {
    return <Loader size='xs' />;
  }

  if (suppressTooltip) {
    return (
      <Avatar ref={ref} color={usernameToColor(userData.display_name)} {...rest}>
        {initials}
      </Avatar>
    );
  }

  return (
    <Tooltip ref={ref} label={userData.display_name} withArrow>
      <Avatar color={usernameToColor(userData.display_name)} {...rest}>
        {initials}
      </Avatar>
    </Tooltip>
  );
});

export default UserAvatar;

function genericColDef(col) {
  return {
    // Data
    field: col.field,
    headerName: col.headerName,

    // Value
    valueGetter: undefined,

    // Editing
    editable: col.editable || undefined,

    // Grouping
    enableRowGroup: true,

    // Size / Position
    resizable: true,

    // Sorting
    sortable: true,
    comparator: (valueA, valueB, nodeA, nodeB, isInverted) => {
      try {
        if (valueA === null || valueB === null) return 1;
        if (isFinite(Number(valueA)) && isFinite(Number(valueB))) {
          return Number(valueA) - Number(valueB);
        }
        return valueA ? valueA.localeCompare(valueB || '') : ''.localeCompare(valueB || '');
      } catch (error) {
        console.log(valueA, valueB, nodeA, nodeB, isInverted);
        console.log(error);
        return 1;
      }
    },
  };
}

export default genericColDef;

import React from 'react';

function VerticalDivider() {
  return (
    <div
      style={{
        borderLeft: '2px solid rgba(88, 86, 82, 0.5)',
        height: '2rem',
        margin: '0 0',
      }}
    />
  );
}

export default VerticalDivider;

import { ActionIcon, Popover, Group, SegmentedControl } from '@mantine/core';
import React, { useEffect, useState } from 'react';
import abbreviateString from '../../../utils/abbreviate';
import ViewManager from './ViewManager';
import useHint from '../../../hooks/useHint';

// LIVEBLOCKS
import { useStorage } from '../../../app/liveblocksClient';
import { unpackColDef } from '../../../utils/coldefCompressor';

const VIEW_SEG_HINT =
  'Select a view to apply to the grid  |  [CTRL] + Left Click to save the current state to this view';

const VIEW_MENU_HINT = 'Manage views';

function ViewController({ context }) {
  // General
  const { dataView, gridApi } = context;

  // Liveblocks
  const views = useStorage((root) => root.views);

  // Hints
  const viewSegHint = useHint(VIEW_SEG_HINT, 'ViewControllerViewSeg');
  const viewMenuHint = useHint(VIEW_MENU_HINT, 'ViewControllerViewMenu');

  const dataViews = views?.get(dataView) || {};

  const data = views
    ? [...dataViews.entries()]
        .sort((a, b) => a[1].sort - b[1].sort)
        .map(([k, v]) => ({ value: k, label: v.name }))
    : [];

  const [segValue, setSegValue] = useState(null);
  const [menuOpen, setMenuOpen] = useState(false);

  // segmentData is the first 5 items of data
  const segmentData = data.slice(0, 5).map((item) => ({
    label:
      item.label.length > 8
        ? abbreviateString(item.label, { length: 9, keepSeparators: false, strict: false })
        : item.label,
    value: item.value,
  }));

  // If the view selected by segment is not in the data, set the segment value to null
  useEffect(() => {
    if (segValue && !data.find((item) => item.value === segValue)) {
      setSegValue(null);
    }
    // eslint-disable-next-line
  }, [data]);

  const handleSegmentSetView = (e) => {
    setSegValue(e);
    const config = JSON.parse(dataViews.get(e)?.data);
    const unpackedConfig = unpackColDef(config);

    setTimeout(() => {
      gridApi.applyColumnState({
        state: unpackedConfig,
        applyOrder: true,
      });
    }, 0);
  };

  return (
    <Group style={{ gap: 8 }}>
      <SegmentedControl
        data={segmentData}
        size='xs'
        value={segValue}
        onChange={handleSegmentSetView}
        ref={viewSegHint}
        color='#203b50'
        radius={'xl'}
      />
      <Popover
        shadow='md'
        opened={menuOpen}
        onClose={() => setMenuOpen(false)}
        width={400}
        withArrow
        styles={{
          dropdown: {
            backgroundColor: '#20252e',
          },
        }}
      >
        <Popover.Target>
          <ActionIcon
            className='action-with-dropdown'
            ref={viewMenuHint}
            variant='light'
            radius='sm'
            size='lg'
            onClick={() => setMenuOpen(!menuOpen)}
          >
            <span className='material-symbols-rounded'>pageview</span>
          </ActionIcon>
        </Popover.Target>
        <Popover.Dropdown>
          <ViewManager context={context} setSegValue={setSegValue} segmentData={segmentData} />
        </Popover.Dropdown>
      </Popover>
    </Group>
  );
}

export default ViewController;

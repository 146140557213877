import { useCombobox } from 'downshift';
import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';

import './AutocompleteStyles.css';
import { baseColumns } from '../../../constants/baseColumns';

const KEY_BACKSPACE = 8;
const KEY_DELETE = 46;
const KEY_ENTER = 13;
const KEY_TAB = 9;
const CREATE_ITEM_ID = '_CREATE_NEW_ITEM';

const SelectEditor = forwardRef((props, ref) => {
  // Ag-Grid State
  const { stopEditing } = props;
  const actualWidth = props.column.actualWidth || 200;
  const { mutateLiveCell } = props.context;

  // Get secondary params from column base definition
  const field = props.colDef.field;
  const colSecondaryParams = baseColumns.find((col) => col.field === field)?.secondaryParams;
  // Autocomplete Initial State
  const initalizeState = () => {
    let startValue;
    let selectAllOnFocus = true;

    if (props.eventKey === 'Backspace' || props.eventKey === 'Delete') {
      startValue = '';
    } else if (props.eventKey?.length === 1) {
      startValue = props.eventKey;
      selectAllOnFocus = false;
    } else {
      startValue = props.colDef?.valueFormatter(props) || '';
    }

    return {
      value: { id: props.value, value: startValue },
      selectAllOnFocus,
      initialKeyPress: props.eventKey,
    };
  };
  const initialState = initalizeState();

  const initializeSuggestions = () => {
    const getter = colSecondaryParams.selectEditItemGetter;
    return typeof getter === 'function' ? getter(props) : [];
  };

  const initialItems = () => {
    // If initial keypress, filter suggestions otherwise return all suggestions
    const char = initialState.initialKeyPress;
    const isAlphaNumeric = new RegExp(/^[0-9a-zA-Z]+$/).test(char) && char?.length === 1;

    if (char && isAlphaNumeric) {
      return suggestions.filter((item) =>
        item.toString().toLowerCase().startsWith(char.toLowerCase())
      );
    }
    return suggestions;
  };

  const [value, setValue] = useState(initialState.value);
  const [suggestions] = useState(initializeSuggestions());
  const [inputItems, setInputItems] = useState(initialItems());
  const [readyToCommit, setReadyToCommit] = useState(false);

  const { isOpen, getMenuProps, getInputProps, highlightedIndex, selectedItem, getItemProps } =
    useCombobox({
      initialIsOpen: true,
      defaultInputValue: value.value,
      defaultHighlightedIndex: value.id
        ? suggestions.findIndex((item) => item.id === value.id)
        : -1,
      items: inputItems,
      itemToString: (item) => (item ? item.value : ''),
      onInputValueChange: ({ inputValue }) => {
        const filteredItems = suggestions.filter((item) =>
          item.value.toString().toLowerCase().startsWith(inputValue.toString().toLowerCase())
        );
        const createItem = [];
        if (inputValue.length > 0 && colSecondaryParams.selectEditAllowCreate) {
          createItem.push({
            id: CREATE_ITEM_ID,
            value: inputValue,
            label: `Create new type: ' ${inputValue} '`,
          });
        }
        setInputItems([...filteredItems, ...createItem]);
      },
      onSelectedItemChange: (options) => {
        setReadyToCommit(true);
      },
    });

  const refInput = useRef(null);
  const ignoreKeyRef = useRef(true);

  // Handle keyboard
  const getCharCodeFromEvent = (event) => {
    event = event || window.event;
    return typeof event.which === 'undefined' ? event.keyCode : event.which;
  };

  const isLeftOrRight = (event) => {
    return [37, 39].indexOf(event.keyCode) > -1;
  };
  const deleteOrBackspace = (event) => {
    return [KEY_DELETE, KEY_BACKSPACE].indexOf(event.keyCode) > -1;
  };

  const isTab = (event) => {
    const charCode = getCharCodeFromEvent(event);
    return charCode === KEY_TAB;
  };

  const finishedEditingPressed = (event) => {
    const charCode = getCharCodeFromEvent(event);
    return charCode === KEY_ENTER;
  };

  const onKeyDown = useCallback((event) => {
    // If the keypress is the initial ENTER keypress to start editing, ignore it
    if (ignoreKeyRef.current) {
      ignoreKeyRef.current = false;
      return;
    }
    if (isTab(event)) {
      event.stopPropagation();
      refInput.current.focus();
      return;
    }
    if (isLeftOrRight(event) || deleteOrBackspace(event)) {
      event.stopPropagation();
      return;
    }

    if (finishedEditingPressed(event)) {
      if (event.preventDefault) event.preventDefault();
      // If not in the dropdown context, commit the value
      if (highlightedIndex <= 0) {
        setReadyToCommit(true);
      }
    }

    //eslint-disable-next-line
  }, []);

  function commitNewValue() {
    if (!selectedItem) return;
    if (
      selectedItem.id === CREATE_ITEM_ID &&
      colSecondaryParams.selectEditItemCreator &&
      typeof colSecondaryParams.selectEditItemCreator === 'function'
    ) {
      colSecondaryParams.selectEditItemCreator(props, selectedItem.value);
      return;
    }
    mutateLiveCell({ ...props, newValue: selectedItem.id });
    setReadyToCommit(false);
  }

  useEffect(() => {
    // console.log('EVENT HANDLER ADDED');
    window.addEventListener('keydown', onKeyDown);
    return () => {
      // console.log('EVENT HANDLER REMOVED');
      window.removeEventListener('keydown', onKeyDown);
    };
    //eslint-disable-next-line
  }, [onKeyDown, ignoreKeyRef.current]);

  useEffect(() => {
    if (readyToCommit) {
      commitNewValue();
      stopEditing();
    }
    //eslint-disable-next-line
  }, [readyToCommit]);

  useImperativeHandle(ref, () => {
    return {
      getValue() {
        return value;
      },

      isCancelBeforeStart() {
        return false;
      },

      isCancelAfterEnd() {
        return true;
      },
    };
  });

  return (
    <div style={{ width: actualWidth, height: '40px' }} className=''>
      <div className='combobox' style={{ width: '100%', height: '100%' }}>
        <input
          {...getInputProps({
            type: 'text',
            ref: refInput,
            style: { width: `100%`, height: '100%' },
            className: 'ag-input-field ag-text-field-input',
          })}
        />
      </div>
      <ul {...getMenuProps()} className='autocomplete-menu'>
        {isOpen &&
          inputItems.map((item, index) => (
            <li
              className='autocomplete-menu-item'
              style={highlightedIndex === index ? { backgroundColor: 'rgba(33,150,243,0.3)' } : {}}
              key={`${item.id}${index}`}
              {...getItemProps({ item: item.value, index })}
            >
              {item.label || item.value}
              {/* {`${item.label || item.value} (${item.id})`} */}
            </li>
          ))}
      </ul>
      {/* Catch the tab out from the menu and throw to input */}
      <div
        className='autocomplete-tab-guard'
        tabIndex='0'
        onFocus={() => {
          if (refInput.current) {
            refInput.current.focus();
          }
        }}
      ></div>
    </div>
  );
});

export default SelectEditor;

import { useCombobox } from 'downshift';
import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';

import './AutocompleteStyles.css';

const KEY_BACKSPACE = 8;
const KEY_DELETE = 46;
const KEY_ENTER = 13;
const KEY_TAB = 9;

const AutocompleteEditor = forwardRef((props, ref) => {
  const { mutateLiveCell } = props.context;

  // Ag-Grid State
  const { stopEditing } = props;
  const actualWidth = props.column.actualWidth || 200;
  const gridApi = props.api;
  const field = props.colDef.field;

  // Autocomplete Initial State
  const initalizeState = () => {
    let startValue;
    let selectAllOnFocus = true;

    if (props.eventKey === 'Backspace' || props.eventKey === 'Delete') {
      startValue = '';
    } else if (props.eventKey?.length === 1) {
      startValue = props.eventKey;
      selectAllOnFocus = false;
    } else {
      startValue = props.value || '';
    }

    return { value: startValue, selectAllOnFocus, initialKeyPress: props.eventKey };
  };
  const initialState = initalizeState();

  const initializeSuggestions = () => {
    const suggestionSet = new Set();

    gridApi.forEachNode((node) => {
      if (node.allLeafChildren) {
        node.allLeafChildren.forEach((child) => {
          const val = gridApi.getValue(field, child);
          if (val) suggestionSet.add(val);
        });
      } else {
        const val = gridApi.getValue(field, node);
        if (val) suggestionSet.add(val);
      }
    });
    return Array.from(suggestionSet);
  };

  const initialItems = () => {
    // If initial keypress, filter suggestions otherwise return all suggestions
    if (initialState.initialKeyPress) {
      return suggestions.filter((item) =>
        item.toString().toLowerCase().startsWith(initialState.initialKeyPress.toLowerCase())
      );
    }
    return suggestions;
  };

  const [value, setValue] = useState(initialState.value);
  const [suggestions] = useState(initializeSuggestions());
  const [inputItems, setInputItems] = useState(initialItems());

  const {
    isOpen,
    getMenuProps,
    getInputProps,
    highlightedIndex,
    getItemProps,
    setHighlightedIndex,
  } = useCombobox({
    initialIsOpen: true,
    defaultInputValue: value,
    items: inputItems,
    onInputValueChange: ({ inputValue }) => {
      setInputItems(
        suggestions.filter((item) =>
          item.toString().toLowerCase().startsWith(inputValue.toString().toLowerCase())
        )
      );
    },
    onSelectedItemChange: ({ selectedItem }) => {
      setValue(selectedItem);
      setHighlightedIndex(-1);
    },
  });

  const refInput = useRef(null);
  const ignoreKeyRef = useRef(true);

  // Handle keyboard
  const getCharCodeFromEvent = (event) => {
    event = event || window.event;
    return typeof event.which === 'undefined' ? event.keyCode : event.which;
  };

  const isLeftOrRight = (event) => {
    return [37, 39].indexOf(event.keyCode) > -1;
  };
  const deleteOrBackspace = (event) => {
    return [KEY_DELETE, KEY_BACKSPACE].indexOf(event.keyCode) > -1;
  };

  const isTab = (event) => {
    const charCode = getCharCodeFromEvent(event);
    return charCode === KEY_TAB;
  };

  const finishedEditingPressed = (event) => {
    const charCode = getCharCodeFromEvent(event);
    return charCode === KEY_ENTER;
  };

  const onKeyDown = useCallback(
    (event) => {
      // If the keypress is the initial ENTER keypress to start editing, ignore it
      if (ignoreKeyRef.current) {
        ignoreKeyRef.current = false;
        return;
      }

      if (isTab(event)) {
        event.preventDefault();
        event.stopPropagation();
        if (isOpen) {
          // If the dropdown is open, select the highlighted item
          if (highlightedIndex >= 0 && highlightedIndex < inputItems.length) {
            const selectedValue = inputItems[highlightedIndex];
            setValue(selectedValue);
            commitNewValue(selectedValue, 'Select item');

            // Check if there is a next cell in the same row and focus it
            gridApi.tabToNextCell();
          } else {
            // If no item is highlighted, close the dropdown and focus on the input
            setHighlightedIndex(-1);
            commitNewValue(undefined, 'Close dropdown');

            gridApi.tabToNextCell();
          }
        } else {
          // If the dropdown is not open, submit the change
          commitNewValue(undefined, 'No dropdown');
          gridApi.tabToNextCell();
        }
      } else if (isLeftOrRight(event) || deleteOrBackspace(event)) {
        event.stopPropagation();
      } else if (finishedEditingPressed(event)) {
        if (event.preventDefault) event.preventDefault();
        // If not in the dropdown context, commit the value
        if (highlightedIndex === -1) {
          commitNewValue(undefined, 'Not in dropdown I');
          stopEditing();
        }
      }

      function commitNewValue(override, source = '') {
        // console.log('COMMIT SOURCE:', source);
        const value = override
          ? override
          : refInput.current?.value === ''
          ? null
          : refInput.current?.value;
        mutateLiveCell({ ...props, newValue: value });
      }
    },
    //eslint-disable-next-line
    [
      isOpen,
      inputItems,
      highlightedIndex,
      stopEditing,
      finishedEditingPressed,
      isTab,
      setHighlightedIndex,
    ]
  );

  useEffect(() => {
    // console.log('EVENT HANDLER ADDED');
    window.addEventListener('keydown', onKeyDown);
    return () => {
      // console.log('EVENT HANDLER REMOVED');
      window.removeEventListener('keydown', onKeyDown);
    };
    //eslint-disable-next-line
  }, [onKeyDown, ignoreKeyRef.current]);

  useImperativeHandle(ref, () => {
    return {
      getValue() {
        return value;
      },

      isCancelBeforeStart() {
        return false;
      },

      isCancelAfterEnd() {
        return true;
      },
    };
  });

  return (
    <div style={{ width: actualWidth, height: '40px' }} className=''>
      <div className='combobox' style={{ width: '100%', height: '100%' }}>
        <input
          {...getInputProps({
            type: 'text',
            ref: refInput,
            style: { width: `100%`, height: '100%' },
            className: 'ag-input-field ag-text-field-input',
          })}
        />
      </div>

      <ul {...getMenuProps()} className='autocomplete-menu'>
        {isOpen &&
          inputItems.map((item, index) => (
            <li
              className='autocomplete-menu-item'
              style={highlightedIndex === index ? { backgroundColor: 'rgba(33,150,243,0.3)' } : {}}
              key={`${item}${index}`}
              {...getItemProps({ item, index })}
            >
              {item}
            </li>
          ))}
      </ul>

      {/* Catch the tab out from the menu and throw to input */}
      <div
        className='autocomplete-tab-guard'
        tabIndex='0'
        onFocus={() => {
          if (refInput.current) {
            refInput.current.focus();
          }
        }}
      ></div>
    </div>
  );
});

export default AutocompleteEditor;

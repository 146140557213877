import { Avatar, Group, Loader, Menu, Text } from '@mantine/core';
import React, { forwardRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { coreApi, useGetUserQuery } from '../../app/services/coreApi';
import { selectUserData, logout } from '../../features/user/userSlice';
import { useSearchParams } from 'react-router-dom';

const usernameToColor = (username) => {
  const hash = username
    .split('')
    .map((char) => char.charCodeAt(0))
    .reduce((a, b) => a + b, 0);
  const colors = [
    'red',
    'pink',
    'grape',
    'violet',
    'indigo',
    'blue',
    'cyan',
    'teal',
    'green',
    'lime',
    'yellow',
    'orange',
  ];
  return colors[hash % colors.length];
};

const UserAvatarMenu = forwardRef(({ user, ...rest }, ref) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const dispatch = useDispatch();
  const stytchUser = useSelector(selectUserData);

  const { data: selfData } = useGetUserQuery(stytchUser.user.user_id, { skip: user });

  const [triggerLogout, { data }] = coreApi.endpoints.getLogout.useLazyQuery();

  const userData = selfData || user;

  const handleLogout = async () => {
    await triggerLogout();
    window.localStorage.clear();
    dispatch(logout());
  };

  const handleEdit = () => {
    setSearchParams({ ...Object.fromEntries(searchParams), editUserSettings: true });
  };

  // Get users initials up to 3 characters
  const initials = userData?.display_name
    .split(' ')
    .map((name) => name[0])
    .join('')
    .toUpperCase()
    .slice(0, 3);

  if (!userData) {
    return <Loader size='xs' />;
  }

  return (
    <Menu withArrow width={300} position='top' transitionProps={{ transition: 'pop' }} withinPortal>
      <Menu.Target>
        <Avatar
          color={usernameToColor(userData.display_name)}
          styles={{
            root: {
              cursor: 'pointer',
            },
          }}
          {...rest}
        >
          {initials}
        </Avatar>
      </Menu.Target>
      <Menu.Dropdown>
        <Menu.Item>
          <Group>
            <Avatar radius='xl' color={usernameToColor(userData.display_name)} />
            <div>
              <Text weight={500}>{selfData?.display_name || '?'}</Text>
              <Text size='xs' c='dimmed'>
                {selfData?.signup_email || '?'}
              </Text>
            </div>
          </Group>
        </Menu.Item>

        <Menu.Divider />

        <Menu.Label>Settings</Menu.Label>
        <Menu.Item onClick={handleEdit}>Account settings</Menu.Item>

        <Menu.Item onClick={handleLogout}>Logout</Menu.Item>
      </Menu.Dropdown>
    </Menu>
  );
});

export default UserAvatarMenu;

import {
  Box,
  Button,
  Card,
  Center,
  Divider,
  Group,
  Loader,
  LoadingOverlay,
  ScrollArea,
  Text,
  Textarea,
  TextInput,
  Title,
} from '@mantine/core';
import React, { useEffect, useState } from 'react';
import {
  useDeleteDocMutation,
  useGetDocQuery,
  useUpdateDocMutation,
} from '../../app/services/coreApi';
import { PERMIT_EDITOR, PERMIT_OWNER } from '../../constants/permissions';
import SharingBlock from './SharingBlock';

function DocConfig({ docId, onRemove }) {
  const [deleteDoc, { isLoading: isDeletingDoc, isSuccess: deleteSuccess, error: deleteError }] =
    useDeleteDocMutation();
  const {
    data: doc,
    isLoading,
    isSuccess,
    error,
  } = useGetDocQuery(docId, { skip: docId === undefined || deleteSuccess });
  const [updateDoc, { isLoading: isUpdatingDoc, isSuccess: isUpdateSuccess, error: updateError }] =
    useUpdateDocMutation({ skip: deleteSuccess });
  const [isPristine, setIsPristine] = useState(true);
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [dangerMode, setDangerMode] = useState(false);

  const hasEditPermission = doc?.permission >= PERMIT_EDITOR;
  const hasOwnerPermission = doc?.permission === PERMIT_OWNER;

  const handleTitleChange = (e) => {
    setTitle(e.currentTarget.value);
    setIsPristine(false);
  };

  const handleDescChange = (e) => {
    setDescription(e.currentTarget.value);
    setIsPristine(false);
  };

  const handleReset = () => {
    setTitle(doc.title);
    setDescription(doc.description);
    setIsPristine(true);
  };

  const handleUpdate = () => {
    updateDoc({ id: docId, body: { title, description } });
  };

  useEffect(() => {
    // Populate initial title when doc is loaded
    if (isSuccess && doc) {
      setTitle(doc.title);
      setDescription(doc.description);
    }

    if (isUpdateSuccess && !isPristine) {
      setIsPristine(true);
    }
    // eslint-disable-next-line
  }, [doc, isSuccess, docId, isUpdateSuccess]);

  if (isLoading) {
    return (
      <Center>
        <Loader />
      </Center>
    );
  }

  if (error) {
    console.log(`Doc Fetch Error: `, error);
    return (
      <Center>
        <Text color={'red'}>There was an error loading this document.</Text>
      </Center>
    );
  }

  const handleDocDelete = async () => {
    const res = await deleteDoc(docId);
    if (res.data) {
      onRemove();
    }
  };

  return (
    <Box>
      <ScrollArea>
        <LoadingOverlay visible={isUpdatingDoc} />
        {/* <Title order={2} mb={3}>
          Document
        </Title> */}
        <Title order={3} mb={3}>
          Details
        </Title>
        <TextInput
          label={'Title'}
          value={title}
          onChange={handleTitleChange}
          disabled={!hasEditPermission || isUpdatingDoc}
        />
        <Textarea
          label={'Description'}
          value={description}
          onChange={handleDescChange}
          mt='xs'
          disabled={!hasEditPermission || isUpdatingDoc}
        />
        {updateError && (
          <Text c={'red'} align='center' mt='xs'>
            {updateError?.data?.message}
          </Text>
        )}
        {hasEditPermission && (
          <Group justify='flex-end'>
            <Button mt='xs' disabled={isPristine} onClick={handleUpdate}>
              Save
            </Button>
            <Button mt='xs' disabled={isPristine} color='gray' onClick={handleReset}>
              Cancel
            </Button>
          </Group>
        )}

        <Divider mt='xl' mb='sm' />

        <Title order={3} mb='md'>
          Sharing
        </Title>
        <SharingBlock doc={doc} onRemove={onRemove} />

        <Divider mt='xl' mb='sm' />
        <Title order={3} mt='xl' mb='md'>
          Metadata
        </Title>
        <Group justify={'space-between'}>
          <Text size='sm' weight={500}>
            Last Updated
          </Text>
          <Text size='md' align='right'>
            {new Date(doc.updated_at).toLocaleString() || ''}
          </Text>
        </Group>
        <Group justify={'space-between'} mt='sm'>
          <Text size='sm' weight={500}>
            Document Created
          </Text>
          <Text size='md' align='right'>
            {new Date(doc.created_at).toLocaleString()}
          </Text>
        </Group>

        <Divider mt='xl' mb='sm' />
        {hasOwnerPermission && (
          <Center mt='xl' mb='xl'>
            <Button variant='outline' onClick={() => setDangerMode(!dangerMode)}>
              Delete Document
            </Button>
          </Center>
        )}
        {dangerMode && (
          <Card withBorder>
            <Text c='red' align='center'>
              Permenantly delete this document?
            </Text>
            <Center mt='md' mb='md'>
              <Button
                color='green'
                variant={'outline'}
                size='md'
                onClick={() => setDangerMode(false)}
                disabled={isDeletingDoc}
              >
                Keep this document active
              </Button>
            </Center>
            <Center mt='md' mb='md'>
              <Button color='red' size='xs' onClick={handleDocDelete} disabled={isDeletingDoc}>
                {isDeletingDoc ? <Loader size='xs' /> : 'Permenantly Delete Document'}
              </Button>
            </Center>
            {deleteError && (
              <Text c={'red'} align='center' mt='xs'>
                {deleteError?.data?.message}
              </Text>
            )}
          </Card>
        )}
      </ScrollArea>
    </Box>
  );
}

export default DocConfig;

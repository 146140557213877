import { baseColumns } from '../../../constants/baseColumns';

export default function genericFillHandler(params) {
  params.context.rangeFill = new Date().getTime();

  const columnBaseDef = baseColumns.find((col) => col.field === params.column.colDef.field);
  const columnHasCustomDataFiller =
    columnBaseDef?.secondaryParams?.valueFiller &&
    typeof columnBaseDef.secondaryParams.valueFiller === 'function';

  if (columnHasCustomDataFiller) {
    return columnBaseDef.secondaryParams.valueFiller(params);
  }
  return false;
}

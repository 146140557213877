import { createClient } from '@liveblocks/client';
import { createRoomContext } from '@liveblocks/react';
import { store } from './store';
import { selectUserToken } from '../features/user/userSlice';

const client = createClient({
  authEndpoint: async (roomId) => {
    const state = store.getState();
    const bearer = selectUserToken(state);
    const response = await fetch(`${localApiPath()}/room-key`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${bearer}`,
      },
      body: JSON.stringify({ roomId }),
    });
    return await response.json();
  },
});

export const {
  RoomProvider,
  useMyPresence,
  useUpdateMyPresence,
  useStorage,
  useStatus,
  useSelf,
  useOthers,
  useMutation,
  useCanUndo,
  useCanRedo,
  useUndo,
  useRedo,
} = createRoomContext(client);

function localApiPath() {
  if (process.env.NODE_ENV === 'development') {
    const protocol = window.location.protocol === 'http:' ? 'http:' : 'https:';
    const host = window.location.hostname;
    const port = host === 'localhost' || host.startsWith('10.0') ? ':3030' : '';
    return `${protocol}//${host}${port}`;
  }
  return `https://api.eclipse.tools`;
}

export const selectLiveFixtures = (root) => root.fixtures;
export const selectLiveFixDefs = (root) => root.fixdefs;

export default function fixDefFieldGetter(params) {
  const { data, colDef } = params;
  const fixDefs = params.context.additionalData;
  const { field } = colDef;

  if (data === undefined) return '';

  const value = data[field];

  if (value === 0) return 0;

  if (value) return value;

  if (!data.fType) return '';

  const fixDef = fixDefs.get(data.fType);

  if (!fixDef || !fixDef[field]) return '';
  return fixDef[field];
}

import React from 'react';
import { Document, Page, Text, View, StyleSheet, Font, PDFViewer } from '@react-pdf/renderer';
import './PDFRenderer.styles.css';
import openSansRegular from './PDFFonts/OpenSans-Regular.ttf';
import openSansLight from './PDFFonts/OpenSans-Light.ttf';
import openSansSemiBold from './PDFFonts/OpenSans-SemiBold.ttf';
import { FIXTURE_NUMBER } from '../DataGrid/colDefs/fixtureDefProperties';

Font.register({
  family: 'Open Sans',
  fonts: [
    { src: openSansRegular },
    { src: openSansLight, fontWeight: 300 },
    { src: openSansSemiBold, fontWeight: 600 },
  ],
});

const styles = StyleSheet.create({
  page: {
    flexDirection: 'column',
    backGroundColor: '#FFFFFF',
    paddingTop: '0.5in',
    paddingBottom: '0.5in',
    paddingHorizontal: '0.5in',
  },
  headerWrapper: {
    flexDirection: 'row',
    padding: 0,
    margin: 0,
    width: '100%',
    overflow: 'hidden',
  },
  headerColWrapper: {
    overflow: 'hidden',
    height: '30px',
    padding: 0,
    margin: 0,
  },
  headerCol: {
    color: 'black',
    textOverflow: 'ellipsis',
    fontFamily: 'Open Sans',
    padding: '0 6',
    fontSize: 8,
    fontWeight: '600',
    lineHeight: 1,
  },
  rowWrapper: {
    flexDirection: 'row',
    padding: 0,
    margin: 0,
    overflow: 'hidden',
    width: '100%',
    height: 30,
  },
  cellWrapper: {
    overflow: 'hidden',
    height: '30px',
    padding: '0 0 0 6',
    margin: 0,
    display: 'flex',
    justifyContent: 'center',
  },
  rowCell: {
    color: 'black',
    textOverflow: 'ellipsis',
    fontFamily: 'Open Sans',
    padding: '0',
    fontSize: 8,
    fontWeight: '400',
    lineHeight: 1,
    verticalAlign: 'middle',
    maxLines: 1,
  },
  fnumCell: {
    color: 'black',
    textOverflow: 'ellipsis',
    fontFamily: 'Open Sans',
    padding: '0',
    fontSize: 12,
    fontWeight: '300',
    lineHeight: 1.2,
    textAlign: 'center',
    maxLines: 1,
    verticalAlign: 'middle',
  },
});

function getFormattedValue(data, col, gridContext) {
  const formatter = col?.colDef?.valueFormatter;
  const formattedData = formatter ? formatter({ value: data, context: { ...gridContext } }) : data;

  // Handle group auto column here, as the formatters are not passed to the auto column
  // If col starts with 'ag-Grid-AutoColumn-', it's a group auto column
  if (col?.colId.startsWith('ag-Grid-AutoColumn-')) {
    // Extract actual column id from group auto column id
    const actualColId = col?.colId.split('-').pop();
    // Get the actual column definition
    const actualCol = gridContext?.gridApi?.getColumn(actualColId);
    // Get the formatter from the actual column definition
    const actualFormatter = actualCol?.colDef?.valueFormatter;
    // If the actual column has a formatter, use it to format the data
    if (actualFormatter) {
      return actualFormatter({ value: data, context: { ...gridContext } });
    }
  }

  return formattedData;
}

function PDFRenderer({ rowData, colData, pageConfig, gridContext }) {
  const { paperSize, orientation } = pageConfig;

  const pageSize = paperSize.toLowerCase();
  const pageOrientation = orientation === 'p' ? 'portrait' : 'landscape';

  return (
    <div style={{ height: '100%', width: '100%' }}>
      <PDFViewer style={{ height: '100%', width: '100%' }}>
        <Document title={'Eclipse Document'}>
          <Page size={pageSize} orientation={pageOrientation} style={styles.page}>
            {/* Table Headers */}
            <View fixed>
              <View style={styles.headerWrapper}>
                {colData.map((col, index) => {
                  const actualWidth = col.actualWidth;
                  const inchesWidth = (actualWidth / 72).toFixed(4);

                  return (
                    <View
                      style={{
                        ...styles.headerColWrapper,
                        width: `${inchesWidth}in`,
                        minWidth: `${inchesWidth}in`,
                      }}
                      key={index}
                    >
                      <Text
                        style={styles.headerCol}
                        hyphenationCallback={(w) => {
                          return [w];
                        }}
                      >
                        {col.colDef.headerName}
                      </Text>
                    </View>
                  );
                })}
              </View>
            </View>
            {/* Table Body */}
            <View
              style={{
                width: '100%',
              }}
            >
              {rowData.map((row, rowIndex) => {
                return (
                  <View
                    style={{
                      ...styles.rowWrapper,
                      backgroundColor: rowIndex % 2 === 0 ? '#f2f2f2' : '#fff',
                    }}
                    key={rowIndex}
                  >
                    {colData.map((col, colIndex) => {
                      const field = col.colId;
                      const actualWidth = col.actualWidth;
                      const inchesWidth = (actualWidth / 72).toFixed(4);
                      const data = gridContext?.gridApi?.getValue(field, row);
                      const formattedData = getFormattedValue(data, col, gridContext);
                      const cellIsFixtureNumber = field === FIXTURE_NUMBER;
                      const cellIsAutoColumn = field.startsWith('ag-Grid-AutoColumn');

                      let cellStyles = styles.rowCell;
                      if (cellIsFixtureNumber) {
                        cellStyles = styles.fnumCell;
                      }
                      if (cellIsAutoColumn) {
                        cellStyles = styles.rowCell;
                      }

                      return (
                        <View
                          style={{
                            ...styles.cellWrapper,
                            width: `${inchesWidth}in`,
                            minWidth: `${inchesWidth}in`,
                          }}
                          key={colIndex}
                        >
                          <Text style={cellStyles} hyphenationCallback={(w) => [w]}>
                            {formattedData}
                          </Text>
                        </View>
                      );
                    })}
                  </View>
                );
              })}
            </View>
          </Page>
        </Document>
      </PDFViewer>
    </div>
  );
}

export default PDFRenderer;

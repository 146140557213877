import AutocompleteEditor from '../editors/AutocompleteEditor';
import SelectEditor from '../editors/SelectEditor';

const genericGridConfig = {
  // Selection
  rowSelection: 'multiple',
  enableRangeSelection: true,
  suppressRowClickSelection: true,
  groupSelectsChildren: true,
  groupSelectsFiltered: true,

  // Fill
  enableFillHandle: true,

  // Grouping
  enableRowGroup: true,
  groupAllowUnbalanced: false,

  // Sidebars
  sideBar: {
    toolPanels: [
      {
        id: 'columns',
        labelDefault: 'Columns',
        labelKey: 'columns',
        iconKey: 'columns',
        toolPanel: 'agColumnsToolPanel',
        toolPanelParams: {
          suppressPivotMode: true,
        },
      },
    ],
  },

  // Navigation
  enterNavigatesVerticallyAfterEdit: true,

  // Components
  components: {
    AutocompleteEditor,
    SelectEditor,
  },

  // Columns
  lockVisible: false,
  autoGroupColumnDef: {
    cellRenderer: 'agGroupCellRenderer',
    cellRendererParams: {
      checkbox: true,
    },
  },
};

export default genericGridConfig;

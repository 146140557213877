import { ActionIcon } from '@mantine/core';
import { nanoid } from 'nanoid';
import React from 'react';
import { useDispatch } from 'react-redux';
import { setScrollToNewRow } from '../../../features/grid/gridSlice';
import useHint from '../../../hooks/useHint';
import { useMutation } from '../../../app/liveblocksClient';
import { LiveObject } from '@liveblocks/client';

const NEW_ROW_HINT = 'Add a new row to the grid';

function GenericAddRowHandler({ context, itemName = 'Row' }) {
  const { rootBranch, gridId } = context;
  const dispatch = useDispatch();

  // LIVEBLOCKS
  const addLiveRow = useMutation(({ storage }) => {
    const newRowId = nanoid(7);
    dispatch(setScrollToNewRow({ rowId: newRowId, gridId }));
    const branch = storage.get(rootBranch);
    branch.set(newRowId, new LiveObject());
  }, []);

  // Hints
  const newRowHint = useHint(NEW_ROW_HINT, 'ActionCreateGenericNewRow');

  return (
    <ActionIcon variant='light' size='lg' onClick={addLiveRow} ref={newRowHint}>
      <span className='material-symbols-rounded'>add_box</span>
    </ActionIcon>
  );
}

export default GenericAddRowHandler;

import { Drawer, Modal } from '@mantine/core';
import React, { useState } from 'react';
import NavToggle from './NavToggle';
import { Outlet } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import DocDrawer from './DocDrawer';
import UserSettings from '../Users/UserSettings';

function AppFrame() {
  const [searchParams, setSearchParams] = useSearchParams();
  const edit = searchParams.get('editUserSettings') || undefined;

  const params = Object.fromEntries(searchParams);
  const clearEdit = () => {
    const { editUserSettings, ...rest } = params || {};
    setSearchParams(rest);
  };

  const [navOpened, setNavOpened] = useState(false);

  return (
    <div
      style={{
        height: 'calc(100vh - 1px)',
        width: '100vw',
        overflow: 'hidden',
      }}
    >
      <Drawer opened={edit} onClose={clearEdit} position='right'>
        <UserSettings />
      </Drawer>
      <Modal
        opened={navOpened}
        onClose={() => setNavOpened(false)}
        size={'xl'}
        padding={'md'}
        withCloseButton={true}
        title={'Documents'}
      >
        <DocDrawer close={() => setNavOpened(false)} />
      </Modal>
      <div style={{ position: 'absolute', top: 0, left: 0, zIndex: 100 }}>
        <NavToggle action={() => setNavOpened(true)} />
      </div>

      <div
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          height: '100%',
          width: '100%',
          overflow: 'hidden',
          zIndex: 50,
        }}
      >
        <Outlet />
      </div>
    </div>
  );
}

export default AppFrame;

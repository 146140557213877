import { baseColGroups, baseColumns } from '../../../constants/baseColumns';
import genericColDef from '../colDefs/genericColDef';
import fixDefFieldGetter from '../getters/fixDefFieldGetter';

function generateClassRules(col) {
  // Cell Class Rules
  const cellClassRules = {};

  // Get DataHint for field
  const dataHint = baseColumns.find((column) => column.field === col.field).dataHint || 'string';

  cellClassRules['eclipse-presence'] = (params) => {
    const awareness = [];

    params.context.others.forEach((other) => {
      if (other?.presence?.selectedCells && Array.isArray(other.presence.selectedCells)) {
        awareness.push(...other.presence.selectedCells);
      }
    });

    const dataView = params.context.dataView;
    // Awareness array contains tuples of [dataView, rowId, field]. If this cell is in the awareness array, return true
    const isAware = awareness
      .filter((item) => item[0] === dataView)
      .some((cursor) => cursor[1] === params.node.data._id && cursor[2] === params.colDef.field);
    if (isAware) return true;

    // Iterate the users in awareness that are not this user
    return false;
  };

  if (col.field === 'fFixN') {
    cellClassRules['eclipse-fixnum-emphasis'] = (params) => true;
  }

  if (dataHint && dataHint === 'numeric') {
    cellClassRules['eclipse-numeric-parser'] = (params) => true;
  }

  if (col.src === 'def') {
    cellClassRules['fxtr-def-inherit'] = (params) => {
      // If this is a group header row, return false
      if (params.data === undefined) return false;
      if (params.colDef.field === 'fType') return false;
      const baseColumn = baseColumns.find((col) => col.field === params.colDef.field);
      if (!baseColumn) return false;
      if (baseColumn.src === 'def') return true;
      return false;
    };

    cellClassRules['fxtr-def-override'] = (params) => {
      if (params.data === undefined) return false;
      if (params.colDef.field === 'fType') return false;
      const baseColumn = baseColumns.find((col) => col.field === params.colDef.field);
      if (!baseColumn) return false;
      const isNull = params.data[params.colDef.field] === null;
      const isUndefined = params.data[params.colDef.field] === undefined;
      const isEmpty = params.data[params.colDef.field] === '';
      if (baseColumn.src === 'def' && !isNull && !isUndefined && !isEmpty) return true;
      return false;
    };
  }

  if (col.field === 'fA01') {
    cellClassRules['fxtr-addr-invalid'] = (params) => {
      if (params.data === undefined) return false;
      if (params.data._id === 'NEW_ROW') return false;
      // if (params.data[col.field] === undefined) return false;
      if (dataHint === 'dmx') {
        // Get the patch width from the fixture definition
        const context = params.context;
        const fDef = context.additionalData?.get(params.data.fType);

        const patchWidth = params.data?.patchWidth || fDef?.fPWdt || 1;

        // If patch width + address is over 511, return true
        const startChannel = Number(params.data[params.colDef.field]);
        const lastChannel = Number(params.data[params.colDef.field]) + Number(patchWidth);
        const startUniverse = Math.floor(startChannel / 512);
        const lastUniverse = Math.floor(lastChannel / 512);
        if (startUniverse !== lastUniverse) return true;
      }
      return false;
    };
  }

  return cellClassRules;
}

function generateColumn(col, params = { omitClassRules: false }) {
  const { omitClassRules } = params;

  return {
    ...genericColDef(col),

    // Override Value Getter
    valueGetter: col.src === 'def' ? fixDefFieldGetter : undefined,
    cellClassRules: omitClassRules ? undefined : generateClassRules(col),

    ...col.params,
  };
}

export function calculateGridColumns(params) {
  const columnDefs = [];

  // Map baseColumns to ag-grid column definitions
  baseColumns
    .filter((col) => !col.parent)
    .forEach((col) => {
      // If this is a parent group, add the children
      columnDefs.push(generateColumn(col, params));
    });

  // Map baseColGroups to ag-grid column definitions
  baseColGroups.forEach((colGrp) => {
    const colGrpDef = {
      // ...colGrp,
      headerName: colGrp.headerName,
      groupId: colGrp.headerName,
      children: [
        ...baseColumns
          .filter((col) => col.parent === colGrp.headerName)
          .map((col) => generateColumn(col, params)),
      ],
    };
    columnDefs.push(colGrpDef);
  });

  return columnDefs;
}

import { Box } from '@mantine/core';
import React, { useState } from 'react';
import { useParams } from 'react-router';
import PreviewGrid from '../DataGrid/PreviewGrid';
import { RoomProvider } from '../../app/liveblocksClient';
import { FIXTURE_GRID_CONFIG } from '../DocEditor/DocEditorInnerTabs';

function RoomTest() {
  const { docId, roomId } = useParams();
  const [parentGridContext, setParentGridContext] = useState(null);
  const [gridViewPristine, setGridViewPristine] = useState(true);
  return (
    <Box style={{ width: '100%', height: '100%', overflow: 'hidden' }}>
      <RoomProvider id={roomId} initialPresence={{}}>
        <PreviewGrid
          config={FIXTURE_GRID_CONFIG}
          setParentGridContext={setParentGridContext}
          setGridViewPristine={setGridViewPristine}
          gridViewPristine={gridViewPristine}
        />
      </RoomProvider>
    </Box>
  );
}

export default RoomTest;

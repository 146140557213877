import { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { clearHint, setHint } from '../features/hints/hintsSlice';

function useHint(hint, src) {
  const [hovered, setHovered] = useState(null);
  const ref = useRef(null);
  const dispatch = useDispatch();

  const onMouseEnter = useCallback(() => setHovered(true), []);
  const onMouseLeave = useCallback(() => setHovered(false), []);

  // Refresh handlers if ref.current changes
  useEffect(() => {
    let current;
    if (ref.current && ref.current !== current) {
      current = ref.current;
      current.addEventListener('mouseenter', onMouseEnter);
      current.addEventListener('mouseleave', onMouseLeave);
    }
    return () => {
      if (current) {
        current.removeEventListener('mouseenter', onMouseEnter);
        current.removeEventListener('mouseleave', onMouseLeave);
      }
    };
    // eslint-disable-next-line
  }, [ref.current]);

  useEffect(() => {
    if (hovered) {
      dispatch(
        setHint({
          hint,
          src,
        })
      );
    } else {
      dispatch(clearHint({ src }));
    }
    // eslint-disable-next-line
  }, [hovered]);

  return ref;
}

export default useHint;

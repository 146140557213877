import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Stage, Layer } from 'react-konva';
import { useDebouncedState, useElementSize } from '@mantine/hooks';
import Konva from 'konva';
import { ActionIcon, Group, Modal, Slider } from '@mantine/core';
import CanvasGridLayer from '../Canvas/CanvasGridLayer';
import CanvasPageLayer from '../Canvas/CanvasPageLayer';
import '../DataGrid/dataGridStyles.css';
import './PagePreview.styles.css';
import PDFPreview from './PDFPreview';
import { useStorage } from '../../app/liveblocksClient';

const SHADOW_BLUR = 32;
const SCREEN_DPI = 72;
const PAGE_WIDTH = 8.5;
const PAGE_HEIGHT = 11;
const MARGIN = 0.5;
const SCALE_BY = 1.1;
const SCALE_MAX = 2.0;
const SCALE_MIN = 0.5;

const backgroundStyle = {
  width: '100%',
  height: 'calc(100% - 3rem)',
  overflow: 'hidden',
  backgroundColor: '#212121',
};

function passTotalPagesToParent(sectionConfig, setTotalPages) {
  setTotalPages(sectionConfig.totalPages);
}

function CanvasPreview({
  gridContext,
  currentPage,
  displayPage,
  setTotalPages,
  gridHasChanged,
  shouldPrint,
  setShouldPrint,
}) {
  // Refs
  const { ref, width, height } = useElementSize();
  const [rowData, setRowData] = useState([]);
  const [colData, setColData] = useState([]);
  const stageRef = useRef(null);
  const pageLayerRef = useRef(null);

  // State
  const [zoom, setZoom] = useState(1);
  const [debouncedZoom, setDebouncedZoom] = useDebouncedState(zoom, 200);

  // Live Blocks
  const paperSize = useStorage((root) => root.pages.get(currentPage)?.page);

  // Compiled State Params
  const refs = { stageRef, pageLayerRef, zoom, setZoom };

  // Config
  const pageConfig = useMemo(
    () => getPageConfig(zoom, paperSize),
    // eslint-disable-next-line
    [zoom, currentPage, paperSize, gridHasChanged]
  );
  const sectionConfig = useMemo(() => {
    const headerHeight = 30;
    const rowHeight = 30;
    const rowsPerPage = Math.floor(
      (pageConfig.pageHeight * pageConfig.screenDpi -
        headerHeight -
        rowHeight -
        pageConfig.margin * pageConfig.screenDpi) /
        rowHeight
    );

    const totalPages = Math.max(Math.ceil(rowData.length / rowsPerPage), 1);

    return {
      headerHeight,
      rowHeight,
      rowsPerPage,
      totalPages,
    };

    // eslint-disable-next-line
  }, [pageConfig, rowData, currentPage, gridHasChanged]);

  // Row/Col Data
  useEffect(() => {
    let timeout = null;
    timeout = setTimeout(() => {
      const compiledRowData = [];
      gridContext?.gridApi?.forEachNodeAfterFilterAndSort((node) => {
        compiledRowData.push(node);
      });

      const calcColGroups = gridContext?.gridApi?.getAllDisplayedColumnGroups() || [];
      const compiledColData = [];
      calcColGroups.forEach((colGrp) => colGrp.children.map((col) => compiledColData.push(col)));

      setColData(compiledColData);
      setRowData(compiledRowData);
    }, 0);
    return () => {
      if (timeout) clearTimeout(timeout);
    };
  }, [gridContext, currentPage, pageConfig, gridHasChanged]);

  // Effects
  useEffect(() => zoomToFitOnLoad(refs, zoomToFitWidth), [currentPage]); // eslint-disable-line
  useEffect(() => debounceZoomSlider(setDebouncedZoom, zoom), [zoom]); // eslint-disable-line
  useEffect(() => applyZoomSlider(stageRef, debouncedZoom), [debouncedZoom]);
  useEffect(
    () => passTotalPagesToParent(sectionConfig, setTotalPages),
    [setTotalPages, sectionConfig, currentPage, gridHasChanged]
  );

  return (
    <>
      <Modal
        opened={shouldPrint}
        onClose={() => setShouldPrint(false)}
        title='Print Preview'
        size={'xl'}
        centered
        styles={{
          content: {
            height: '100%',
          },
          body: {
            height: 'calc(100% - 60px)',
          },
        }}
      >
        <PDFPreview
          gridContext={gridContext}
          rowData={rowData}
          colData={colData}
          pageConfig={pageConfig}
        />
      </Modal>
      <div className='eclipse-preview-wrapper'>
        <FloatingPreviewZoom refs={refs} />
        <div style={backgroundStyle} ref={ref}>
          {width > 0 && height > 0 && (
            <Stage
              width={width}
              height={height}
              draggable
              onWheel={(e) => zoomStage(e, stageRef, setZoom)}
              ref={stageRef}
            >
              <Layer name='0 - Page' ref={pageLayerRef} listening={false}>
                <CanvasPageLayer pageConfig={pageConfig} sectionConfig={sectionConfig} />
              </Layer>

              <CanvasGridLayer
                currentPage={currentPage}
                gridContext={gridContext}
                pageConfig={pageConfig}
                stageRef={stageRef}
                rowData={rowData}
                colData={colData}
                sectionConfig={sectionConfig}
                displayPage={displayPage}
                gridHasChanged={gridHasChanged}
              />
            </Stage>
          )}
        </div>
      </div>
    </>
  );
}

export default CanvasPreview;

// UTILITY FUNCTIONS

function getPageConfig(zoom, paperSize) {
  const { d: dims, o: orientation, s: size } = paperSize;

  const pageWidth = dims[orientation === 'p' ? 0 : 1];
  const pageHeight = dims[orientation === 'p' ? 1 : 0];

  return {
    paperSize: size,
    orientation,
    pageWidth: pageWidth / SCREEN_DPI,
    pageHeight: pageHeight / SCREEN_DPI,
    screenDpi: SCREEN_DPI,
    margin: MARGIN,
    xStart: MARGIN * SCREEN_DPI,
    yStart: MARGIN * SCREEN_DPI,
    xEnd: pageWidth - MARGIN * SCREEN_DPI - MARGIN * SCREEN_DPI,
    yEnd: pageHeight - MARGIN * SCREEN_DPI - MARGIN * SCREEN_DPI,
    scale: zoom,
  };
}

function handleZoomChange(val, setZoom) {
  const constrainedZoom = (val / 100) * (SCALE_MAX - SCALE_MIN) + SCALE_MIN;
  setZoom(constrainedZoom);
}

function calcScale(val) {
  const scale = val * (SCALE_MAX - SCALE_MIN) + SCALE_MIN * 100;
  return scale;
}

function zoomToFitWidth(duration, refs) {
  const { stageRef, pageLayerRef, setZoom } = refs;
  const stage = stageRef.current;
  const bounds = pageLayerRef.current.getClientRect({ relativeTo: stageRef.current });

  const stageSize = stage.getSize();
  const scaleX = stageSize.width / bounds.width;
  const scaleY = stageSize.height / bounds.height;
  const scaleValue = Math.max(scaleX, scaleY) * 0.95;
  const offsetX = (stageSize.width - (bounds.width - SHADOW_BLUR * 2) * scaleValue) / 2;

  moveStage(
    stage,
    {
      location: { x: offsetX, y: 48 },
      scale: { x: scaleValue, y: scaleValue },
      duration,
    },
    setZoom
  );
}

function moveStage(stage, params, setZoom) {
  const { location, scale, duration = 0.35 } = params;

  const { x, y } = location;
  const tween = new Konva.Tween({
    duration,
    easing: Konva.Easings.EaseInOut,
    node: stage,
    onFinish: () => {
      setZoom(scale.x);
      tween.destroy();
    },
    scaleX: (scale && scale.x) || 1,
    scaleY: (scale && scale.y) || 1,
    x,
    y,
  });
  tween.play();
  stage.batchDraw();
}

function zoomStage(event, stageRef, setZoom) {
  event.evt.preventDefault();
  if (stageRef.current !== null) {
    const stage = stageRef.current;
    const oldScale = stage.scaleX();
    const { x: pointerX, y: pointerY } = stage.getPointerPosition();
    const mousePointTo = {
      x: (pointerX - stage.x()) / oldScale,
      y: (pointerY - stage.y()) / oldScale,
    };
    const newScale = event.evt.deltaY > 0 ? oldScale / SCALE_BY : oldScale * SCALE_BY;

    // Center the zoom at the pinch point
    const newX = pointerX - mousePointTo.x * newScale;
    const newY = pointerY - mousePointTo.y * newScale;

    const constrainedScale = Math.min(Math.max(newScale, SCALE_MIN), SCALE_MAX);
    const isConstrained = newScale !== constrainedScale;

    stage.scale({ x: constrainedScale, y: constrainedScale });
    setZoom(constrainedScale);

    if (!isConstrained) stage.position({ x: newX, y: newY });
    stage.batchDraw();
  }
}

function zoomToFitOnLoad(refs) {
  const { pageLayerRef } = refs;
  let timeout = null;
  timeout = setTimeout(() => {
    if (pageLayerRef.current) {
      zoomToFitWidth(0, refs);
    }
  }, 200);

  return () => {
    if (timeout) clearTimeout(timeout);
  };
}

function debounceZoomSlider(setDebouncedZoom, zoom) {
  setDebouncedZoom(zoom);
  // eslint-disable-next-line
}

function applyZoomSlider(stageRef, debouncedZoom) {
  // If stage zoom <> state zoom, update stage zoom
  if (stageRef.current && stageRef.current.scaleX() !== debouncedZoom) {
    stageRef.current.scale({ x: debouncedZoom, y: debouncedZoom });
    stageRef.current.batchDraw();
  }
}

// FLOATING PREVIEW ZOOM COMPONENT

const getLabel = (num) => {
  return `${Math.round(num)}%`;
};

function FloatingPreviewZoom(props) {
  const { refs } = props;
  const { zoom, setZoom } = refs;
  return (
    <div className='preview-floating-zoom-container'>
      <Group position='right'>
        <Slider
          label={getLabel}
          w={100}
          size={'xs'}
          scale={calcScale}
          value={zoom * 100 - SCALE_MIN * 100}
          onChange={(val) => handleZoomChange(val, setZoom)}
          marks={[
            {
              value: 0,
            },
            {
              value: 35,
            },
            {
              value: 100,
            },
          ]}
        />
        <ActionIcon variant='light' onClick={() => zoomToFitWidth(0.35, refs)} size='lg'>
          <span className='material-symbols-rounded'>fit_screen</span>
        </ActionIcon>
      </Group>
    </div>
  );
}

import * as math from 'mathjs';

export default function numericValueParser(params) {
  if (params.newValue === null || params.newValue === undefined) {
    return null;
  }

  // If the value ends in + or -, but is otherwise numeric, pass it as an untouched string
  if (typeof params.newValue === 'string' && params.newValue.match(/^[0-9.]+[+-]$/)) {
    return params.newValue;
  }

  const isExpression = typeof params.newValue === 'string' && params.newValue[0] === '=';
  if (isExpression) {
    return math.evaluate(params.newValue.slice(1));
  }

  const isNumeric = typeof params.newValue === 'number';

  // Use a regular expression to remove non-numeric characters
  const numericValue = isNumeric ? params.newValue : params.newValue.replace(/[^0-9.]/g, '');

  // Convert the result to a number
  const parsedValue = parseFloat(numericValue);

  // Check if the result is a valid number, if not, return null
  if (isNaN(parsedValue)) {
    return null;
  }

  return parsedValue;
}

import React from 'react';
import { useSelector } from 'react-redux';
import { Navigate, Outlet } from 'react-router-dom';
import { selectUserIsAuthenticated } from '../../features/user/userSlice';

function UnauthRoutes() {
  const isSignedIn = useSelector(selectUserIsAuthenticated);
  return !isSignedIn ? <Outlet /> : <Navigate to='/' />;
}

export default UnauthRoutes;

import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { store } from './app/store';
import App from './App';

import { Input, MantineProvider, Modal, Paper, Popover, createTheme } from '@mantine/core';
import { BrowserRouter } from 'react-router-dom';

import '@mantine/core/styles.css';
import './index.css';

const container = document.getElementById('root');
const root = createRoot(container);

const eclipse = [
  '#f3f4f7',
  '#e5e6e7',
  '#c7cbd0',
  '#a7aeb9',
  '#8b95a6',
  '#7a869a',
  '#707f96',
  '#5e6d83',
  '#536175',
  '#445369',
];

const theme = createTheme({
  fontFamily: 'Open Sans, sans-serif',
  primaryColor: 'eclipse',
  colors: {
    eclipse,
  },
  components: {
    Input: Input.extend({
      styles: {
        input: {
          backgroundColor: '#181c23',
        },
      },
    }),
    Modal: Modal.extend({
      styles: {
        content: {
          backgroundColor: 'rgb(32, 37, 46)',
        },
        header: {
          backgroundColor: 'rgb(32, 37, 46)',
        },
      },
    }),
    Paper: Paper.extend({
      styles: {
        root: {
          backgroundColor: 'rgb(32, 37, 46)',
        },
      },
    }),
    Popover: Popover.extend({
      styles: {
        dropdown: {
          backgroundColor: 'rgb(32, 37, 46)',
        },
      },
    }),
  },
});

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <BrowserRouter>
        <MantineProvider
          withNormalizeCSS
          defaultColorScheme='dark'
          forceColorScheme='dark'
          theme={theme}
        >
          <App />
        </MantineProvider>
      </BrowserRouter>
    </Provider>
  </React.StrictMode>
);

import { baseColGroups, baseColumns } from '../../../constants/baseColumns';

function generateColumn(col) {
  // Cell Class Rules
  const cellClassRules = {};

  // Get DataHint for field
  const dataHint = baseColumns.find((column) => column.field === col.field).dataHint || 'string';

  cellClassRules['eclipse-presence'] = (params) => {
    const awareness = [];

    params.context.others.forEach((other) => {
      if (other?.presence?.selectedCells && Array.isArray(other.presence.selectedCells)) {
        awareness.push(...other.presence.selectedCells);
      }
    });

    const dataView = params.context.dataView;
    // Awareness array contains tuples of [dataView, rowId, field]. If this cell is in the awareness array, return true
    const isAware = awareness
      .filter((item) => item[0] === dataView)
      .some((cursor) => cursor[1] === params.node.data._id && cursor[2] === params.colDef.field);
    if (isAware) return true;

    // Iterate the users in awareness that are not this user
    return false;
  };

  if (dataHint && dataHint === 'numeric') {
    cellClassRules['eclipse-numeric-parser'] = (params) => true;
  }

  return {
    resizable: true,
    field: col.field,
    headerName: col.headerName,
    editable: col.editable || undefined,
    cellClassRules,
    ...col.params,
    ...col.defConfig,
    ...col.defParams,
  };
}

export function calculateFixtureDefGridCols(dispatch) {
  const columnDefs = [];

  // Map baseColumns to ag-grid column definitions
  baseColumns
    .filter((col) => !col.parent && col.src === 'def')
    .forEach((col) => {
      // If this is a parent group, add the children
      columnDefs.push(generateColumn(col));
    });

  // Map baseColGroups to ag-grid column definitions
  baseColGroups.forEach((colGrp) => {
    const colGrpDef = {
      ...colGrp,
      children: [
        ...baseColumns
          .filter((col) => col.parent === colGrp.headerName && col.src === 'def')
          .map((col) => generateColumn(col)),
      ],
    };
    columnDefs.push(colGrpDef);
  });

  return columnDefs;
}

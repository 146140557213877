import React from 'react';
import DocList from '../DocList/DocList';
import { Container, Paper, Title } from '@mantine/core';

function Dashboard() {
  return (
    <Container size='md'>
      <Paper p='xl' shadow='md' style={{ marginTop: '3rem' }} withBorder>
        <Title order={2}>Documents</Title>
        <DocList />
      </Paper>
    </Container>
  );
}

export default Dashboard;

import {
  ActionIcon,
  Button,
  Card,
  Center,
  Container,
  Divider,
  Group,
  Loader,
  Stack,
  Text,
  TextInput,
  Title,
} from '@mantine/core';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  coreApi,
  useDeleteUserMutation,
  useGetUserQuery,
  useUpdateUserMutation,
} from '../../app/services/coreApi';
import { logout, selectUserData } from '../../features/user/userSlice';
import PencilIcon from '../Icons/PencilIcon';
import UserAvatar from './UserAvatar';
import useHint from '../../hooks/useHint';

const EDIT_NAME_HINT = 'Edit your public display name';

const usernameToColor = (username) => {
  const hash = username
    .split('')
    .map((char) => char.charCodeAt(0))
    .reduce((a, b) => a + b, 0);
  const colors = [
    'red',
    'pink',
    'grape',
    'violet',
    'indigo',
    'blue',
    'cyan',
    'teal',
    'green',
    'lime',
    'yellow',
    'orange',
  ];
  return colors[hash % colors.length];
};

function UserSettings() {
  const dispatch = useDispatch();
  const [shouldEdit, setShouldEdit] = useState(false);
  const [displayName, setDisplayName] = useState('');
  const [shouldUpdate, setShouldUpdate] = useState(false);
  const [dangerMode, setDangerMode] = useState(false);
  const [error, setError] = useState(null);

  const editNameHint = useHint(EDIT_NAME_HINT, 'UserSettingsEditName');

  const stytchUser = useSelector(selectUserData);
  const { data: user } = useGetUserQuery(stytchUser.user.user_id);
  const [triggerUpdateUser, { data: updatedUser, error: updateError, isLoading: updateLoading }] =
    useUpdateUserMutation();
  const [triggerLogout, { data }] = coreApi.endpoints.getLogout.useLazyQuery();
  const [triggerLogoutAll, { isLoading: logoutLoading }] =
    coreApi.endpoints.getLogoutAllSessions.useLazyQuery();
  const [triggerDeleteUser, { isLoading: deleteLoading }] = useDeleteUserMutation();

  useEffect(() => {
    let shouldCancel = false;
    if (shouldUpdate) {
      const makeUpdate = async () => {
        try {
          await triggerUpdateUser({ id: user.id, body: { display_name: displayName } });
          if (!shouldCancel) {
            setShouldEdit(false);
            setShouldUpdate(false);
          }
        } catch (error) {
          console.log('Update User Error', error);
          if (!shouldCancel) {
            setShouldEdit(false);
            setShouldUpdate(false);
          }
        }
      };
      makeUpdate();
    }
    return () => {
      shouldCancel = true;
    };
    // eslint-disable-next-line
  }, [shouldUpdate]);

  const handleEdit = () => {
    setShouldEdit(true);
    setDisplayName(user.display_name);
  };
  const canUpdate = displayName.length > 0 && displayName !== user.display_name;

  const handleUpdate = () => {
    setShouldUpdate(true);
  };

  const handleLogout = async () => {
    await triggerLogout();
    window.localStorage.clear();
    dispatch(logout());
  };

  const handleLogoutAll = async () => {
    await triggerLogoutAll();
    window.localStorage.clear();
    dispatch(logout());
  };

  const handleDeleteAccount = async () => {
    const res = await triggerDeleteUser(stytchUser.user.user_id);
    if (res.error) {
      setError(res.error);
      console.log(res);
    } else {
      window.localStorage.clear();
      dispatch(logout());
    }
  };

  return (
    <Container>
      {!user && (
        <Center>
          <Loader />
        </Center>
      )}
      {user && (
        <>
          <Center>
            <UserAvatar
              size='xl'
              radius='xl'
              color={usernameToColor(user.display_name)}
              user={user}
            />
          </Center>
          <Center mt='md'>
            {!shouldEdit && (
              <Group justify='center'>
                <Title order={3}>{user.display_name || ''}</Title>
                <ActionIcon
                  onClick={handleEdit}
                  mr='-48px'
                  variant='outline'
                  color='gray'
                  ref={editNameHint}
                >
                  <PencilIcon />
                </ActionIcon>
              </Group>
            )}
            {shouldEdit && (
              <Stack spacing={'xs'}>
                <TextInput
                  autoCorrect='off'
                  spellCheck='false'
                  size='lg'
                  placeholder='Display Name'
                  value={displayName}
                  onChange={(e) => setDisplayName(e.target.value)}
                  autoFocus
                />
                <Group justify='right' spacing={'xs'} mt={0}>
                  <Button color='blue' size='xs' disabled={!canUpdate} onClick={handleUpdate}>
                    {updateLoading ? <Loader size='xs' /> : 'Update'}
                  </Button>
                  <Button
                    variant='outline'
                    size='xs'
                    disabled={updateLoading}
                    onClick={() => setShouldEdit(false)}
                  >
                    Cancel
                  </Button>
                </Group>
                {updateError && (
                  <Text size='sm' color='red' align='center'>
                    {updateError?.data?.message}
                  </Text>
                )}
              </Stack>
            )}
          </Center>

          <Center mt='sm'>
            <Text size='sm'>{user.signup_email}</Text>
          </Center>
        </>
      )}
      <Center mt='md'>
        <Button variant='outline' color='blue' onClick={handleLogout}>
          Logout
        </Button>
      </Center>
      <Divider mt='xl' mb='xl' />
      <Center mt='md'>
        <Button variant='outline' color='blue' onClick={handleLogoutAll}>
          Logout of all devices
        </Button>
      </Center>
      <Center mt='md'>
        <Button variant='outline' color='red' onClick={() => setDangerMode(true)}>
          Permenantly Delete Account
        </Button>
      </Center>
      {dangerMode && (
        <Card withBorder mt='sm'>
          <Title order={3} color='red' align='center'>
            Are you sure?
          </Title>
          <Text color='red' align='center'>
            This will permanently delete your account and all owned documents. This will be
            irreversible. Are you sure?
          </Text>
          <Text mt='lg' c='green' ta='center'>
            No, keep my account active and my documents safe
          </Text>
          <Center>
            <Button
              color='green'
              variant={'outline'}
              size='lg'
              onClick={() => setDangerMode(false)}
              mt='lg'
            >
              CANCEL
            </Button>
          </Center>
          <Text mt='xl' c='red' ta='center'>
            Yes, permanently delete my account and all my documents
          </Text>
          <Center mt='md'>
            <Button color='red' size='xs' onClick={handleDeleteAccount}>
              DELETE
            </Button>
          </Center>
          {error && (
            <Text color='red' mt='md' size='xs'>
              {error.message || error.data?.message || 'General Error'}
            </Text>
          )}
        </Card>
      )}
    </Container>
  );
}

export default UserSettings;

const regexes = [/\s|\-|_|,/, /\W/, /[aieouäöü]/i, /[a-z]/, /[AIEOUÄÖÜ]/, /[A-Z0-9]/];
const digraphs = ['ch', 'gh', 'gn', 'kn', 'ph', 'qu', 'sh', 'th', 'wh', 'wr'];
const diblends = [
  'bl',
  'br',
  'cl',
  'cr',
  'fl',
  'fr',
  'gl',
  'gr',
  'pl',
  'pr',
  'sc',
  'sl',
  'sm',
  'sn',
  'sp',
  'st',
];
const trigraphs = ['chr', 'sch'];
const triblends = ['shr', 'spl', 'spr', 'squ', 'str', 'thr'];

export default function abbreviateString(str, { length, keepSeparators, strict }) {
  length = length || 3;
  keepSeparators = keepSeparators || false;
  strict = strict || true;
  if (length <= 0 && strict) return '';
  if (length >= str.length) return str;

  // trim
  str = str.replace(/^[\s\-_,]+/, '').replace(/[\s\-_,]+$/, '');
  if (length >= str.length) return str;

  const chars = str.split('');
  let pos = 1;
  const order = [pos];
  let orderedCount = 1;
  let word = 1;
  const words = [1];
  let sep = 0;
  let newWord = false;
  let found;

  // forward search for word beginnings
  for (let i = 1; i < chars.length; i++) {
    order.push(0);
    if (regexes[0].test(chars[i])) {
      words.push(0);
      newWord = true;
      sep++;
    } else {
      if (newWord) {
        newWord = false;
        word++;
        pos++;
        order[i] = pos;
        orderedCount++;
        if (i < chars.length + 2) {
          // search for trigraphs/triblends
          for (const tri of trigraphs.concat(triblends)) {
            if (
              tri[0] === chars[i].toLowerCase() &&
              tri[1] === chars[i + 1].toLowerCase() &&
              tri[2] === chars[i + 2].toLowerCase()
            ) {
              found = true;
              break;
            }
          }
          if (found) {
            found = false;
            pos++;
            order.push(pos);
            orderedCount++;
            pos++;
            order.push(pos);
            orderedCount++;
            words.push(word);
            words.push(word);
            i++;
            i++;
          } else if (i < chars.length + 1) {
            // search for digraphs/diblends
            for (const di of digraphs.concat(diblends)) {
              if (di[0] === chars[i].toLowerCase() && di[1] === chars[i + 1].toLowerCase()) {
                found = true;
                break;
              }
            }
            if (found) {
              found = false;
              pos++;
              order.push(pos);
              orderedCount++;
              words.push(word);
              i++;
            }
          }
        }
      }
      words.push(word);
    }
  }

  if (!strict) {
    let should = word;
    if (keepSeparators) {
      should += sep;
    }
    if (length < should) {
      length = should;
    }
  }

  // backward search for separators
  if (keepSeparators) {
    for (let i = 0; i < chars.length; i++) {
      if (words[i] === 0) {
        order[i] = pos;
        orderedCount++;
        pos++;
      }
    }
    pos = chars.length;
  } else {
    pos = chars.length;
    for (let i = chars.length - 1; i >= 0; i--) {
      if (words[i] === 0) {
        order[i] = pos;
        orderedCount++;
        pos--;
      }
    }
  }

  // backward search for remaining chars
  let j = 1;
  let unfinished = true;
  while (j < regexes.length && unfinished) {
    for (let i = chars.length - 1; i >= 0; i--) {
      if (order[i] === 0) {
        if (regexes[j].test(chars[i])) {
          order[i] = pos;
          orderedCount++;
          pos--;
          if (orderedCount === chars.length) {
            unfinished = false;
            break;
          }
        }
      }
    }
    j++;
  }

  // map selected chars
  const result = chars.map((val, i) => {
    if (order[i] <= length) {
      return val;
    } else {
      return '';
    }
  });

  return result.join('');
}

// REACT
import React, { useCallback, useEffect, useId, useMemo, useState } from 'react';

// LIVEBLOCKS
import { useMutation, useStorage } from '../../app/liveblocksClient';

// AG GRID
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-enterprise';

import 'ag-grid-community/styles/ag-grid.css'; // Core grid CSS, always needed
import 'ag-grid-community/styles/ag-theme-alpine.css'; // Optional theme CSS
import './dataGridStyles.css';

// GRID HANDLERS
import genericFillHandler from './fillers/genericFillHandler';
import { packColDef, unpackColDef } from '../../utils/coldefCompressor';
import { set } from 'lodash';

function PreviewGrid({
  currentPage,
  config,
  setParentGridContext = null,
  setViewIsPristine,
  viewIsPristine,
  setGridHasChanged,
}) {
  // GENERAL CONFIG
  const { dataSelector, rootBranch, additionalSubscription, getGridColumns, dataView } = config;
  const gridId = useId();

  // LIVEBLOCKS
  const data = useStorage(dataSelector);
  const additionalData = useStorage(additionalSubscription);
  const pageData = useStorage((root) => root.pages.get(currentPage));

  const mutatePageConfig = useMutation(
    ({ storage }, columnState) => {
      const page = storage.get('pages').get(currentPage);
      const packedConfig = packColDef(columnState);
      page.set('gridConfig', JSON.stringify(packedConfig));
      return true;
    },
    [currentPage]
  );

  // STATE
  const [cols, setCols] = useState(getGridColumns({ omitClassRules: true }));
  const [gridApi, setGridApi] = useState(null);
  const [isUpdatingGridConfig, setIsUpdatingGridConfig] = useState(false);

  // AG GRID CONFIG
  const { gridConfig } = config;

  const defaultColDef = useMemo(() => {
    return {
      enableCellChangeFlash: true,
      editable: false,
    };
  }, []);

  const gridContext = useMemo(() => {
    return {
      currentPage,
      rootBranch,
      dataView,
      additionalData,
      gridId,
    };
  }, [rootBranch, dataView, additionalData, gridId, currentPage]);

  // AG GRID EVENTS
  const handleGridReady = useCallback(
    (params) => {
      setGridApi(params.api);
      if (setParentGridContext && typeof setParentGridContext === 'function') {
        setParentGridContext({
          gridApi: params.api,
          additionalData,
          dataView,
          rootBranch,
        });
      }
    },
    [setParentGridContext, additionalData, dataView, rootBranch]
  );

  const updateGridConfig = useCallback(
    (params) => {
      // console.log('UPDATE GRID CONFIG', params);
      const gridApiIsReady = gridApi !== null;
      const updateIsToolPanel = params.source === 'toolPanelUi';
      const updateIsColumnResize =
        params.source === 'api' && params.type === 'columnResized' && params.columns.length === 1;

      if (!gridApiIsReady || (!updateIsToolPanel && !updateIsColumnResize)) return;

      setIsUpdatingGridConfig(true);
      // console.log('START GRID UPDATE');

      // Persist column state
      const colState = params.api.getColumnState();
      mutatePageConfig(colState);
      // Mark dirty
      setViewIsPristine(viewIsPristine + 1);
      setGridHasChanged(new Date().getTime());
      setIsUpdatingGridConfig(false);
      // console.log('END GRID UPDATE');
    },
    [mutatePageConfig, gridApi, viewIsPristine, setViewIsPristine, setGridHasChanged]
  );

  // PAGE CHANGE WATCHER
  // If the page changes, apply the page config to the grid
  useEffect(() => {
    if (!isUpdatingGridConfig && gridApi && pageData) {
      const pageConfig = JSON.parse(pageData.gridConfig);
      const unpackedConfig = unpackColDef(pageConfig);

      if (unpackedConfig.length === 0) {
        gridApi.resetColumnState();
        return;
      }

      gridApi.applyColumnState({
        state: unpackedConfig,
        applyOrder: true,
      });
    }
  }, [gridApi, pageData, currentPage, isUpdatingGridConfig]);

  // AG GRID DATA
  const rowData = useMemo(() => {
    if (!data || !cols || cols?.length === 0) return [];
    const res = [];
    // Iterate the map data and convert to array
    data.forEach((val, key) => {
      res.push({ _id: key, ...val });
    });
    return res;
  }, [data, cols]);

  const getRowId = useCallback((params) => {
    return params.data._id;
  }, []);

  return (
    <>
      <div
        className='ag-theme-quartz-dark invisible-grid-wrapper ag-no-border'
        style={{ height: 'calc(100% - 3rem)' }}
      >
        <AgGridReact
          context={gridContext}
          onGridReady={handleGridReady}
          immutableData={true}
          getRowId={getRowId}
          rowData={rowData}
          columnDefs={cols}
          fillOperation={genericFillHandler}
          defaultColDef={defaultColDef}
          suppressColumnStateEvents={false}
          rowDragManaged={true}
          {...gridConfig}
          // Disable Selection
          rowSelection='multiple'
          enableRangeSelection={false}
          suppressRowClickSelection={true}
          // Fill
          enableFillHandle={false}
          // Grouping
          enableRowGroup={true}
          groupDisplayType='multipleColumns'
          groupAllowUnbalanced={false}
          groupDefaultExpanded={-1}
          // Sidebars
          sideBar={{
            toolPanels: [
              {
                id: 'columns',
                labelDefault: 'Columns',
                labelKey: 'columns',
                iconKey: 'columns',
                toolPanel: 'agColumnsToolPanel',
                toolPanelParams: {
                  suppressPivotMode: true,
                },
              },
            ],
            defaultToolPanel: 'columns',
            position: 'right',
          }}
          // Columns
          lockVisible={false}
          autoGroupColumnDef={{
            cellRendererSelector: (params) => undefined,
            cellRendererParams: {
              checkbox: false,
            },
          }}
          // Events
          onDisplayedColumnsChanged={updateGridConfig}
          onColumnMoved={updateGridConfig}
          onColumnResized={updateGridConfig}
          onColumnVisible={updateGridConfig}
          onColumnPinned={updateGridConfig}
          onColumnRowGroupChanged={updateGridConfig}
          onColumnValueChanged={updateGridConfig}
          onColumnPivotChanged={updateGridConfig}
          onColumnGroupOpened={updateGridConfig}
          onRowDataUpdated={() => setGridHasChanged(new Date().getTime())}
        />
      </div>
    </>
  );
}

export default PreviewGrid;

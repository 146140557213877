import { dmxValueFormatter } from '../components/DataGrid/formatters/dmxValueFormatter';
import dmxValueParser from '../components/DataGrid/parsers/dmxValueParser';

import { nanoid } from 'nanoid';
import dmxAddressFillHandler from '../components/DataGrid/fillers/dmxAddressFillHandler';
import {
  FIXTURE_PURPOSE,
  FIXTURE_CLASS,
  FIXTURE_MANUFACTURER,
  FIXTURE_TYPE,
  FIXTURE_VOLTAGE,
  FIXTURE_POWER,
  FIXTURE_CURRENT,
  FIXTURE_PHASES,
  FIXTURE_PATCH_WIDTH,
  FIXTURE_DISTRO,
  FIXTURE_CIRCUIT_CABLE,
  FIXTURE_CIRCUIT_NUMBER,
  FIXTURE_CIRCUIT_NAME,
  FIXTURE_NUMBER,
  FIXTURE_ID,
  FIXTURE_GROUP,
  FIXTURE_POSITION,
  FIXTURE_UNIT,
  FIXTURE_MODE,
  FIXTURE_WEIGHT,
  FIXTURE_PRIMARY_ADDRESS,
  FIXTURE_DIMMER,
  FIXTURE_ACCESSORIES,
  FIXTURE_COLOR,
  FIXTURE_GOBO,
  FIXTURE_POSITION_OFFSET,
} from '../components/DataGrid/colDefs/fixtureDefProperties';
import powerFieldGetter from '../components/DataGrid/getters/powerFieldGetter';
import numericValueParser from '../components/DataGrid/parsers/numericValueParser';
import { LiveObject } from '@liveblocks/client';

// DataHints
// string = generic string
// alphanumeric = letters and numbers only
// numeric = numbers only

export const DATA_GRID_CELL_HINTS = {
  singleCell: 'Type to overwrite, [Enter] to edit',
  multiCell: 'Type to overwrite all, [Enter] to edit all',
  canIncrementDecrement: 'Auto increment or decrement with [+]/[-] and optional offset',
  canUseEquation: 'Start with [=] to use an equation',
  canUseDMXFormat: 'Enter as Universe.Channel or Absolute',
  canUseWeightFormat: 'Enter as #kg, #g, #lbs',
};

export const dataHintLegend = {
  canIncrementDecrement: {
    context: 'multi',
    dataTypes: ['string', 'alphanumeric'],
  },
  canUseEquation: {
    context: 'all',
    dataTypes: ['numeric'],
  },
  canUseDMXFormat: {
    context: 'all',
    dataTypes: ['dmx'],
  },
  canUseWeightFormat: {
    context: 'all',
    dataTypes: ['weight'],
  },
};

export const baseColGroups = [
  {
    headerName: 'Type',
  },
  {
    headerName: 'Position',
  },
  {
    headerName: 'Accessories',
  },
  {
    headerName: 'Data',
  },
  {
    headerName: 'Physical',
  },
  {
    headerName: 'Power',
  },
];

export const baseColumns = [
  {
    field: FIXTURE_ID,
    headerName: 'ID',
    editable: false,
    src: 'self',
    params: {
      hide: true,
    },
  },
  // {
  //   field: 'rowNum',
  //   headerName: 'Row Number',
  //   params: {
  //     valueGetter: (params) => {
  //       return params.node.rowIndex;
  //     },
  //   },
  // },
  {
    field: FIXTURE_NUMBER,
    headerName: 'Fixture #',
    src: 'self',
    dataHint: 'string',
    params: {
      cellDataType: 'text',
      rowDrag: true,
      width: 170,
      headerCheckboxSelection: true,
      checkboxSelection: (params) => {
        // True if cell renderer is not agGroupCellRenderer
        return !params.node.group;
      },
      showDisabledCheckboxes: false,
    },
  },

  {
    field: FIXTURE_TYPE,
    headerName: 'Fixture Type',
    parent: 'Type',
    src: 'def',
    dataHint: 'string',
    defConfig: {
      headerName: 'Type Name',
    },
    defParams: {
      cellEditor: undefined,
      cellEditorPopup: false,
      valueFormatter: undefined,
      headerCheckboxSelection: true,
      checkboxSelection: (params) => {
        // True if cell renderer is not agGroupCellRenderer
        return !params.node.group;
      },
      showDisabledCheckboxes: false,
    },
    params: {
      width: 170,
      cellEditor: 'SelectEditor',
      cellEditorPopup: true,
      valueFormatter: (params) => {
        if (!params.value) return '';
        const fixDefs = params.context.additionalData;
        if (!fixDefs) return '';
        const fDef = fixDefs.get(params.value);
        if (fDef?.fType) {
          return fDef.fType;
          // return fDef.fType + ' - ' + params.value;
        }
        return params.value;
      },
    },
    secondaryParams: {
      selectEditAllowCreate: true,
      selectEditItemGetter: (params) => {
        const fixDefs = params.context.additionalData;
        const items = [];
        fixDefs.forEach((val, key) => {
          if (val.fType) items.push({ id: key, value: val.fType });
        });
        return items.sort((a, b) => a.value.localeCompare(b.value));
      },
      selectEditItemCreator: (params, value) => {
        const { mutateLiveCell } = params.context;

        const mutations = [];
        const newDef = { _id: nanoid(7) };

        // Create new Type Def
        mutations.push({
          path: ['fixdefs', newDef._id],
          value: new LiveObject(),
          action: 'update',
        });
        mutations.push({
          path: ['fixdefs', newDef._id, 'fType'],
          value,
          action: 'update',
        });

        mutateLiveCell({ ...params, newValue: newDef._id }, null, mutations);
      },
    },
  },
  {
    field: FIXTURE_MANUFACTURER,
    headerName: 'Manufacturer',
    parent: 'Type',
    src: 'def',
    dataHint: 'string',
    defConfig: {
      cellEditor: 'AutocompleteEditor',
      cellEditorPopup: true,
    },
    params: {
      width: 140,
    },
  },
  {
    field: FIXTURE_CLASS,
    headerName: 'Class',
    parent: 'Type',
    src: 'def',
    dataHint: 'string',
    defConfig: {
      headerName: 'Class',
    },
    params: {
      width: 140,
      cellEditor: 'AutocompleteEditor',
      cellEditorPopup: true,
    },
  },
  {
    field: FIXTURE_ACCESSORIES,
    headerName: 'Accessories',
    parent: 'Accessories',
    src: 'self',
    dataHint: 'string',
    params: {
      width: 170,
      cellEditor: 'AutocompleteEditor',
      cellEditorPopup: true,
    },
  },
  {
    field: FIXTURE_COLOR,
    headerName: 'Color',
    parent: 'Accessories',
    src: 'self',
    dataHint: 'string',
    params: {
      width: 170,
      cellEditor: 'AutocompleteEditor',
      cellEditorPopup: true,
    },
  },
  {
    field: FIXTURE_GOBO,
    headerName: 'Gobo',
    parent: 'Accessories',
    src: 'self',
    dataHint: 'string',
    params: {
      width: 170,
      cellEditor: 'AutocompleteEditor',
      cellEditorPopup: true,
    },
  },
  {
    field: FIXTURE_GROUP,
    headerName: 'Group',
    parent: 'Position',
    src: 'self',
    dataHint: 'string',
    params: {
      cellDataType: 'text',
      width: 140,
      cellEditor: 'AutocompleteEditor',
      cellEditorPopup: true,
    },
  },
  {
    field: FIXTURE_POSITION,
    headerName: 'Position',
    parent: 'Position',
    src: 'self',
    cellEditorPopup: true,
    dataHint: 'string',
    params: {
      cellDataType: 'text',
      width: 170,
      cellEditor: 'AutocompleteEditor',
    },
  },
  {
    field: FIXTURE_UNIT,
    headerName: 'Unit',
    parent: 'Position',
    src: 'self',
    dataHint: 'string',
    params: {
      cellDataType: 'text',
      width: 100,
    },
  },
  {
    field: FIXTURE_PURPOSE,
    headerName: 'Purpose',
    parent: 'Position',
    src: 'self',
    dataHint: 'string',
    params: {
      width: 170,
      cellEditor: 'AutocompleteEditor',
      cellEditorPopup: true,
    },
  },
  {
    field: FIXTURE_POSITION_OFFSET,
    headerName: 'Offset',
    parent: 'Position',
    src: 'self',
    dataHint: 'numeric',
    params: {
      width: 100,
      valueParser: numericValueParser,
    },
  },
  {
    field: FIXTURE_PRIMARY_ADDRESS,
    headerName: 'Address',
    parent: 'Data',
    src: 'self',
    dataHint: 'dmx',
    params: {
      width: 120,
      valueFormatter: dmxValueFormatter,
      valueParser: dmxValueParser,
    },
    secondaryParams: {
      valueFiller: dmxAddressFillHandler,
    },
  },
  {
    field: FIXTURE_PATCH_WIDTH,
    headerName: 'Patch Width',
    parent: 'Data',
    src: 'def',
    dataHint: 'numeric',
    defConfig: {
      headerName: 'Patch Width',
    },
    params: {
      width: 100,
      valueParser: numericValueParser,
    },
  },
  {
    field: FIXTURE_MODE,
    headerName: 'Mode',
    parent: 'Data',
    src: 'def',
    dataHint: 'string',
    defConfig: {
      headerName: 'Mode',
    },
    params: {
      width: 140,
    },
  },

  {
    field: FIXTURE_WEIGHT,
    headerName: 'Weight',
    parent: 'Physical',
    src: 'def',
    dataHint: 'weight',
    defConfig: {
      headerName: 'Weight',
    },
    params: {
      width: 100,
      aggFunc: 'sum',
      valueFormatter: (params) => {
        // Return the value in grams as kg
        const value = params.value;
        if (value === '' || value === null || value === undefined) {
          return '';
        }
        const kg = Math.round((value / 1000) * 100) / 100;
        return `${kg} kg`;
      },
      valueParser: (params) => {
        // Accept a value in the format of #.#kg, #kg, #lbs, #.#lbs, convert to grams and return a number
        const value = params.newValue;
        const regex = /(\d*\.?\d*) ?(g|kg|lbs)?/i;
        const match = value.match(regex);
        if (match) {
          const num = Number(match[1]);
          const unit = match[2];
          const numIsNumeric = isFinite(num);
          if (!numIsNumeric && !unit) {
            // Entry is only text, return null
            return null;
          }
          if (unit === 'kg' || unit === undefined) {
            return num * 1000;
          } else if (unit === 'g') {
            return num;
          } else if (unit === 'lbs') {
            return num * 453.592;
          }
        }
        return null;
      },
    },
  },

  {
    field: FIXTURE_POWER,
    headerName: 'Power',
    parent: 'Physical',
    src: 'def',
    dataHint: 'numeric',
    defConfig: {
      headerName: 'Power',
    },
    params: {
      width: 100,
      aggFunc: 'sum',
      enableValue: true,
      valueGetter: powerFieldGetter,
      valueFormatter: (params) => {
        if (params.value) {
          const w = Math.round(params.value * 100) / 100;
          return `${w}w`;
        }
        return '';
      },
      valueParser: numericValueParser,
    },
  },
  {
    field: FIXTURE_VOLTAGE,
    headerName: 'Voltage',
    parent: 'Physical',
    src: 'def',
    dataHint: 'numeric',
    defConfig: {
      headerName: 'Voltage',
    },
    params: {
      width: 100,
      valueFormatter: (params) => {
        if (params.value) {
          const v = Math.round(params.value * 100) / 100;
          return `${v}v`;
        }
        return '';
      },
      valueParser: numericValueParser,
    },
  },
  {
    field: FIXTURE_CURRENT,
    headerName: 'Current',
    parent: 'Physical',
    src: 'def',
    dataHint: 'numeric',
    defConfig: {
      headerName: 'Current',
    },
    params: {
      width: 100,
      aggFunc: 'sum',
      valueFormatter: (params) => {
        if (params.value) {
          const a = Math.round(params.value * 100) / 100;
          return `${a}A`;
        }
        return '';
      },
      valueParser: numericValueParser,
    },
  },
  {
    field: FIXTURE_PHASES,
    headerName: 'Phases',
    parent: 'Physical',
    src: 'def',
    dataHint: 'string',
    defConfig: {
      headerName: 'Phases',
    },
    params: {
      width: 100,
      cellEditor: 'agSelectCellEditor',
      cellEditorParams: {
        values: ['1ø', '2ø', '3ø'],
        valueListGap: 0,
      },

      valueParser: numericValueParser,
    },
  },

  {
    field: FIXTURE_DISTRO,
    headerName: 'Distro',
    parent: 'Power',
    src: 'self',
    dataHint: 'string',
    params: {
      width: 120,
      cellEditor: 'AutocompleteEditor',
      cellEditorPopup: true,
    },
  },
  {
    field: FIXTURE_DIMMER,
    headerName: 'Dimmer',
    parent: 'Power',
    src: 'self',
    dataHint: 'string',
    params: {
      width: 120,
      cellEditor: 'AutocompleteEditor',
      cellEditorPopup: true,
    },
  },
  {
    field: FIXTURE_CIRCUIT_CABLE,
    headerName: 'Cable',
    parent: 'Power',
    src: 'self',
    dataHint: 'string',
    params: {
      width: 120,
      cellEditor: 'AutocompleteEditor',
      cellEditorPopup: true,
    },
  },
  {
    field: FIXTURE_CIRCUIT_NAME,
    headerName: 'Circuit Name',
    parent: 'Power',
    src: 'self',
    dataHint: 'string',
    params: {
      width: 120,
      cellEditor: 'AutocompleteEditor',
      cellEditorPopup: true,
    },
  },
  {
    field: FIXTURE_CIRCUIT_NUMBER,
    headerName: 'Circuit #',
    parent: 'Power',
    src: 'self',
    dataHint: 'string',
    params: {
      width: 120,
    },
  },
];

// REACT
import React, { useCallback, useMemo, useState } from 'react';
// AG GRID
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-enterprise';

import 'ag-grid-community/styles/ag-grid.css'; // Core grid CSS, always needed
import 'ag-grid-community/styles/ag-theme-quartz.css'; // Optional theme CSS

// STYLES
import '../dataGridStyles.css';
import './ViewManager.styles.css';

// LIVEBLOCKS
import { useMutation, useStorage } from '../../../app/liveblocksClient';
import { LiveObject } from '@liveblocks/client';

// MANTINE
import { ActionIcon, Center } from '@mantine/core';

// UTILS
import { nanoid } from 'nanoid';
import useHint from '../../../hooks/useHint';

import { packColDef, unpackColDef } from '../../../utils/coldefCompressor';

const ROW_HINT =
  'Reorder rows by dragging and dropping | Double click to rename a view | Click to recall, update or delete a view | Type in the bottom row to save a new view';

function ViewManager({ context, setSegValue, segmentData }) {
  // General
  const viewManagerHint = useHint(ROW_HINT, 'ViewManagerRow');

  const { dataView, gridApi: parentGridApi } = context;
  const [localGridApi, setLocalGridApi] = useState(null);

  // Liveblocks
  const views = useStorage((root) => root.views);

  const setName = useMutation(({ storage }, params) => {
    const id = params.data._id;
    const views = storage.get('views');
    // Handle new view create
    if (id === '_NEW_ROW_DATA') {
      const id = nanoid(7);
      const name = params.newValue || 'New view';
      if (!views.has(dataView)) {
        views.set(dataView, {});
      }
      views.get(dataView).set(
        id,
        new LiveObject({
          name: name || 'New View',
          data: JSON.stringify(parentGridApi.getColumnState()),
          sort: views.get(dataView).size,
        })
      );
      return true;
    }

    // Or update existing view
    views.get(dataView).get(id).set('name', params.newValue);
    return true;
  }, []);

  const updateConfig = useMutation(({ storage }, params) => {
    const id = params.data._id;
    const dataViews = storage.get('views').get(dataView);
    const packedState = packColDef(parentGridApi.getColumnState());
    dataViews.get(id).set('data', JSON.stringify(packedState));
    return true;
  }, []);

  const deleteView = useMutation(({ storage }, params) => {
    const id = params.data._id;
    const dataViews = storage.get('views').get(dataView);
    dataViews.delete(id);
    return true;
  }, []);

  const reorderViews = useMutation(
    ({ storage }) => {
      const dataViews = storage.get('views').get(dataView);
      localGridApi.forEachNodeAfterFilterAndSort((rowNode, index) => {
        dataViews.get(rowNode.data._id).set('sort', index);
      });
    },
    [localGridApi]
  );

  const dataViews = useMemo(() => views?.get(dataView) || {}, [views, dataView]);
  const data = views
    ? [...dataViews.entries()]
        .sort((a, b) => a[1].sort - b[1].sort)
        .map(([k, v]) => ({ _id: k, view: v.name }))
    : [];

  const handleGridReady = useCallback((params) => {
    setLocalGridApi(params.api);
    params.api.sizeColumnsToFit();
  }, []);

  const handleSetView = useCallback(
    (params) => {
      const id = params.data._id;
      // If this id is in the segmentData, set the segment value
      const segmentValue = segmentData.find((item) => item.value === id);
      if (segmentValue) {
        setSegValue(id);
      } else {
        setSegValue(null);
      }

      const config = JSON.parse(dataViews?.get(id)?.data);
      const unpackedConfig = unpackColDef(config);

      parentGridApi.resetColumnState();
      setTimeout(() => {
        parentGridApi.applyColumnState({
          state: unpackedConfig,
          applyOrder: true,
        });
      }, 0);
    },
    [parentGridApi, dataViews, segmentData, setSegValue]
  );

  const getRowId = useCallback((params) => {
    return params.data._id;
  }, []);

  return (
    <>
      <div
        className='ag-theme-quartz-dark'
        style={{ width: '100%', height: '30vh', minHeight: '300px' }}
        ref={viewManagerHint}
      >
        <AgGridReact
          rowData={data}
          immutableData={true}
          getRowId={getRowId}
          rowDragManaged={true}
          onRowDragEnd={reorderViews}
          animateRows={true}
          onGridReady={handleGridReady}
          pinnedBottomRowData={[
            {
              _id: '_NEW_ROW_DATA',
              view: 'Type to save a new view...',
              recall: false,
              update: false,
              delete: false,
            },
          ]}
          columnDefs={[
            {
              field: 'view',
              headerName: 'View Name',
              editable: true,
              sortable: false,
              // Prevent column reordering
              lockPosition: true,
              flex: 1,
              rowDrag: true,
              suppressHeaderMenuButton: true,
              resizable: false,
              valueSetter: setName,
              colSpan: (params) => {
                // if row id is _NEW_ROW_DATA, span all columns
                if (params.data._id === '_NEW_ROW_DATA') {
                  return 4;
                }
                return 1;
              },
            },
            {
              field: 'recall',
              headerName: '',
              width: 40,
              resizable: false,
              sortable: false,
              lockPosition: true,
              suppressHeaderMenuButton: true,
              suppressAutoSize: true,
              suppressSizeToFit: true,

              cellRenderer: (params) => {
                if (params.data.hasOwnProperty('recall') && params.data.recall === false) {
                  return <div></div>;
                }
                return (
                  <Center style={{ width: '100%', height: '100%' }}>
                    <ActionIcon variant='light' onClick={() => handleSetView(params)} color='gray'>
                      <span className='material-symbols-rounded'>visibility</span>
                    </ActionIcon>
                  </Center>
                );
              },
            },
            {
              field: 'update',
              headerName: '',
              width: 40,
              resizable: false,
              sortable: false,
              lockPosition: true,
              suppressHeaderMenuButton: true,
              cellRenderer: (params) => {
                if (params.data.hasOwnProperty('update') && params.data.update === false) {
                  return <div></div>;
                }
                return (
                  <Center style={{ width: '100%', height: '100%' }}>
                    <ActionIcon variant='light' onClick={() => updateConfig(params)} color='gray'>
                      <span className='material-symbols-rounded'>save</span>
                    </ActionIcon>
                  </Center>
                );
              },
            },
            {
              field: 'delete',
              headerName: '',
              width: 40,
              resizable: false,
              sortable: false,
              lockPosition: true,
              suppressHeaderMenuButton: true,
              cellRenderer: (params) => {
                if (params.data.hasOwnProperty('delete') && params.data.delete === false) {
                  return <div></div>;
                }
                return (
                  <Center style={{ width: '100%', height: '100%' }}>
                    <ActionIcon variant='light' onClick={() => deleteView(params)} color='gray'>
                      <span className='material-symbols-rounded'>delete</span>
                    </ActionIcon>
                  </Center>
                );
              },
            },
          ]}
        />
      </div>
    </>
  );
}

export default ViewManager;

import { Text, Grid, Box, ActionIcon, Group, Avatar } from '@mantine/core';
import React, { useEffect, useState } from 'react';
import { Link, useSearchParams } from 'react-router-dom';

import UserAvatar from '../Users/UserAvatar';
import useHint from '../../hooks/useHint';

const DOC_NAME_HINT = 'Open this document';
const DOC_SHARE_HINT = 'The users this document is shared with (edit in document settings)';
const DOC_SETTINGS_HINT = 'Change document name and sharing settings or delete document';

function DocListRow({ doc, handleClose = () => null }) {
  const docNameHint = useHint(DOC_NAME_HINT, `DocListRow${doc.id}Name`);
  const docShareHint = useHint(DOC_SHARE_HINT, `DocListRow${doc.id}Share`);
  const docSettingsHint = useHint(DOC_SETTINGS_HINT, `DocListRow${doc.id}Settings`);

  const [searchParams, setSearchParams] = useSearchParams();
  const [animate, setAnimate] = useState('');
  const handleEdit = () => {
    setSearchParams({ ...Object.fromEntries(searchParams), editDocConfig: doc.id });
  };

  const collabs = [...doc.collaborators.data];
  if (doc.collaborators.total > 5) {
    collabs.push({ id: 'more', display_name: `+${doc.collaborators.total - 5}` });
  }

  // Animate the row when it is added
  useEffect(() => {
    let cancel = false;
    // If doc.created_at is less than ten seconds ago
    if (new Date(doc.created_at).getTime() > Date.now() - 10000 && !animate) {
      setAnimate('highlight-row');
      setTimeout(() => {
        if (!cancel) {
          setAnimate('highlight-row animate');
        }
      }, 250);
      setTimeout(() => {
        if (!cancel) {
          setAnimate('');
        }
      }, 2250);
    }
    return () => {
      cancel = true;
    };
    // eslint-disable-next-line
  }, []);

  return (
    <Box
      className={animate}
      w='100%'
      p='sm'
      sx={{
        borderBottom: '1px solid rgb(50,50,50)',
        ':hover': {
          backgroundColor: 'rgb(30,35,42, 0.6)',
        },
      }}
    >
      <Grid columns={12}>
        <Grid.Col span={4}>
          <Box w={'100%'}>
            <Text
              truncate
              weight={600}
              component={Link}
              ref={docNameHint}
              to={`/doc/${doc.id}/${doc.room_id}`}
              onClick={handleClose}
            >
              {doc.title}
            </Text>
          </Box>
        </Grid.Col>
        <Grid.Col span={3}>
          <Box w={'100%'}>
            <Text truncate c='dimmed' weight={300}>
              {doc.description}
            </Text>
          </Box>
        </Grid.Col>

        <Grid.Col span={3}>
          <Box w={'100%'}>
            <Text truncate size='xs'>
              {new Date(doc.updated_at).toLocaleDateString('en-us', {
                year: 'numeric',
                month: 'short',
                day: 'numeric',
                hour: 'numeric',
                minute: 'numeric',
              })}
            </Text>
          </Box>
        </Grid.Col>
        <Grid.Col span={1}>
          <Avatar.Group ref={docShareHint}>
            {collabs.map((collab) => (
              <UserAvatar key={collab.id} user={collab} size='sm' radius='xl' />
            ))}
          </Avatar.Group>
        </Grid.Col>
        <Grid.Col span={1}>
          <Group justify='flex-end'>
            <ActionIcon
              variant='light'
              color='eclipse'
              size='md'
              onClick={handleEdit}
              ref={docSettingsHint}
            >
              <span className='material-symbols-rounded'>settings</span>
            </ActionIcon>
          </Group>
        </Grid.Col>
      </Grid>
    </Box>
  );
}

export default DocListRow;

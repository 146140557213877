import Konva from 'konva';
import React, { useEffect, useRef, useState } from 'react';
import { Group, Layer, Line, Rect, Text } from 'react-konva';
import { FIXTURE_NUMBER } from '../DataGrid/colDefs/fixtureDefProperties';

const HEADER_COL_STYLE = {
  fill: 'black',
  ellipsis: true,
  wrap: 'none',
  padding: 6,
  fontFamily: 'Open Sans',
  fontSize: 8,
  fontStyle: '600',
  lineHeight: 1,
};

const AUTO_COL_ROW_STYLE = {
  fill: 'black',
  wrap: 'none',
  padding: 6,
  fontFamily: 'Open Sans',
  fontSize: 8,
  fontStyle: '600',
  verticalAlign: 'middle',
};

const ROW_STYLE = {
  fill: 'black',
  ellipsis: true,
  wrap: 'none',
  padding: 6,
  fontFamily: 'Open Sans',
  fontSize: 8,
  fontStyle: '400',
  verticalAlign: 'middle',
};

const FNUM_STYLE = {
  fill: 'black',
  ellipsis: true,
  wrap: 'none',
  padding: 6,
  fontFamily: 'Open Sans',
  fontSize: 12,
  fontStyle: '300',
  align: 'center',
  verticalAlign: 'middle',
};

function getColumnWidth(col) {
  return col.actualWidth || col.colDef.width || 50;
}

function getFormattedValue(data, col, gridContext) {
  const formatter = col?.colDef?.valueFormatter;
  const formattedData = formatter ? formatter({ value: data, context: { ...gridContext } }) : data;

  // Handle group auto column here, as the formatters are not passed to the auto column
  // If col starts with 'ag-Grid-AutoColumn-', it's a group auto column
  if (col?.colId.startsWith('ag-Grid-AutoColumn-')) {
    // Extract actual column id from group auto column id
    const actualColId = col?.colId.split('-').pop();
    // Get the actual column definition
    const actualCol = gridContext?.gridApi?.getColumn(actualColId);
    // Get the formatter from the actual column definition
    const actualFormatter = actualCol?.colDef?.valueFormatter;
    // If the actual column has a formatter, use it to format the data
    if (actualFormatter) {
      return actualFormatter({ value: data, context: { ...gridContext } });
    }
  }

  return formattedData;
}

function CanvasGridLayer({
  gridContext,
  pageConfig,
  stageRef,
  colData,
  rowData,
  sectionConfig,
  displayPage,
}) {
  const ruleLineRef = useRef(null);
  const layerRef = useRef(null);
  const { xStart, xEnd, yStart, yEnd, scale } = pageConfig;
  const [initial, setInitial] = useState({});
  const [ruleLine, setRuleLine] = useState({ visible: false, x: 0 });
  const [cols, setCols] = useState([]);
  const { headerHeight, rowHeight, rowsPerPage } = sectionConfig;

  useEffect(() => {
    setCols(colData);
  }, [colData]);

  const colHeaders = [];
  const colHandles = [];

  let xCursor = yStart;

  cols.forEach((col) => {
    const field = col?.colId;

    const width = getColumnWidth(col);
    const x = xCursor;
    const y = yStart;
    colHeaders.push(
      <Text
        {...HEADER_COL_STYLE}
        text={`${col?.colDef?.headerName}`}
        x={x}
        y={y}
        width={width}
        key={`header-${field}`}
      />
    );
    colHandles.push(
      <Rect
        key={`handle-${field}`}
        x={x + width - 1}
        y={y + 4}
        width={2}
        height={16}
        fill='#eee'
        dragDistance={1}
        onMouseOver={() => {
          stageRef.current.container().style.cursor = 'move';
        }}
        onMouseOut={() => {
          stageRef.current.container().style.cursor = 'default';
        }}
        hitStrokeWidth={15}
        draggable
        dragBoundFunc={(e) => {
          // Calculate page position
          const pagePos = layerRef.current.getAbsolutePosition();

          // Get the field state
          const thisColWidth = col.actualWidth || col.colDef.width || 50;

          // Calculate left and right limits relative to page
          const leftLimit = initial.abs.x - thisColWidth * scale + 8;
          const rightLimit =
            xEnd * scale + pagePos.x + pageConfig.margin * pageConfig.screenDpi * scale - 8;

          // Return left or right limit if drag is outside limits
          if (e.x < leftLimit) {
            return {
              x: leftLimit,
              y: initial.abs.y,
            };
          }

          if (e.x > rightLimit) {
            return {
              x: rightLimit,
              y: initial.abs.y,
            };
          }
          // If drag is within limits, return new position

          return {
            x: e.x,
            y: initial.abs.y,
          };
        }}
        onDragStart={(e) => {
          // Set initial Absolute position for dragBoundFunc
          setInitial({
            abs: e.target.absolutePosition(),
            pos: { x: e.target.x(), y: e.target.y() },
          });
          const fadeInRuleLine = new Konva.Tween({
            node: ruleLineRef.current,
            duration: 0.3,
            opacity: 1,
            onFinish: () => {
              fadeInRuleLine.destroy();
            },
          });
          fadeInRuleLine.play();
        }}
        onDragMove={(e) => {
          setRuleLine({ visible: true, x: e.target.x() });
        }}
        onDragEnd={(e) => {
          const thisColWidth = getColumnWidth(col);
          const oldX = initial.pos.x;
          const newX = e.target.x();
          const diff = newX - oldX;
          const newWidth = thisColWidth + diff;
          setRuleLine({ visible: false, x: ruleLine.x });
          const fadeOutRuleLine = new Konva.Tween({
            node: ruleLineRef.current,
            duration: 1,
            opacity: 0,
            onFinish: () => {
              fadeOutRuleLine.destroy();
            },
          });
          fadeOutRuleLine.play();
          // Update column width in grid
          gridContext.gridApi.setColumnWidth(field, Math.round(newWidth));
          // Update column width in state
          const newCols = cols.map((c) => {
            if (c.colId === field) {
              c.colDef.width = Math.round(newWidth);
            }
            return c;
          });
          setCols(newCols);
        }}
      />
    );
    xCursor += width;
  });

  const rows = [];
  const rowDividers = [];

  const startRow = (displayPage - 1) * rowsPerPage;
  const endRow = Math.min(startRow + rowsPerPage, rowData.length);
  const rowOffset = (displayPage - 1) * rowsPerPage;
  const lastRow = Math.min(rowData.length, endRow - rowOffset);

  for (let i = 0; i < lastRow; i++) {
    let xCursor = xStart;
    rows.push(
      <Group key={`row-${i}`}>
        {cols.forEach((col) => {
          const node = rowData[rowOffset + i];
          const field = col?.colId;
          const width = getColumnWidth(col);
          const x = xCursor;
          const y = yStart + headerHeight + rowHeight * i;
          const data = gridContext.gridApi?.getValue(field, node);
          const formattedData = getFormattedValue(data, col, gridContext);

          const cellIsAutoColumn = field.startsWith('ag-Grid-AutoColumn');
          const cellIsFixtureNumber = field === FIXTURE_NUMBER;

          let rowStyle = ROW_STYLE;
          if (cellIsAutoColumn) {
            rowStyle = AUTO_COL_ROW_STYLE;
          }
          if (cellIsFixtureNumber) {
            rowStyle = FNUM_STYLE;
          }

          rows.push(
            <Text
              key={`cell-${i}-${field}`}
              {...rowStyle}
              text={formattedData}
              x={x}
              y={y}
              width={cellIsAutoColumn ? width + 200 : width}
              height={rowHeight}
            />
          );
          xCursor += width;
        })}
      </Group>
    );
  }

  for (let i = 0; i < lastRow; i++) {
    rowDividers.push(
      <Rect
        key={`row-divider-${i}`}
        x={xStart}
        y={yStart + headerHeight + rowHeight * i}
        width={xEnd}
        height={rowHeight}
        fill={i % 2 === 0 ? '#f9f9f9' : '#fff'}
      />
    );
  }

  return (
    <>
      <Layer name='1 - Grid' listening={false}>
        <Group clipX={xStart} clipY={yStart} clipWidth={xEnd} clipHeight={yEnd}>
          {colHeaders}
        </Group>
        <Group>{rowDividers}</Group>
        <Group clipX={xStart} clipY={yStart} clipWidth={xEnd} clipHeight={yEnd}>
          {rows}
        </Group>
      </Layer>
      <Layer name='2 - Grid Handles' listening={true} ref={layerRef}>
        <Group clipX={xStart} clipY={yStart} clipWidth={xEnd} clipHeight={yEnd}>
          {colHandles}
        </Group>
        <Line
          points={[ruleLine.x + 1, yStart + 20, ruleLine.x + 1, yEnd]}
          stroke='#ddd'
          strokeWidth={1}
          dash={[4, 4]}
          opacity={0}
          ref={ruleLineRef}
        />
      </Layer>
    </>
  );
}

export default CanvasGridLayer;

export default function dmxValueParser(params) {
  // Take params and execute these steps:
  // 1. Extract value from params
  // 2. Determine if the entry is an absolute address, relative address in universe.channel format, or universe/channel format
  // 3. Convert to absolute address
  // 4. Return absolute address as a number

  const value = params.newValue;
  if (!value) return null;

  const isNumeric = typeof params.newValue === 'number';

  if (isNumeric) return value;

  const valueArray = value.split('.');

  if (valueArray.length === 2) {
    // universe.channel format
    const universe = parseInt(valueArray[0]);
    const channel = parseInt(valueArray[1]);
    if (isNaN(universe) || isNaN(channel)) return null;

    return (universe - 1) * 512 + channel;
  } else if (valueArray.length === 1) {
    // absolute address
    const absoluteAddress = parseInt(valueArray[0]);
    if (isNaN(absoluteAddress)) return null;

    return absoluteAddress;
  } else {
    // invalid format
    return null;
  }
}

import { Box, Flex, Kbd, Portal, Text } from '@mantine/core';
import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { selectHint } from '../../../features/hints/hintsSlice';
import { useOs } from '@mantine/hooks';

function FooterBar() {
  const hint = useSelector(selectHint);
  const os = useOs();

  let hintString;

  if (os === 'macos') {
    // Replace instances of [CTRL] with [⌘]
    hintString = hint?.replace(/\[CTRL\]/g, '[⌘]');
  } else {
    hintString = hint;
  }

  const addKbdTags = useCallback((input) => {
    const components = [];
    const regex = /\[([^\]]+)\]/g;
    let lastIndex = 0;
    let match;

    while ((match = regex.exec(input)) !== null) {
      // Add the text before the match
      components.push(<span key={lastIndex}>{input.substring(lastIndex, match.index)}</span>);

      // Add the matched substring within <Kbd> tags
      components.push(
        <Kbd key={match.index + 1} size='sm' style={{ position: 'relative', top: -2 }}>
          {match[1]}
        </Kbd>
      );

      lastIndex = match.index + match[0].length;
    }

    // Add any remaining text after the last match
    components.push(<span key={lastIndex}>{input.substring(lastIndex)}</span>);

    return (
      <Text size='xs' style={{ lineHeight: '1.5rem' }}>
        {components}
      </Text>
    );
  }, []);

  return (
    <Portal target='#ext-portal'>
      <Box className='fxtr-footer-bar'>
        <Flex
          gap='xs'
          justify='left'
          align='center'
          direction='row'
          pl='md'
          style={{ height: '100%', cursor: 'default', userSelect: 'none' }}
        >
          {hint && addKbdTags(hintString)}
        </Flex>
      </Box>
    </Portal>
  );
}

export default FooterBar;

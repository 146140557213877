import {
  Box,
  Center,
  CloseButton,
  Group,
  Modal,
  Pagination,
  Stack,
  TextInput,
  Title,
} from '@mantine/core';
import SearchIcon from '../Icons/FileSearchIcon';
import DocListRow from './DocListRow';
import { useGetDocsQuery } from '../../app/services/coreApi';
import { useSearchParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import DocConfig from '../Doc/DocConfig';
import { useDebouncedValue } from '@mantine/hooks';
import NewDocButton from './NewDocButton';

function DocList({ pageSize = 10, handleClose = () => null }) {
  const [searchParams, setSearchParams] = useSearchParams();
  const [searchString, setSearchString] = useState('');
  const [debouncedSearch] = useDebouncedValue(searchString, 500);
  const [page, setPage] = useState(1);
  const edit = searchParams.get('editDocConfig') || undefined;

  const params = Object.fromEntries(searchParams);
  const clearEdit = () => {
    const { editDocConfig, ...rest } = params || {};
    setSearchParams(rest);
  };
  const queryParams = {
    $limit: pageSize,
    $skip: Math.max((page - 1) * pageSize, 0),
  };

  if (debouncedSearch) {
    queryParams.$search = `%${debouncedSearch}%`;
  }

  const { data: docs, isSuccess } = useGetDocsQuery(queryParams, {
    refetchOnMountOrArgChange: true,
    refetchOnFocus: true,
  });

  const total = docs?.total ? Math.ceil(docs.total / pageSize) : 0;

  useEffect(() => {
    // Ensure that the page number is not outside the range of the total number of pages
    if (isNaN(page) || page < 0 || page > total) {
      setPage(1);
    }

    // eslint-disable-next-line
  }, [page, total, edit]);

  const docsList = Array.isArray(docs?.data) ? docs.data : [];
  const readyToDisplayList = isSuccess && docsList.length > 0;
  const shouldDisplayEmptyState = isSuccess && docsList.length === 0;

  return (
    <>
      <Modal opened={edit} onClose={clearEdit} padding={'lg'}>
        <DocConfig docId={edit} onRemove={clearEdit} />
      </Modal>
      <Group justify={'space-between'} mt='xl'>
        <TextInput
          placeholder='Search for documents'
          size='sm'
          icon={<SearchIcon />}
          w={{ xs: '100%', sm: 400 }}
          value={searchString}
          onChange={(e) => setSearchString(e.target.value)}
          rightSection={searchString ? <CloseButton onClick={() => setSearchString('')} /> : null}
        />
        <NewDocButton />
      </Group>

      <Box mt='xl'>
        {readyToDisplayList &&
          docsList.map((doc) => <DocListRow key={doc.id} doc={doc} handleClose={handleClose} />)}
        {shouldDisplayEmptyState && (
          <Center mt='xl' style={{ minHeight: '300px' }}>
            <Stack>
              <Title order={3}>No documents found</Title>
              <NewDocButton />
            </Stack>
          </Center>
        )}
      </Box>

      {readyToDisplayList && (
        <Center>
          <Pagination mt='xl' total={total} value={page} onChange={setPage} />
        </Center>
      )}
    </>
  );
}

export default DocList;

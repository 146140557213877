import { COMPRESSED_PROPERTIES } from '../components/DataGrid/colDefs/fixtureDefProperties';

export const packColDef = (colDef) => {
  const res = [];
  colDef.forEach((col) => {
    const obj = {};
    obj.a = getCompressedProperty(col.colId);
    obj.b = col.width;
    if (col.hide) obj.c = 1;
    if (col.pinned) obj.d = col.pinned;
    if (col.sort) obj.e = col.sort;
    if (col.sortIndex) obj.f = col.sortIndex;
    if (col.aggFunc) obj.g = col.aggFunc;
    if (col.rowGroup) obj.h = 1;
    if (col.rowGroupIndex) obj.i = col.rowGroupIndex;
    if (col.pivot) obj.j = 1;
    if (col.pivotIndex) obj.k = col.pivotIndex;
    if (col.flex) obj.l = col.flex;
    res.push(obj);
  });
  return res;
};

export const unpackColDef = (colDef) => {
  if (!colDef || colDef.length === 0) return [];
  // Detect if this is a compressed colDef
  if (!colDef[0].hasOwnProperty('a')) {
    return colDef;
  }

  const res = [];
  colDef.forEach((col) => {
    const obj = {};
    obj.colId = getUncompressedProperty(col.a);
    obj.width = col.b;
    obj.hide = col.c === 1;
    obj.pinned = col.d || null;
    obj.sort = col.e || null;
    obj.sortIndex = col.f || null;
    obj.aggFunc = col.g || null;
    obj.rowGroup = col.h === 1;
    // If rowGroup is true, rowGroupIndex is required
    obj.rowGroupIndex = col.h === 1 ? col.i || 0 : null;
    obj.pivot = col.j === 1;
    obj.pivotIndex = col.k || null;
    obj.flex = col.l || null;
    res.push(obj);
  });
  return res;
};

function getCompressedProperty(col) {
  if (Object(COMPRESSED_PROPERTIES).hasOwnProperty(col)) {
    return COMPRESSED_PROPERTIES[col];
  }
  return col;
}

function getUncompressedProperty(col) {
  const res = Object.keys(COMPRESSED_PROPERTIES).find((key) => COMPRESSED_PROPERTIES[key] === col);
  if (res) return res;
  return col;
}

/*
[
  {
      "colId": "ag-Grid-AutoColumn",
      "width": 200,
      "hide": false,
      "pinned": null,
      "sort": null,
      "sortIndex": null,
      "aggFunc": null,
      "rowGroup": false,
      "rowGroupIndex": null,
      "pivot": false,
      "pivotIndex": null,
      "flex": null
  },
  {
      "colId": "_id",
      "width": 200,
      "hide": true,
      "pinned": null,
      "sort": null,
      "sortIndex": null,
      "aggFunc": null,
      "rowGroup": false,
      "rowGroupIndex": null,
      "pivot": false,
      "pivotIndex": null,
      "flex": null
  },
  {
      "colId": "fDist",
      "width": 120,
      "hide": true,
      "pinned": null,
      "sort": null,
      "sortIndex": null,
      "aggFunc": null,
      "rowGroup": true,
      "rowGroupIndex": 0,
      "pivot": false,
      "pivotIndex": null,
      "flex": null
  },
  {
      "colId": "fCrCb",
      "width": 120,
      "hide": false,
      "pinned": null,
      "sort": null,
      "sortIndex": null,
      "aggFunc": null,
      "rowGroup": false,
      "rowGroupIndex": null,
      "pivot": false,
      "pivotIndex": null,
      "flex": null
  },
  {
      "colId": "fCrNa",
      "width": 120,
      "hide": true,
      "pinned": null,
      "sort": null,
      "sortIndex": null,
      "aggFunc": null,
      "rowGroup": true,
      "rowGroupIndex": 1,
      "pivot": false,
      "pivotIndex": null,
      "flex": null
  },
  {
      "colId": "fCrNo",
      "width": 120,
      "hide": true,
      "pinned": null,
      "sort": null,
      "sortIndex": null,
      "aggFunc": null,
      "rowGroup": true,
      "rowGroupIndex": 2,
      "pivot": false,
      "pivotIndex": null,
      "flex": null
  },
  {
      "colId": "fFixN",
      "width": 170,
      "hide": false,
      "pinned": null,
      "sort": null,
      "sortIndex": null,
      "aggFunc": null,
      "rowGroup": false,
      "rowGroupIndex": null,
      "pivot": false,
      "pivotIndex": null,
      "flex": null
  },
  {
      "colId": "fType",
      "width": 170,
      "hide": false,
      "pinned": null,
      "sort": null,
      "sortIndex": null,
      "aggFunc": null,
      "rowGroup": false,
      "rowGroupIndex": null,
      "pivot": false,
      "pivotIndex": null,
      "flex": null
  },
  {
      "colId": "fManu",
      "width": 140,
      "hide": true,
      "pinned": null,
      "sort": null,
      "sortIndex": null,
      "aggFunc": null,
      "rowGroup": false,
      "rowGroupIndex": null,
      "pivot": false,
      "pivotIndex": null,
      "flex": null
  },
  {
      "colId": "fDfCl",
      "width": 140,
      "hide": true,
      "pinned": null,
      "sort": null,
      "sortIndex": null,
      "aggFunc": null,
      "rowGroup": false,
      "rowGroupIndex": null,
      "pivot": false,
      "pivotIndex": null,
      "flex": null
  },
  {
      "colId": "fGrp",
      "width": 140,
      "hide": true,
      "pinned": null,
      "sort": null,
      "sortIndex": null,
      "aggFunc": null,
      "rowGroup": false,
      "rowGroupIndex": null,
      "pivot": false,
      "pivotIndex": null,
      "flex": null
  },
  {
      "colId": "fPos",
      "width": 170,
      "hide": false,
      "pinned": null,
      "sort": null,
      "sortIndex": null,
      "aggFunc": null,
      "rowGroup": false,
      "rowGroupIndex": null,
      "pivot": false,
      "pivotIndex": null,
      "flex": null
  },
  {
      "colId": "fUnit",
      "width": 100,
      "hide": false,
      "pinned": null,
      "sort": null,
      "sortIndex": null,
      "aggFunc": null,
      "rowGroup": false,
      "rowGroupIndex": null,
      "pivot": false,
      "pivotIndex": null,
      "flex": null
  },
  {
      "colId": "fPurp",
      "width": 170,
      "hide": true,
      "pinned": null,
      "sort": null,
      "sortIndex": null,
      "aggFunc": null,
      "rowGroup": false,
      "rowGroupIndex": null,
      "pivot": false,
      "pivotIndex": null,
      "flex": null
  },
  {
      "colId": "fA01",
      "width": 120,
      "hide": false,
      "pinned": null,
      "sort": null,
      "sortIndex": null,
      "aggFunc": null,
      "rowGroup": false,
      "rowGroupIndex": null,
      "pivot": false,
      "pivotIndex": null,
      "flex": null
  },
  {
      "colId": "fPWdt",
      "width": 100,
      "hide": true,
      "pinned": null,
      "sort": null,
      "sortIndex": null,
      "aggFunc": null,
      "rowGroup": false,
      "rowGroupIndex": null,
      "pivot": false,
      "pivotIndex": null,
      "flex": null
  },
  {
      "colId": "fMode",
      "width": 140,
      "hide": true,
      "pinned": null,
      "sort": null,
      "sortIndex": null,
      "aggFunc": null,
      "rowGroup": false,
      "rowGroupIndex": null,
      "pivot": false,
      "pivotIndex": null,
      "flex": null
  },
  {
      "colId": "fWght",
      "width": 100,
      "hide": false,
      "pinned": null,
      "sort": null,
      "sortIndex": null,
      "aggFunc": "sum",
      "rowGroup": false,
      "rowGroupIndex": null,
      "pivot": false,
      "pivotIndex": null,
      "flex": null
  },
  {
      "colId": "fPwr",
      "width": 100,
      "hide": false,
      "pinned": null,
      "sort": null,
      "sortIndex": null,
      "aggFunc": "sum",
      "rowGroup": false,
      "rowGroupIndex": null,
      "pivot": false,
      "pivotIndex": null,
      "flex": null
  },
  {
      "colId": "fVolt",
      "width": 100,
      "hide": false,
      "pinned": null,
      "sort": null,
      "sortIndex": null,
      "aggFunc": null,
      "rowGroup": false,
      "rowGroupIndex": null,
      "pivot": false,
      "pivotIndex": null,
      "flex": null
  },
  {
      "colId": "fCurr",
      "width": 100,
      "hide": false,
      "pinned": null,
      "sort": null,
      "sortIndex": null,
      "aggFunc": "sum",
      "rowGroup": false,
      "rowGroupIndex": null,
      "pivot": false,
      "pivotIndex": null,
      "flex": null
  },
  {
      "colId": "fPhas",
      "width": 100,
      "hide": false,
      "pinned": null,
      "sort": null,
      "sortIndex": null,
      "aggFunc": null,
      "rowGroup": false,
      "rowGroupIndex": null,
      "pivot": false,
      "pivotIndex": null,
      "flex": null
  }
]*/

export default async function dmxAddressFillHandler(params) {
  // Get the last address
  const priorAddress = Number(await params.values[params.values.length - 1]);

  const direction = params.direction;
  if (!(direction === 'down' || direction === 'up')) return false;

  const ranges = await params.api.getCellRanges();

  const firstRange = ranges[0];
  const rangeStartRow =
    direction === 'down' ? firstRange.startRow.rowIndex : firstRange.endRow.rowIndex;
  const rangeEndRow =
    direction === 'down' ? firstRange.endRow.rowIndex : firstRange.startRow.rowIndex;
  const forward = rangeStartRow <= rangeEndRow;

  // Gather all rows in range
  const rows = [];
  if (forward) {
    for (let i = rangeStartRow; i <= rangeEndRow; i++) {
      rows.push(params.api.getDisplayedRowAtIndex(i).data);
    }
  } else {
    for (let i = rangeStartRow; i >= rangeEndRow; i--) {
      rows.push(params.api.getDisplayedRowAtIndex(i).data);
    }
  }

  const currentRow = rows[params.values.length];
  const priorRow = rows[params.values.length - 1];

  // Get the data for the prior row
  const priorFType = priorRow.fType;

  // Get fixture type of prior fixture
  const fixDefs = params.context.additionalData;
  const priorFDef = fixDefs.get(priorFType);

  const priorPatchWidth = priorFDef ? Number(priorRow.fPWdt || priorFDef.fPWdt) : 1;

  // Get fixture type of current fixture
  const currentFType = currentRow.fType;
  const currentFDef = fixDefs.get(currentFType);

  const currentPatchWidth = currentFDef ? Number(currentRow.fPWdt || currentFDef.fPWdt) : 1;

  const proposedAddress = priorAddress + priorPatchWidth;

  const proposedChannel = proposedAddress % 512;

  if (proposedChannel + currentPatchWidth <= 512) {
    // Address fits in current universe
    return proposedAddress;
  }

  // Address overflows universe, return start address of next universe
  const universe = Math.floor(priorAddress / 512) + 1;
  return universe * 512 + 1;
}

import React, { useEffect, useId, useState } from 'react';
import './PagePreview.styles.css';
import { ActionIcon, Group, Pagination, Select } from '@mantine/core';
import { FIXTURE_GRID_CONFIG } from '../DocEditor/DocEditorInnerTabs';
import CanvasPreview from './CanvasPreview';
import PreviewGrid from '../DataGrid/PreviewGrid';
import { useStorage } from '../../app/liveblocksClient';
import { unpackColDef } from '../../utils/coldefCompressor';
import PaperConfigEditor from './PaperConfigEditor';

function PagePreview({ currentPage }) {
  const subscriberId = useId();
  const [gridContext, setGridContext] = useState(null);
  const [viewValue, setViewValue] = useState(null);
  const [viewIsPristine, setViewIsPristine] = useState(0);
  const [gridHasChanged, setGridHasChanged] = useState(0);
  const [displayPage, setDisplayPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [shouldPrint, setShouldPrint] = useState(false);

  const views = useStorage((root) => root.views);
  const dataViews = views?.get(gridContext?.dataView) || null;

  let selectViewData = dataViews
    ? [...dataViews.entries()]
        .sort((a, b) => a[1].sort - b[1].sort)
        .map(([k, v]) => ({ value: k, label: v.name }))
    : [];

  const handleSelectView = (e) => {
    setViewValue(e);
  };

  // Handle selectViewValue in an effect
  useEffect(() => {
    if (!viewValue) return;
    const config = JSON.parse(dataViews.get(viewValue)?.data);
    const unpackedConfig = unpackColDef(config);
    gridContext.gridApi.resetColumnState();
    setTimeout(() => {
      gridContext.gridApi.applyColumnState({
        state: unpackedConfig,
        applyOrder: true,
      });
      setViewValue(null);
    }, 0);
    // eslint-disable-next-line
  }, [viewValue]);

  // If totalPages has changed and display page is greater, set displayPage to totalPages
  useEffect(() => {
    if (displayPage > totalPages) setDisplayPage(totalPages);
  }, [totalPages, displayPage]);

  if (!currentPage) return <div className='eclipse-page-preview-outer-container' />;

  return (
    <div className='eclipse-page-preview-outer-container'>
      <div className='previewgrid-top-menu-container'>
        <Group gap={'xs'}>
          <ActionIcon size='md' variant='light' onClick={() => setShouldPrint(true)}>
            <span className='material-symbols-rounded'>print</span>
          </ActionIcon>
          <PaperConfigEditor currentPage={currentPage} />
        </Group>
        <Pagination
          total={totalPages}
          size='sm'
          siblings={1}
          disabled={totalPages <= 1}
          value={displayPage}
          onChange={setDisplayPage}
        />
        <Select
          data={selectViewData}
          value={viewValue}
          onChange={handleSelectView}
          ml='md'
          placeholder='Recall grid view'
        />
      </div>

      <div
        className='eclipse-page-preview-inner-container'
        style={{
          display: 'flex',
          position: 'relative',
          top: 0,
          left: 0,
        }}
      >
        <CanvasPreview
          gridContext={gridContext}
          currentPage={currentPage}
          gridHasChanged={gridHasChanged}
          displayPage={displayPage}
          setTotalPages={setTotalPages}
          shouldPrint={shouldPrint}
          setShouldPrint={setShouldPrint}
        />
        <PreviewGrid
          currentPage={currentPage}
          config={FIXTURE_GRID_CONFIG}
          subscriberId={subscriberId}
          setParentGridContext={setGridContext}
          setViewIsPristine={setViewIsPristine}
          viewIsPristine={viewIsPristine}
          setGridHasChanged={setGridHasChanged}
        />
      </div>
    </div>
  );
}

export default PagePreview;

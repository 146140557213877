import { ActionIcon, Image } from '@mantine/core';
import React from 'react';
import useHint from '../../hooks/useHint';

function NavToggle({ action }) {
  const hint = useHint('Show Document List', 'NavToggle');

  return (
    <div
      style={{
        height: 'calc(3.0rem - 1px)',
        width: 'calc(3.0rem - 1px)',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <ActionIcon size={'xl'} onClick={action} color='gray' variant='subtle' radius={0} ref={hint}>
        <Image src='/logo192.png' alt='logo' width={36} height={36} />
      </ActionIcon>
    </div>
  );
}

export default NavToggle;

import React from 'react';
import { ActionIcon } from '@mantine/core';

import { useCanUndo, useCanRedo, useUndo, useRedo } from '../../../app/liveblocksClient';

function UndoRedo() {
  const undo = useUndo();
  const redo = useRedo();
  const canUndo = useCanUndo();
  const canRedo = useCanRedo();

  return (
    <>
      <ActionIcon size='md' variant='light' disabled={!canUndo} onClick={undo}>
        <span className='material-symbols-rounded'>undo</span>
      </ActionIcon>

      <ActionIcon size='md' variant='light' disabled={!canRedo} onClick={redo}>
        <span className='material-symbols-rounded'>redo</span>
      </ActionIcon>
    </>
  );
}

export default UndoRedo;

export function dmxValueFormatter(params) {
  // Display as DMX Universe.Channel
  const value = params.value;
  if (!value) return '';

  const universe = Math.floor(value / 512) + 1;
  const channel = value % 512;
  const channelString = channel.toString().padStart(3, '0');

  return `${universe}.${channelString}`;
}

export default dmxValueFormatter;

import { DATAVIEW } from '../../../constants/dataViews';
import { FIXTURE_CURRENT, FIXTURE_POWER, FIXTURE_VOLTAGE } from '../colDefs/fixtureDefProperties';

function getVal(data, fixDef, field) {
  const value = data[field];
  if (value === 0) return 0;
  if (value) return Number(value);

  const defValue = fixDef[field];
  if (defValue === 0) return 0;
  if (defValue) return Number(defValue);

  return undefined;
}

export default function powerFieldGetter(params) {
  const { data, colDef, context } = params;
  const { field } = colDef;

  // First find local value
  if (data === undefined) return '';

  const value = data[field];

  if (value === 0) return 0;
  if (value) return value;
  if (!data.fType) return '';

  // If not found, find default value
  const fixDef =
    context.dataView === DATAVIEW.FIXTURE_GRID ? context.additionalData?.get(data.fType) : data;

  if (!fixDef) return '';
  if (fixDef[field] === 0) return 0;
  if (fixDef[field]) return fixDef[field];

  // If not found, attempt to derive a value
  if (field === FIXTURE_POWER) {
    const voltage = getVal(data, fixDef, FIXTURE_VOLTAGE);
    const current = getVal(data, fixDef, FIXTURE_CURRENT);
    if (typeof voltage === 'undefined' || typeof current === 'undefined') return '';

    return voltage * current;
  }

  return fixDef[field];
}

import React, { useMemo } from 'react';

import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-enterprise';
import 'ag-grid-community/styles/ag-grid.css'; // Core grid CSS, always needed
import 'ag-grid-community/styles/ag-theme-quartz.css'; // Optional theme CSS
import '../DataGrid/dataGridStyles.css';
import './PagePreview.styles.css';
import { Tooltip, ActionIcon, Text } from '@mantine/core';
import useHint from '../../hooks/useHint';
import { nanoid } from 'nanoid';
import { useMutation, useStorage } from '../../app/liveblocksClient';
import { LiveObject } from '@liveblocks/client';

const GRID_HINT = 'Add a fixture grid page';
const FIXTURE_DEF_HINT = 'Add a fixture definition page';

function PageList({ docId, setCurrentPage, currentPage }) {
  const gridHint = useHint(GRID_HINT, 'PageListGrid');
  const fixtureDefHint = useHint(FIXTURE_DEF_HINT, 'PageListFixtureDef');

  const pages = useStorage((root) => root.pages);

  const addPage = useMutation(
    ({ storage }, params) => {
      const id = nanoid(7);
      const page = new LiveObject({
        s: 'LETTER', // Size
        d: [612.0, 792.0], // Dims
        o: 'p', // Orientation
        m: [20, 20, 20, 20], // Margins
      });
      const pageData = {
        _id: id,
        name: 'New Page',
        type: params.type,
        sort: storage.get('pages').size,
        gridConfig: null,
        page,
      };
      storage.get('pages').set(id, new LiveObject(pageData));
      return true;
    },
    [docId]
  );

  const updatePage = useMutation(
    ({ storage }, params) => {
      const page = storage.get('pages').get(params.data._id);
      page.set('name', params.newValue);
      return true;
    },
    [docId]
  );

  const duplicatePage = useMutation(
    ({ storage }, params) => {
      const page = storage.get('pages').get(params.node.data._id);
      const id = nanoid(7);
      const pageData = {
        _id: id,
        name: `${page.get('name')} (Copy)`,
        type: page.get('type'),
        sort: storage.get('pages').size,
        gridConfig: page.get('gridConfig'),
      };
      storage.get('pages').set(id, new LiveObject(pageData));
      return true;
    },
    [docId]
  );

  const deletePage = useMutation(
    ({ storage }, params) => {
      storage.get('pages').delete(params.node.data._id);
      if (currentPage === params.node.data._id) setCurrentPage(null);
      return true;
    },
    [docId, currentPage]
  );

  const updateRowOrder = useMutation(
    ({ storage }, params) => {
      const gridApi = params.api;
      if (!gridApi) return;
      gridApi.forEachNodeAfterFilterAndSort((rowNode, index) => {
        const page = storage.get('pages').get(rowNode.data._id);
        page.set('sort', index);
      });
    },

    [docId]
  );

  const rowData = useMemo(() => {
    if (!pages) return [];
    if (!typeof pages === 'object') return [];

    const data = [];

    for (const [key, value] of pages.entries()) {
      data.push({ _id: key, ...value });
    }
    return data.sort((a, b) => (a.sort || 0) - (b.sort || 0));
  }, [pages]);

  return (
    <>
      <div className='datagrid-top-menu-container'>
        <ActionIcon.Group>
          <Tooltip
            label='Fixture Grid'
            placement='bottom'
            withArrow
            offset={10}
            onClick={() => addPage('grid')}
          >
            <ActionIcon
              radius='md'
              size='lg'
              variant='light'
              ref={gridHint}
              className='action-with-plus'
            >
              <span className='material-symbols-rounded'>note</span>
            </ActionIcon>
          </Tooltip>
        </ActionIcon.Group>
      </div>
      <div className='ag-theme-quartz-dark ag-no-border' style={{ width: '100%', height: '100%' }}>
        <AgGridReact
          rowData={rowData}
          immutableData={true}
          getRowId={(params) => params.data._id}
          rowDragManaged={true}
          onRowDragEnd={updateRowOrder}
          onRowClicked={(params) => setCurrentPage(params.data._id)}
          rowSelection='single'
          suppressCellFocus={true}
          getContextMenuItems={(params) => {
            return [
              {
                name: 'Duplicate',
                action: () => duplicatePage(params),
              },
              {
                name: 'Delete',
                action: () => deletePage(params),
              },
            ];
          }}
          defaultColDef={{
            enableCellChangeFlash: true,
          }}
          columnDefs={[
            {
              valueSetter: updatePage,
              rowDrag: true,
              field: 'name',
              headerName: 'Name',
              editable: true,
              resizable: true,
              sortable: false,
              filter: false,
              flex: 1,
              suppressHeaderMenuButton: true,
            },
            {
              field: 'type',
              headerName: 'Type',
              editable: false,
              sortable: false,
              filter: false,
              resizable: false,
              width: 130,
              suppressHeaderMenuButton: true,
              valueFormatter: (params) => {
                if (params.value === 'grid') return 'Fixture Grid';
                if (params.value === 'defs') return 'Fixture Definitions';
                return '';
              },
              cellRenderer: (params) => {
                return (
                  <div
                    style={{
                      height: '100%',
                      width: '100%',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    <Text size='xs' c='dimmed'>
                      {params.valueFormatted}
                    </Text>
                  </div>
                );
              },
            },
          ]}
          colResizeDefault='shift'
          headerHeight={32}
          overlayNoRowsTemplate={'No Pages'}
        ></AgGridReact>
      </div>
    </>
  );
}

export default PageList;

// Internal
export const FIXTURE_ID = '_id';

// Ungrouped
export const FIXTURE_NUMBER = 'fFixN';

// Fixture Type
export const FIXTURE_TYPE = 'fType';
export const FIXTURE_MANUFACTURER = 'fManu';
export const FIXTURE_CLASS = 'fDfCl';

// Fixture Accessories
export const FIXTURE_ACCESSORIES = 'fAccs';
export const FIXTURE_COLOR = 'fCol';
export const FIXTURE_GOBO = 'fGob';

// Fixture Position
export const FIXTURE_GROUP = 'fGrp';
export const FIXTURE_POSITION = 'fPos';
export const FIXTURE_UNIT = 'fUnit';
export const FIXTURE_PURPOSE = 'fPurp';
export const FIXTURE_POSITION_OFFSET = 'fOfst';

// Fixture Data
export const FIXTURE_PRIMARY_ADDRESS = 'fA01';
export const FIXTURE_PATCH_WIDTH = 'fPWdt';
export const FIXTURE_MODE = 'fMode';

// Fixture Physical
export const FIXTURE_WEIGHT = 'fWght';
export const FIXTURE_POWER = 'fPwr';
export const FIXTURE_VOLTAGE = 'fVolt';
export const FIXTURE_CURRENT = 'fCurr';
export const FIXTURE_PHASES = 'fPhas';

// Fixture Power
export const FIXTURE_DISTRO = 'fDist';
export const FIXTURE_DIMMER = 'fDim';
export const FIXTURE_CIRCUIT_CABLE = 'fCrCb';
export const FIXTURE_CIRCUIT_NAME = 'fCrNa';
export const FIXTURE_CIRCUIT_NUMBER = 'fCrNo';

export const COMPRESSED_PROPERTIES = {
  'ag-Grid-AutoColumn': 'a',
  _id: 'b',
  fFixN: 'c',
  fType: 'd',
  fManu: 'e',
  fGrp: 'f',
  fPos: 'g',
  fUnit: 'h',
  fDfCl: 'i',
  fPurp: 'j',
  fVolt: 'k',
  fPwr: 'l',
  fCurr: 'm',
  fPhas: 'n',
  fPWdt: 'o',
  fDist: 'p',
  fCrCb: 'q',
  fCrNo: 'r',
  fCrNa: 's',
  fMode: 't',
  fWght: 'u',
  fAccs: 'v',
  fDim: 'w',
  fCol: 'x',
  fGob: 'y',
  fOfst: 'z',
  fA01: 'd1',
};

import React from 'react';
import DocList from '../DocList/DocList';
import { ScrollArea, Stack } from '@mantine/core';

function DocDrawer({ close }) {
  return (
    <div>
      <Stack style={{ height: '100%' }} justify='space-between' align='stretch'>
        <ScrollArea p='md' type='auto' style={{ height: '100%' }}>
          <DocList handleClose={close} />
        </ScrollArea>
      </Stack>
    </div>
  );
}

export default DocDrawer;

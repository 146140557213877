import { LiveObject, LiveMap, LiveList } from '@liveblocks/core';

const RANGE_FILL_TIMEOUT = 150;

export async function liveMutationSetter(
  { storage },
  params,
  overridePath = null,
  preMutations = []
) {
  let { newValue: value } = params;
  const { rootBranch } = params.context;

  // If value is a promise, await it
  // This specifically enables async range fill handling
  if (value instanceof Promise) {
    value = await value;
  }

  let path;

  // If overridePath is an array, spread it into path
  if (Array.isArray(overridePath)) {
    path = [...overridePath];
  } else {
    path = [params.colDef.field];
  }

  const mutations = [...preMutations];

  // If this is from the clipboard, add the value to the clipboard mutations list
  if (typeof value === 'string' && value?.startsWith('_PASTE_OPERATION>')) {
    const extractedValue = value.replace('_PASTE_OPERATION>', '');
    mutations.push({
      path: [rootBranch, params.node.data._id, ...path],
      value: extractedValue,
      action: 'update',
    });

    processMutations(mutations, storage);
    return true;
  }

  // If there is a range selected, compile a list of nodes to update
  const cellRanges = params.api.getCellRanges();

  // If context has a rangefill timestamp that is < 150ms old, do not use range fill
  if (
    cellRanges.length === 0 ||
    (params.context.rangeFill && Date.now() - params.context.rangeFill < RANGE_FILL_TIMEOUT)
  ) {
    mutations.push({
      path: [rootBranch, params.node.data._id, ...path],
      value: value,
      action: 'update',
    });
    processMutations(mutations, storage);
    return;
  } else if (
    params.context.rangeFill &&
    Date.now() > params.context.rangeFill + RANGE_FILL_TIMEOUT
  ) {
    delete params.context.rangeFill;
  }

  if (cellRanges) {
    let cellValue = value;
    let increment = 0;

    if (typeof value === 'string' && value.match(/^-?[0-9.]+(?:[+-]\d*)?$/)) {
      cellValue = Number(value.match(/^-?[0-9.]+/)[0]);
      const match = value.match(/[+-]\d*$/);
      increment = match ? Number(match[0]) || (value.endsWith('-') ? -1 : 1) : 0;
    }

    let totalIncrement = 0;

    cellRanges.forEach(function (range) {
      // get starting and ending row, remember rowEnd could be before rowStart
      let startRow = Math.min(range.startRow.rowIndex, range.endRow.rowIndex);
      let endRow = Math.max(range.startRow.rowIndex, range.endRow.rowIndex);

      for (let rowIndex = startRow; rowIndex <= endRow; rowIndex++) {
        let incrementalValue = cellValue + totalIncrement;
        range.columns.forEach((column) => {
          const rowModel = params.api.getModel();
          const rowNode = rowModel.getRow(rowIndex);
          const isGroupedRow = rowNode?.data?._id === undefined;
          if (isGroupedRow) return;
          const rowId = rowNode.data._id;
          mutations.push({
            path: [rootBranch, rowId, ...path],
            value: increment ? incrementalValue : cellValue,
            action: 'update',
          });
          if (increment) {
            totalIncrement += increment;
          }
        });
      }
    });
  }
  // console.log('PROCESS', value);
  processMutations(mutations, storage);
  return true;
}

function processMutations(mutations, storage) {
  // Iterate the mutations
  mutations.forEach((mutation) => {
    const { action, path, value, preserve = false, initialKeys = [] } = mutation;

    const key = path[path.length - 1];
    const relPath = path.slice(0, path.length - 1);
    // console.log(mutation);
    if (action === 'add' || action === 'update') {
      const target = getTarget(relPath, storage);
      // Check if target is a LiveObject, LiveMap or LiveList
      if (target instanceof LiveObject || target instanceof LiveMap || target instanceof LiveList) {
        target.set(key, value);
      } else {
        console.log('TARGET', target, relPath);
        console.warn('Cannot set value on object', target);
      }
    }

    if (action === 'delete') {
      const target = getTarget(relPath, storage);
      target.delete(key);
    }
  });
}

function getTarget(path, obj) {
  if (path.length === 0) return obj;
  const key = path[0];
  let nextObj = obj;
  if (key !== '') {
    if (!obj.get(key)) {
      const newObj = new LiveObject();
      obj.set(key, newObj);
    }
    nextObj = obj.get(key);
  }
  return getTarget(path.slice(1), nextObj);
}

function getLiveValue(value) {
  if (typeof value === 'string' || typeof value === 'number') return value;
  if (value && typeof value === 'object') {
    const liveVal = new LiveObject();

    Object.entries(value).forEach(([key, val]) => {
      liveVal.set(key, getLiveValue(val));
    });
  }
}

import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { PERMIT_VIEWER } from '../../constants/permissions';

const host =
  window.location.hostname === 'eclipse.tools'
    ? 'https://api.eclipse.tools'
    : window.location.hostname;
const port = host === 'localhost' || host.startsWith('10.0.0') ? ':3030' : '';
const protocol = host.includes('https://') ? '' : 'http://';
console.log(`Opening connection at ${host}${port} in ${process.env.NODE_ENV} mode`);

export const coreApi = createApi({
  reducerPath: 'coreApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${protocol}${host}${port}`,
    tagTypes: ['Docs', 'DocUsers'],
    prepareHeaders: (headers, { getState }) => {
      const session_jwt = getState().user.userData?.session_jwt;

      if (session_jwt) {
        headers.set('authorization', `Bearer ${session_jwt}`);
      }

      return headers;
    },
  }),
  endpoints: (build) => ({
    // AUTHENTICATION
    createMagicLink: build.mutation({
      query: (body) => ({
        url: 'magic-link',
        method: 'POST',
        body,
      }),
    }),
    getMagicLinkAuthorization: build.query({
      query: (token) => ({
        url: `authorize?token=${token}`,
        method: 'GET',
      }),
    }),
    createSessionAuthorization: build.mutation({
      query: () => {
        // Allow local dev override
        if (
          process.env.NODE_ENV === 'development' &&
          process.env.REACT_APP_LOGIN_OVERRIDE === 'true'
        ) {
          console.warn('Development Login Override ACTIVE');
          return {
            url: `authorize`,
            method: 'POST',
            body: {
              devOverride: true,
            },
          };
        }
        return {
          url: `authorize`,
          method: 'GET',
        };
      },
    }),
    getLogout: build.query({
      query: () => ({
        url: `logout`,
        method: 'GET',
      }),
    }),
    getLogoutAllSessions: build.query({
      query: () => ({
        url: `logout?revokeAllSessions=true`,
        method: 'GET',
      }),
    }),

    // DOCUMENTS
    getDocs: build.query({
      query: (query) => ({
        url: query ? `docs?${new URLSearchParams(query).toString()}` : `docs`,
        method: 'GET',
      }),
      providesTags: (result) => {
        return result?.data
          ? [result.data.map(({ id }) => ({ type: 'Docs', id })), { type: 'Docs', id: 'LIST' }]
          : [{ type: 'Docs', id: 'LIST' }];
      },
    }),
    getDoc: build.query({
      query: (id) => ({
        url: `docs/${id}`,
        method: 'GET',
      }),
      providesTags: (result, error, id) => [{ type: 'Docs', id }],
    }),
    createDoc: build.mutation({
      query: (body) => ({
        url: `docs`,
        method: 'POST',
        body,
      }),
      invalidatesTags: [{ type: 'Docs', id: 'LIST' }],
    }),
    updateDoc: build.mutation({
      query: ({ id, body }) => ({
        url: `docs/${id}`,
        method: 'PATCH',
        body,
      }),
      invalidatesTags: (result, error, { id }) => [
        { type: 'Docs', id },
        { type: 'Docs', id: 'LIST' },
      ],
    }),
    deleteDoc: build.mutation({
      query: (id) => ({
        url: `docs/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: (result, error, { id }) => [{ type: 'Docs', id: 'LIST' }],
    }),

    // DOC USERS
    getDocUser: build.query({
      query: ({ docId, userId }) => ({
        url: `doc-users?doc_id=${docId}&user_id=${userId}`,
        method: 'GET',
      }),
      providesTags: (result) => [{ type: 'DocUsers', id: result.data[0].id }],
    }),
    getDocUsers: build.query({
      query: (docId) => ({
        url: `doc-users?doc_id=${docId}`,
        method: 'GET',
      }),
      providesTags: (result) => {
        return result?.data
          ? [
              result.data.map(({ id }) => ({ type: 'DocUsers', id })),
              { type: 'DocUsers', id: 'LIST' },
            ]
          : [{ type: 'DocUsers', id: 'LIST' }];
      },
    }),
    updateDocUser: build.mutation({
      query: ({ id, body }) => ({
        url: `doc-users/${id}`,
        method: 'PATCH',
        body,
      }),
      invalidatesTags: (result, error, { id }) => [
        { type: 'DocUsers', id },
        { type: 'DocUsers', id: 'LIST' },
      ],
    }),
    deleteDocUser: build.mutation({
      query: (id) => ({
        url: `doc-users/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: (result, error, { id }) => [
        { type: 'DocUsers', id },
        { type: 'DocUsers', id: 'LIST' },
        { type: 'Docs', id: 'LIST' },
      ],
    }),

    // INVITE USER TO DOC
    inviteUserToDoc: build.mutation({
      query: ({ docId, email, permission = PERMIT_VIEWER }) => ({
        url: `invite-user-to-doc`,
        method: 'POST',
        body: { doc_id: docId, email, permission },
      }),
      invalidatesTags: (result, error, { id }) => [
        { type: 'DocUsers', id },
        { type: 'DocUsers', id: 'LIST' },
      ],
    }),
    // USERS
    getUser: build.query({
      query: (id) => ({
        url: `users/${id}`,
        method: 'GET',
      }),
      providesTags: (result) => [{ type: 'User' }],
    }),
    updateUser: build.mutation({
      query: ({ id, body }) => ({
        url: `users/${id}`,
        method: 'PATCH',
        body,
      }),
      invalidatesTags: (result, error, { id }) => [{ type: 'User' }],
    }),
    deleteUser: build.mutation({
      query: (id) => ({
        url: `users/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: (result, error, { id }) => [{ type: 'User' }],
    }),
  }),
});

export const {
  useCreateMagicLinkMutation,
  useGetMagicLinkAuthorizationQuery,
  useCreateSessionAuthorizationMutation,
  useGetLogoutQuery,
  useGetLogoutAllSessionsQuery,
  useGetDocsQuery,
  useGetDocQuery,
  useCreateDocMutation,
  useUpdateDocMutation,
  useDeleteDocMutation,
  useGetDocUserQuery,
  useGetDocUsersQuery,
  useUpdateDocUserMutation,
  useDeleteDocUserMutation,
  useInviteUserToDocMutation,
  useGetUserQuery,
  useUpdateUserMutation,
  useDeleteUserMutation,
} = coreApi;

import { Button, Center, Group, Loader, Select, Text, TextInput, Title } from '@mantine/core';
import React, { useEffect, useState } from 'react';
import { useGetDocUsersQuery, useInviteUserToDocMutation } from '../../app/services/coreApi';
import { PERMIT_EDITOR, PERMIT_OWNER, PERMIT_VIEWER } from '../../constants/permissions';
import EmailSearchIcon from '../Icons/EmailSearchIcon';
import UserPermitBlock from '../Users/UserPermitBlock';

function SharingBlock({ doc, onRemove }) {
  const hasEditPermission = doc?.permission >= PERMIT_EDITOR;
  const {
    data: users,
    isLoading,
    isSuccess,
  } = useGetDocUsersQuery(doc.id, { refetchOnMountOrArgChange: true });
  const [
    inviteUser,
    { isLoading: isInvitingUser, isSuccess: isInviteSuccess, error: inviteError },
  ] = useInviteUserToDocMutation();

  const [inviteEmail, setInviteEmail] = useState('');
  const [permission, setPermission] = useState(PERMIT_VIEWER);
  const emailIsValid = inviteEmail.match(
    /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/g
  );

  const handleInvite = () => {
    inviteUser({ docId: doc.id, email: inviteEmail, permission });
  };

  useEffect(() => {
    if (isInviteSuccess) {
      setInviteEmail('');
    }
    // eslint-disable-next-line
  }, [isInviteSuccess]);

  // If users exist, sort it so that the owner is first
  const sortedUsers =
    users && Array.isArray(users.data)
      ? [...users.data].sort((a, b) => {
          if (a.permission === PERMIT_OWNER) {
            return -1;
          } else if (b.permission === PERMIT_OWNER) {
            return 1;
          } else {
            return 0;
          }
        })
      : [];

  return (
    <>
      <Title order={5}>Invite Collaborators</Title>
      {!hasEditPermission && (
        <Text mt='xs' size='sm' c='dimmed'>
          Only document editors and owners can invite new collaborators
        </Text>
      )}
      {hasEditPermission && (
        <>
          <Group>
            <TextInput
              disabled={isInvitingUser}
              value={inviteEmail}
              inputMode='email'
              onChange={(e) => setInviteEmail(e.currentTarget.value)}
              mt='md'
              icon={<EmailSearchIcon />}
              placeholder='Email'
              style={{ width: '100%' }}
            />
          </Group>
          <Group style={{ width: '100%' }} spacing='6px' p={0} mt='xs' justify='flex-end'>
            <Select
              withCheckIcon={false}
              value={permission.toString()}
              onChange={(value) => setPermission(Number(value))}
              disabled={isInvitingUser}
              size='xs'
              data={[
                { value: PERMIT_EDITOR.toString(), label: 'Editor' },
                { value: PERMIT_VIEWER.toString(), label: 'Viewer' },
              ]}
              style={{ width: 90 }}
            />
            <Button
              size='xs'
              style={{ width: 70 }}
              disabled={isInvitingUser || !emailIsValid}
              onClick={handleInvite}
            >
              {isInvitingUser ? <Loader size='xs' /> : 'Invite'}
            </Button>
          </Group>
        </>
      )}
      {isInviteSuccess && (
        <Text size='sm' c='dimmed' mt='xs'>
          Success! If this user exists in the system, they have been invited to collaborate on the
          document.
        </Text>
      )}
      {inviteError && (
        <Text size='sm' c='red' mt='xs'>
          {inviteError?.data?.message || ''}
        </Text>
      )}

      <Title order={5} mt='xl' mb='md'>
        Collaborators
      </Title>
      {isLoading && (
        <Center>
          <Loader />
        </Center>
      )}
      {isSuccess &&
        sortedUsers.map((user) => (
          <UserPermitBlock key={user.id} docUser={user} doc={doc} onRemove={onRemove} />
        ))}
    </>
  );
}

export default SharingBlock;

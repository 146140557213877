import { ActionIcon, AvatarGroup, Box, Flex, Group, Indicator, Text, Tooltip } from '@mantine/core';
import React from 'react';
import useHint from '../../../hooks/useHint';
import UserAvatar from '../../Users/UserAvatar';
import VerticalDivider from '../../DataGrid/menubars/VerticalDivider';

import UndoRedo from './UndoRedo';
import { useOthers, useStatus } from '../../../app/liveblocksClient';
import { useGetDocQuery } from '../../../app/services/coreApi';
import UserAvatarMenu from '../../Users/UserAvatarMenu';
import { Actions } from 'flexlayout-react';

const GRID_HINT = 'Add fixture grid to current tab set';
const FIXTURE_DEF_HINT = 'Add fixture definition grid to current tab set';
const PAGE_HINT = 'Add page editor to current tab set';

const statusColors = {
  initial: 'gray',
  connecting: 'yellow',
  connected: 'green',
  reconnecting: 'orange',
  disconnected: 'red',
};

function TopMenu({ model, layout, docId }) {
  // Liveblocks
  const status = useStatus();
  const others = useOthers();
  const presence = Array.isArray(others)
    ? others.map((u) => ({ id: u.id, display_name: u.info.name }))
    : [];

  const gridHint = useHint(GRID_HINT, 'TopMenuGrid');
  const fixtureDefHint = useHint(FIXTURE_DEF_HINT, 'TopMenuFixtureDef');
  const pageHint = useHint(PAGE_HINT, 'TopMenuPage');

  const { data: docData } = useGetDocQuery(docId);

  const docTitle = docData?.title || 'Untitled Document';

  const ensureActiveTabset = () => {
    const rootNode = model.getRoot();
    const activeTabset = model.getActiveTabset();
    if (!activeTabset) {
      const tabset = model.getFirstTabSet(rootNode);
      if (tabset) {
        model.doAction(Actions.setActiveTabset(tabset.getId()));
      }
    }
  };

  const addGrid = () => {
    ensureActiveTabset();
    layout?.current?.addTabToActiveTabSet({
      component: 'fGrid',
      name: 'Fixture Grid',
    });
  };

  const addFixtureDef = () => {
    ensureActiveTabset();
    layout?.current?.addTabToActiveTabSet({
      component: 'fDefs',
      name: 'Fixture Definitions',
    });
  };

  const addPages = () => {
    ensureActiveTabset();
    layout?.current?.addTabToActiveTabSet({
      component: 'pages',
      name: 'Page Editor',
    });
  };

  return (
    <Box
      style={{
        width: '100%',
        height: 'calc(3rem - 1px)',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        boxShadow: 'rgb(0 0 0) 0px 0px 8px 4px',
      }}
    >
      <Flex
        gap='xs'
        justify='left'
        align='center'
        direction='row'
        pl='sm'
        style={{ height: '100%' }}
        ml='3rem'
      >
        <ActionIcon.Group>
          <Tooltip label='Fixture Grid' placement='bottom' withArrow offset={10}>
            <ActionIcon radius='md' size='lg' onClick={addGrid} variant='light' ref={gridHint}>
              <span className='material-symbols-rounded'>view_list</span>
            </ActionIcon>
          </Tooltip>
          <Tooltip label='Fixture Definitions' placement='bottom' withArrow offset={10}>
            <ActionIcon
              radius='md'
              size='lg'
              onClick={addFixtureDef}
              variant='light'
              ref={fixtureDefHint}
            >
              <span className='material-symbols-rounded'>apps</span>
            </ActionIcon>
          </Tooltip>
          <Tooltip label='Pages' placement='bottom' withArrow offset={10}>
            <ActionIcon radius='md' size='lg' onClick={addPages} variant='light' ref={pageHint}>
              <span className='material-symbols-rounded'>library_books</span>
            </ActionIcon>
          </Tooltip>
        </ActionIcon.Group>
      </Flex>
      <Group>
        <Text style={{ cursor: 'default', userSelect: 'none' }}>{docTitle}</Text>
      </Group>
      <Group gap='xs'>
        <UndoRedo />
        <VerticalDivider />
        <Flex pr='md'>
          <AvatarGroup>
            {presence.map((user, index) => (
              <UserAvatar key={user.id} user={user} size='sm' radius='xl' />
            ))}
            <Indicator color={statusColors[status]}>
              <UserAvatarMenu size='sm' radius='xl' />
            </Indicator>
          </AvatarGroup>
        </Flex>
      </Group>
    </Box>
  );
}

export default TopMenu;

import { Popover, Select, ActionIcon, SegmentedControl } from '@mantine/core';
import React, { useState } from 'react';
import { PAGE_SIZES } from './pageSizes';
import { useMutation, useStorage } from '../../app/liveblocksClient';

function PaperConfigEditor({ currentPage }) {
  const [suppressClickOutside, setSuppressClickOutside] = useState(false);

  const paperSize = useStorage((root) => root.pages.get(currentPage)?.page?.s);
  const paperOrientation = useStorage((root) => root.pages.get(currentPage)?.page?.o);

  // Write mutation for paperSize
  const updatePaperSize = useMutation(
    ({ storage }, params) => {
      const page = storage.get('pages').get(currentPage).get('page');
      page.set('s', params);
      page.set('d', PAGE_SIZES[params]);
    },
    [currentPage]
  );
  const updatePaperOrientation = useMutation(
    ({ storage }, params) => {
      storage.get('pages').get(currentPage).get('page').set('o', params);
    },
    [currentPage]
  );

  return (
    <Popover closeOnClickOutside={suppressClickOutside}>
      <Popover.Target>
        <ActionIcon size='md' variant='light' className='action-with-dropdown'>
          <span className='material-symbols-rounded'>settings</span>
        </ActionIcon>
      </Popover.Target>
      <Popover.Dropdown>
        <Select
          label='Paper Size'
          data={Object.keys(PAGE_SIZES)}
          value={paperSize}
          onChange={updatePaperSize}
          onDropdownOpen={() => setSuppressClickOutside(false)}
          onDropdownClose={() => setSuppressClickOutside(true)}
          allowDeselect={false}
          searchable
        />
        <SegmentedControl
          mt='md'
          data={[
            { value: 'p', label: 'Portrait' },
            { value: 'l', label: 'Landscape' },
          ]}
          fullWidth
          size='xs'
          value={paperOrientation}
          onChange={updatePaperOrientation}
        />
      </Popover.Dropdown>
    </Popover>
  );
}

export default PaperConfigEditor;

import { createSlice } from '@reduxjs/toolkit';

export const hintSlice = createSlice({
  name: 'hint',
  initialState: {
    hint: null,
    src: null,
  },
  reducers: {
    setHint: (state, action) => {
      state.hint = action.payload.hint;
      state.src = action.payload.src;
    },
    clearHint: (state, action) => {
      if (state.src === action.payload.src) {
        state.hint = null;
        state.src = null;
      }
    },
  },
});

export const { setHint, clearHint } = hintSlice.actions;

export default hintSlice.reducer;

export const selectHint = (state) => state.hint.hint;

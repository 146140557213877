import React from 'react';
import { Rect } from 'react-konva';

function CanvasPageLayer({ pageConfig, sectionConfig }) {
  const { pageWidth, pageHeight, screenDpi, margin } = pageConfig;

  const pageHeightWithoutMargin = pageHeight - margin * 2;

  return (
    <>
      <Rect
        width={pageWidth * screenDpi}
        height={pageHeight * screenDpi}
        fill='#fff'
        stroke={'#141516'}
        strokeWidth={1}
        shadowOffset={{ x: 0, y: 4 }}
        shadowBlur={32}
        shadowColor='#000'
        shadowOpacity={0.5}
      />
      <Rect
        width={(pageWidth - margin * 2) * screenDpi}
        height={pageHeightWithoutMargin * screenDpi}
        x={margin * screenDpi}
        y={margin * screenDpi}
        fillEnabled={false}
        stroke={'#f6f6f6'}
        strokeWidth={1}
      />
    </>
  );
}

export default CanvasPageLayer;

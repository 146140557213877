import {
  Button,
  Center,
  Divider,
  Group,
  Image,
  LoadingOverlay,
  Paper,
  Text,
  TextInput,
} from '@mantine/core';
import { useForm } from '@mantine/form';
import React, { useState } from 'react';
import { useCreateMagicLinkMutation } from '../../app/services/coreApi';

function Login() {
  const [getMagicLink, { isLoading, isSuccess }] = useCreateMagicLinkMutation();
  const [globalError, setGlobalError] = useState(null);

  const handleSubmit = async (values) => {
    try {
      const response = await getMagicLink(values);

      if (!response?.data?.status || !response.data.status === 200) {
        throw new Error(response.data?.message || 'Unable to request magic link');
      }
    } catch (err) {
      console.log(err);
      setGlobalError(err.message || err);
    }
  };

  const form = useForm({
    initialValues: {
      email: '',
    },
    validate: {
      email: (value) => (/^\S+@\S+$/.test(value) ? null : 'Invalid email'),
    },
  });

  if (isSuccess) {
    return (
      <div
        style={{
          width: '100vw',
          height: '100vh',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Paper radius='md' p='xl' withBorder w='500px' pos='relative'>
          <Text size='lg' align='center'>
            Welcome back!
          </Text>

          <Text mt='xl' align='center'>
            Thank you, please login using the magic link in your inbox
          </Text>
        </Paper>
      </div>
    );
  }

  return (
    <div
      style={{
        width: '100vw',
        height: '100vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Paper radius='md' p='xl' withBorder w='500px' pos='relative'>
        <LoadingOverlay visible={isLoading} />
        <form
          onSubmit={form.onSubmit((values) => {
            handleSubmit(values);
          })}
        >
          <Group justify='center'>
            <Image src='/logo192.png' alt='Eclipse Logo' width={100} height={100} />
          </Group>
          <Text size='lg' align='center'>
            Welcome back!
          </Text>
          <TextInput
            disabled={isLoading}
            mt='md'
            size='xl'
            placeholder='Enter your email'
            {...form.getInputProps('email')}
          />

          <Text size='sm' color='red' align='center' mt='md'>
            {globalError && (globalError?.message || 'General Error')}
          </Text>

          <Center>
            <Button mt='md' size='md' fullWidth type='submit' disabled={isLoading}>
              Sign in via magic link
            </Button>
          </Center>
          {/* <Divider mt='xl' mb='xl' label='or' labelPosition='center' />
          <Text align='center'>Try Eclipse for free with no signup</Text> */}
        </form>
      </Paper>
    </div>
  );
}

export default Login;

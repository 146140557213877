import { createSlice } from '@reduxjs/toolkit';

export const userSlice = createSlice({
  name: 'user',
  initialState: {
    userData: null,
    localUserData: null,
    isAuthenticated: false,
    isAnonymous: false,
    isPreauthComplete: false,
  },
  reducers: {
    login: (state, action) => {
      state.userData = action.payload;
      state.isAuthenticated = true;
      state.isAnonymous = false;
      state.isPreauthComplete = true;
    },
    setLocalUserData: (state, action) => {
      state.localUserData = action.payload;
    },
    abortPreauth: (state) => {
      state.isPreauthComplete = true;
      state.userData = null;
      state.isAuthenticated = false;
      state.isAnonymous = false;
    },
    setPreauthData: (state, action) => {
      state.userData = action.payload;
      state.isAuthenticated = false;
      state.isAnonymous = false;
      state.isPreauthComplete = false;
    },
    logout: (state) => {
      state.userData = null;
      state.localUserData = null;
      state.isAuthenticated = false;
      state.isAnonymous = false;
      state.isPreauthComplete = true;
    },
  },
});

export const { login, setLocalUserData, abortPreauth, setPreauthData, logout } = userSlice.actions;
export default userSlice.reducer;

export const selectUserIsAuthenticated = (state) => state.user.isAuthenticated;
export const selectIsPreauthComplete = (state) => state.user.isPreauthComplete;
export const selectHasUserData = (state) => state.user.userData !== null;
export const selectUserData = (state) => state.user.userData;
export const selectUserId = (state) => state?.user?.userData?.user?.user_id || null;
export const selectUserToken = (state) => state.user.userData.session_jwt;
export const selectLocalUserData = (state) => state.user.localUserData;
export const selectUserAwarenessData = (state) => ({
  user_id: state.user.localUserData.id,
  user: {
    display_name: state.user.localUserData.display_name,
  },
});
export const selectUserDisplayName = (state) => state?.user?.localUserData?.display_name;

import React, { useEffect, useRef, useState } from 'react';
import { Popover, TextInput, Button, Loader, ActionIcon, Text } from '@mantine/core';

import { useCreateDocMutation } from '../../app/services/coreApi';

function NewDocButton() {
  const inputRef = useRef(null);
  const [title, setTitle] = useState('New Document');
  const [open, setOpen] = useState(false);
  const [createDoc, { isLoading: isCreatingDoc, isSuccess, error }] = useCreateDocMutation();

  const handleOpen = () => {
    setTimeout(() => {
      // select all text in the input
      inputRef.current.select();
    }, 100);
  };

  const formSubmit = (e) => {
    e.preventDefault();
    handleCreateDoc();
  };

  const handleCreateDoc = () => {
    createDoc({ title });
  };

  useEffect(() => {
    if (isSuccess) {
      setTitle('New Document');
      setOpen(false);
    }
  }, [isSuccess]);

  return (
    <Popover
      width={300}
      trapFocus
      withArrow
      shadow='md'
      withinPortal
      onChange={setOpen}
      opened={open}
      onOpen={handleOpen}
    >
      <Popover.Target>
        <ActionIcon
          className='action-with-dropdown'
          variant='light'
          size='lg'
          onClick={() => setOpen((o) => !o)}
          disabled={isCreatingDoc}
        >
          <span className='material-symbols-rounded'>add_box</span>
        </ActionIcon>
      </Popover.Target>
      <Popover.Dropdown>
        <form onSubmit={formSubmit}>
          <TextInput
            ref={inputRef}
            label='Title'
            placeholder='Document Title'
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            maxLength={255}
            required
          />
          {error && (
            <Text c='red' size='xs' mt='md'>
              {error.message || error.data?.message || 'General error'}
            </Text>
          )}
          <Button
            disabled={isCreatingDoc}
            variant='light'
            fullWidth
            mt='md'
            onClick={handleCreateDoc}
          >
            {isCreatingDoc ? <Loader size='xs' /> : 'Create'}
          </Button>
        </form>
      </Popover.Dropdown>
    </Popover>
  );
}

export default NewDocButton;

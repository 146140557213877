import React, { useEffect, useRef, useState } from 'react';
import TopMenu from './TopMenu/TopMenu';

import * as FlexLayout from 'flexlayout-react';
import './docEditor-dark.css';

import { calculateGridColumns } from '../DataGrid/colDefs/calcFixtureGridCols';
import { DATAVIEW } from '../../constants/dataViews';

import fixtureGridConfig from '../DataGrid/configs/fixtureGridConfig';
import { calculateFixtureDefGridCols } from '../DataGrid/colDefs/calcFixtureDefGridCols';
import fixtureDefGridConfig from '../DataGrid/configs/fixtureDefGridConfig';

import FixtureDefGridTopMenu from '../DataGrid/menubars/FixtureDefGridTopMenu';
import FixtureGridTopMenu from '../DataGrid/menubars/FixtureGridTopMenu';
import PageEditor from '../PageEditor/PageEditor';
import LiveGrid from '../DataGrid/LiveGrid';
import { selectLiveFixDefs, selectLiveFixtures, useStatus } from '../../app/liveblocksClient';
import { LoadingOverlay, Text } from '@mantine/core';
import { useGetDocUserQuery, useUpdateDocUserMutation } from '../../app/services/coreApi';
import { selectUserId } from '../../features/user/userSlice';
import { useSelector } from 'react-redux';
import FooterBar from './FooterBar/FooterBar';

const TABSET_HEIGHT = 32;

const BLANK_LAYOUT = {
  global: {
    tabSetTabStripHeight: TABSET_HEIGHT,
  },
  borders: [],
  layout: {
    type: 'row',
    children: [
      {
        active: true,
        type: 'tabset',
        children: [
          {
            type: 'tab',
            name: 'Fixtures',
            component: 'fGrid',
          },
          {
            type: 'tab',
            name: 'Fixture Definitions',
            component: 'fDefs',
          },
        ],
      },
    ],
  },
};

export const FIXTURE_GRID_CONFIG = {
  dataView: DATAVIEW.FIXTURE_GRID,
  dataSelector: selectLiveFixtures,
  rootBranch: 'fixtures',
  additionalSubscription: selectLiveFixDefs,
  newRowFocusCell: 'fType',
  getGridColumns: calculateGridColumns,
  getContextMenuItems: (params, deleteLiveRows) => [
    'cut',
    'copy',
    'paste',
    'copyWithHeaders',
    {
      name: 'Delete',
      icon: 'X',
      action: function () {
        //  Handle delete
        deleteLiveRows(params.node.data._id);
      },
    },
    {
      name: 'Delete All Selected',
      icon: 'X',
      disabled: params.api.getSelectedNodes().length <= 1,
      action: function () {
        const rows = [];
        // Delete the selected nodes
        params.api.getSelectedNodes().forEach((node) => {
          rows.push(node.data._id);
        });
        // Reset selected rows and ranges
        params.api.deselectAll();
        params.api.clearRangeSelection();
        // Handle delete
        deleteLiveRows(rows);
      },
    },
    'separator',
    'export',
  ],
  gridConfig: fixtureGridConfig,
  topMenuComponent: FixtureGridTopMenu,
};

export const FIXTURE_DEF_GRID_CONFIG = {
  dataView: DATAVIEW.TYPES_GRID,
  dataSelector: selectLiveFixDefs,
  rootBranch: 'fixdefs',
  additionalSubscription: (root) => root.meta.version,
  newRowFocusCell: 'fType',
  getGridColumns: (dispatch) => calculateFixtureDefGridCols(dispatch),
  getContextMenuItems: (params, deleteLiveRows) => [
    'cut',
    'copy',
    'paste',
    'copyWithHeaders',
    {
      name: 'Delete',
      icon: 'X',
      action: function () {
        deleteLiveRows(params.node.data._id);
      },
    },
    {
      name: 'Delete All Selected',
      icon: 'X',
      disabled: params.api.getSelectedNodes().length <= 1,
      action: function () {
        const rows = [];
        // Delete the selected nodes
        params.api.getSelectedNodes().forEach((node) => {
          rows.push(node.data._id);
        });
        // Reset selected rows and ranges
        params.api.deselectAll();
        params.api.clearRangeSelection();
        // Handle delete
        deleteLiveRows(rows);
      },
    },
    'separator',
    'export',
  ],
  gridConfig: fixtureDefGridConfig,
  topMenuComponent: FixtureDefGridTopMenu,
};

function DocEditorInnerTabs({ docId }) {
  // Get persisted layout from docUser
  const userId = useSelector(selectUserId);
  const { data: docUser, isSuccess: docUserIsSuccess } = useGetDocUserQuery({ docId, userId });
  const [updateLayout] = useUpdateDocUserMutation();
  const docUserId = docUser?.data?.[0]?.id;
  const userLayout = docUser?.data?.[0]?.layout;
  const [persistedStateApplied, setPersistedStateApplied] = useState(false);

  // Liveblocks Hooks
  const status = useStatus();

  const [model, setModel] = useState(FlexLayout.Model.fromJson(BLANK_LAYOUT));

  const layoutRef = useRef(null);
  const factory = (node) => {
    const component = node.getComponent();
    if (component === 'fGrid') {
      return <LiveGrid docId={docId} config={FIXTURE_GRID_CONFIG} />;
    }
    if (component === 'fDefs') {
      return <LiveGrid docId={docId} config={FIXTURE_DEF_GRID_CONFIG} />;
    }
    if (component === 'pages') {
      return <PageEditor docId={docId} />;
    }
    if (component === 'empty') {
      return <div>Empty</div>;
    }
    return <div>Empty</div>;
  };

  const handlePersistModel = (model) => {
    if (!model) return;
    updateLayout({ id: docUserId, body: { layout: model.toJson() } });
  };

  // Once the layout is loaded and the document is connected, apply the persisted layout
  useEffect(() => {
    if (persistedStateApplied) return;
    if (userLayout && status === 'connected') {
      if (userLayout.hasOwnProperty('global')) {
        setModel(FlexLayout.Model.fromJson(userLayout));
        setPersistedStateApplied(true);
      } else {
        setModel(FlexLayout.Model.fromJson(BLANK_LAYOUT));
        setPersistedStateApplied(true);
      }
    }
  }, [userLayout, status, persistedStateApplied]);

  // If doc id changes reset persisted state
  useEffect(() => {
    if (!persistedStateApplied) return;
    setPersistedStateApplied(false);
    // eslint-disable-next-line
  }, [docId]);

  if (status === 'initial' || status === 'connecting' || !docUserIsSuccess) {
    return (
      <LoadingOverlay
        visible={true}
        styles={{
          overlay: {
            backgroundColor: '#13141D',
          },
        }}
      />
    );
  }

  return (
    <>
      <TopMenu model={model} layout={layoutRef} docId={docId} />
      <FlexLayout.Layout
        model={model}
        factory={factory}
        ref={layoutRef}
        onModelChange={(model) => {
          handlePersistModel(model);
        }}
        onTabSetPlaceHolder={() => <EmptyTabPlaceholder />}
      />
      <FooterBar />
    </>
  );
}

export default DocEditorInnerTabs;

function EmptyTabPlaceholder() {
  return (
    <div
      className='flexlayout__tab empty__tab'
      style={{ height: `calc(100% - ${TABSET_HEIGHT}px)` }}
    >
      <Text c='dimmed' fs='italic'>
        No tabs active
      </Text>
    </div>
  );
}

import { Group } from '@mantine/core';
import React from 'react';
import ActionCreateNewFixtureRow from './ActionCreateNewFixtureRow';
import ViewController from './ViewController';
import VerticalDivider from './VerticalDivider';

function FixtureGridTopMenu({ context }) {
  return (
    <Group justify='flex-start' spacing={'0px'} align='center'>
      <ActionCreateNewFixtureRow context={context} />
      <VerticalDivider />
      <ViewController context={context} />
    </Group>
  );
}

export default FixtureGridTopMenu;

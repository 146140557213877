import React from 'react';
import GenericAddRowHandler from './GenericAddRowHandler';
import ViewController from './ViewController';
import { Group } from '@mantine/core';
import VerticalDivider from './VerticalDivider';

function FixtureDefGridTopMenu({ context }) {
  return (
    <Group justify={'flex-start'} spacing={'xs'} align='center'>
      <GenericAddRowHandler context={context} />
      <VerticalDivider />
      <ViewController context={context} />
    </Group>
  );
}

export default FixtureDefGridTopMenu;

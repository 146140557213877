import React from 'react';

import { Box } from '@mantine/core';

import { RoomProvider } from '../../app/liveblocksClient';
import DocEditorInnerTabs from './DocEditorInnerTabs';
import { useParams } from 'react-router';

function DocEditor() {
  const { docId, roomId } = useParams();

  return (
    <Box
      style={{
        width: '100%',
        height: '100%',
        overflow: 'hidden',
      }}
    >
      <RoomProvider id={roomId} initialPresence={{}}>
        <DocEditorInnerTabs docId={docId} />
      </RoomProvider>
    </Box>
  );
}

export default DocEditor;

import React, { useEffect } from 'react';
import './App.css';
import { Route, Routes } from 'react-router-dom';
import PrivateRoutes from './components/PrivateRoutes/PrivateRoutes';
import Login from './components/Login/Login';
import ValidateMagicLink from './components/ValidateMagicLink/ValidateMagicLink';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectIsPreauthComplete,
  selectUserData,
  selectUserIsAuthenticated,
  setLocalUserData,
} from './features/user/userSlice';
import PreauthHandler from './components/PreauthHandler/PreauthHandler';
import UnauthRoutes from './components/UnauthRoutes/UnauthRoutes';
import AppFrame from './components/AppFrame/AppFrame';

import { useGetUserQuery } from './app/services/coreApi';
import Dashboard from './components/Dashboard/Dashboard';
import DocEditor from './components/DocEditor/DocEditor';
import RoomTest from './components/RoomTest/RoomTest';

function App() {
  const dispatch = useDispatch();
  const appReadyToRender = useSelector(selectIsPreauthComplete);

  // Populate local user data in the store
  const isSignedIn = useSelector(selectUserIsAuthenticated);
  const stytchUser = useSelector(selectUserData);
  const { data: localUserData } = useGetUserQuery(stytchUser?.user?.user_id, { skip: !isSignedIn });

  useEffect(() => {
    if (localUserData) {
      dispatch(setLocalUserData(localUserData));
    }
    //eslint-disable-next-line
  }, [localUserData]);

  // Handle auto re-authorization before rendering app
  if (!appReadyToRender) {
    return <PreauthHandler />;
  }

  return (
    <>
      <Routes>
        <Route element={<PrivateRoutes />}>
          <Route element={<AppFrame />}>
            <Route path='/' element={<Dashboard />} />
            <Route path='/doc/:docId/:roomId' element={<DocEditor />} />
            <Route path='/room/:docId/:roomId' element={<RoomTest />} />
          </Route>
        </Route>
        <Route element={<UnauthRoutes />}>
          <Route path='login' element={<Login />} />
          <Route path='auth/login' element={<ValidateMagicLink />} />
          <Route path='auth/signup' element={<ValidateMagicLink />} />
        </Route>
      </Routes>
    </>
  );
}

export default App;

import { ActionIcon, Box, CheckIcon, Group, Loader, Select, Text } from '@mantine/core';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useDeleteDocUserMutation, useUpdateDocUserMutation } from '../../app/services/coreApi';
import { PERMIT_EDITOR, PERMIT_OWNER, PERMIT_VIEWER } from '../../constants/permissions';
import { selectUserData } from '../../features/user/userSlice';
import UserAvatar from './UserAvatar';

function UserPermitBlock({ docUser, doc, onRemove = () => {} }) {
  const [permission, setPermission] = useState(docUser.permission.toString());
  const userData = useSelector(selectUserData);
  const thisUserId = userData.user.user_id;

  const [updatePermission, { isLoading: isUpdatingPermission, error: updateError }] =
    useUpdateDocUserMutation();
  const [removeUser, { isLoading: isRemovingUser, error: deleteError }] =
    useDeleteDocUserMutation();

  // The user is this doc collaborator
  const isSelf = docUser.user_id === thisUserId;
  // This user has edit permission for others and self
  const userCanEdit = Number(doc.permission) >= PERMIT_EDITOR || isSelf;
  // The doc collaborator is the owner
  const isOwner = Number(docUser.permission) >= PERMIT_OWNER;
  // The permission has changed and can be updated
  const canSubmit = Number(docUser.permission) !== Number(permission);

  const permissionGroup = { group: 'Permission', items: [] };
  const userPermissions = [];

  if (doc.owner_id === docUser.user_id) {
    permissionGroup.items.push({
      value: PERMIT_OWNER.toString(),
      label: 'Owner',
    });
  }

  if (doc.permission >= PERMIT_EDITOR) {
    permissionGroup.items.push({
      value: PERMIT_EDITOR.toString(),
      label: 'Editor',
    });
  }

  permissionGroup.items.push({
    value: PERMIT_VIEWER.toString(),
    label: 'View Only',
  });

  userPermissions.push(permissionGroup);

  if ((doc.permission >= PERMIT_EDITOR && docUser.permission < PERMIT_OWNER) || isSelf) {
    userPermissions.push({
      group: 'Remove User',
      items: [{ value: 'REMOVE', label: 'Remove' }],
    });
  }

  const handleUpdate = async () => {
    if (permission === 'REMOVE') {
      const res = await removeUser(docUser.id);
      if (res.error) {
        console.log('Error removing user', res.error);
      } else {
        console.log('User removed');
        if (isSelf) {
          onRemove();
        }
      }
    } else {
      updatePermission({ id: docUser.id, body: { permission: Number(permission) } });
    }
  };

  const selectIsDisabled = isOwner || isUpdatingPermission || isRemovingUser || !userCanEdit;

  return (
    <Box
      w='100%'
      pt='sm'
      pb='sm'
      sx={{
        cursor: 'pointer',
        borderBottom: '1px solid rgb(50,50,50)',
      }}
    >
      <Group justify={'space-between'}>
        <Group>
          <UserAvatar user={docUser.user} size='md' radius='xl' />
          <Text truncate>{docUser?.user?.display_name || ''}</Text>
        </Group>
        <Group spacing={'6px'}>
          <Select
            data={userPermissions}
            style={{ width: 150 }}
            value={permission?.toString()}
            onChange={(e) => setPermission(e)}
            disabled={selectIsDisabled}
          />

          <ActionIcon
            p='8px'
            variant={'default'}
            size='lg'
            disabled={isOwner || !canSubmit}
            onClick={handleUpdate}
          >
            {isUpdatingPermission || isRemovingUser ? (
              <Loader size='xs' />
            ) : (
              <span className='material-symbols-rounded'>done</span>
            )}
          </ActionIcon>
        </Group>
      </Group>
      {updateError && (
        <Text size='sm' c='red' align='center'>
          {updateError.message || 'Unknown error'}
        </Text>
      )}
      {deleteError && (
        <Text size='sm' c='red' align='center'>
          {deleteError.message || 'Unknown error'}
        </Text>
      )}
    </Box>
  );
}

export default UserPermitBlock;

// REACT
import React, { useCallback, useEffect, useId, useMemo, useState } from 'react';

// LIVEBLOCKS
import {
  useMutation,
  useOthers,
  useStorage,
  useUpdateMyPresence,
} from '../../app/liveblocksClient';

// AG GRID
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-enterprise';
import { LicenseManager } from 'ag-grid-enterprise';

import 'ag-grid-community/styles/ag-grid.css'; // Core grid CSS, always needed
import 'ag-grid-community/styles/ag-theme-quartz.css'; // Optional theme CSS
import './dataGridStyles.css';
import { useDispatch, useSelector } from 'react-redux';

// GRID HANDLERS
import { liveMutationSetter } from './setters/liveMutationSetter';
import genericFillHandler from './fillers/genericFillHandler';
import { clearScrollToNewRow, selectScrollToNewRow } from '../../features/grid/gridSlice';
import useDispatchHint from '../../hooks/useDispatchHint';
import { DATA_GRID_CELL_HINTS, baseColumns, dataHintLegend } from '../../constants/baseColumns';

LicenseManager.setLicenseKey(
  'Using_this_AG_Grid_Enterprise_key_( AG-050682 )_in_excess_of_the_licence_granted_is_not_permitted___Please_report_misuse_to_( legal@ag-grid.com )___For_help_with_changing_this_key_please_contact_( info@ag-grid.com )___( Alexander Wanuch )_is_granted_a_( Single Application )_Developer_License_for_the_application_( Synapse )_only_for_( 1 )_Front-End_JavaScript_developer___All_Front-End_JavaScript_developers_working_on_( Synapse )_need_to_be_licensed___( Synapse )_has_not_been_granted_a_Deployment_License_Add-on___This_key_works_with_AG_Grid_Enterprise_versions_released_before_( 11 December 2024 )____[v2]_MTczMzg3NTIwMDAwMA==3b0764255075d9401c407ad763e225df'
);

const KEY_TAB = 9;
const KEY_ENTER = 13;

function LiveGrid({ config }) {
  const dispatch = useDispatch();
  // GENERAL CONFIG
  const {
    dataSelector,
    rootBranch,
    additionalSubscription,
    newRowFocusCell,
    getGridColumns,
    dataView,
    getContextMenuItems,
    topMenuComponent,
    gridConfig,
  } = config;
  const gridId = useId();

  // LIVEBLOCKS
  const data = useStorage(dataSelector);
  const additionalData = useStorage(additionalSubscription);
  const updateMyPresence = useUpdateMyPresence();
  const others = useOthers();
  const mutateLiveCell = useMutation(liveMutationSetter, []);
  const deleteLiveRows = useMutation(
    ({ storage }, ids) => {
      const branch = storage.get(rootBranch);
      if (Array.isArray(ids)) {
        ids.forEach((id) => {
          branch.delete(id);
        });
      } else if (typeof ids === 'string') {
        branch.delete(ids);
      }
    },
    [rootBranch]
  );

  // HINTS
  const dispatchHint = useDispatchHint('LiveGrid');

  // STATE
  const [gridApi, setGridApi] = useState(null);
  const [cols, setCols] = useState(getGridColumns());

  const scrollToNewRow = useSelector(selectScrollToNewRow);

  // EFFECTS
  // New row scroll handler
  useEffect(() => {
    if (gridApi && scrollToNewRow && scrollToNewRow.gridId === gridId) {
      setTimeout(() => {
        // Get the row index by the id
        const rowNode = gridApi.getRowNode(scrollToNewRow.rowId);
        if (rowNode) {
          gridApi.ensureIndexVisible(rowNode.rowIndex, 'top');

          // Clear ranges
          gridApi.clearRangeSelection();

          gridApi.setFocusedCell(rowNode.rowIndex, newRowFocusCell);
        }
      }, 0);

      dispatch(clearScrollToNewRow());
    }
    // eslint-disable-next-line
  }, [scrollToNewRow]);

  // Presence change detection
  useEffect(() => {
    // @Todo: Maybe limit this in future to only the impacted cells?
    gridApi?.refreshCells({ force: true, suppressFlash: true });
  }, [others, gridApi]);

  // AG GRID CONFIG
  const menuContext = useMemo(() => {
    return {
      rootBranch,
      gridApi,
      dataView,
      gridId,
    };
  }, [rootBranch, gridApi, dataView, gridId]);

  const gridContext = useMemo(() => {
    return {
      rootBranch,
      dataView,
      additionalData,
      gridId,
      mutateLiveCell,
      others,
    };
  }, [rootBranch, dataView, additionalData, gridId, mutateLiveCell, others]);

  const defaultColDef = useMemo(() => {
    return {
      enableCellChangeFlash: true,
      editable: true,
      sortable: false,
      // Stop default event handling of Enter key
      suppressKeyboardEvent: (params) => {
        // Allow normal keyboard events when cell is not editing
        if (!params.editing) return false;
        // Allow regular events when not using AutocompleteEditor
        if (
          params.colDef.cellEditor === 'AutocompleteEditor' ||
          params.colDef.cellEditor === 'SelectEditor'
        ) {
          // Disable Tab Out (Needed for autocomplete and multiselect editors)
          // to allow tab to select a suggested option
          const keyCode = params.event.keyCode;
          return keyCode === KEY_TAB || keyCode === KEY_ENTER;
        }
        return false;
      },
      valueSetter: (params) => {
        mutateLiveCell(params);
      },
    };
  }, [mutateLiveCell]);

  // AG GRID EVENTS
  const handleGridReady = useCallback((params) => {
    setGridApi(params.api);
  }, []);

  const processDataFromClipboard = useCallback((params) => {
    const data = params.data;
    const processedData = data.map((row) => row.map((cell) => `_PASTE_OPERATION>${cell}`));
    return processedData;
  }, []);

  const handleDrag = useCallback(
    (params) => {
      // Compile a list of all selected cells from ranges
      const selectedCells = [];
      const ranges = params.api.getCellRanges();
      const columnDataHints = new Set();

      if (ranges) {
        ranges.forEach((range) => {
          let startRow = Math.min(range.startRow.rowIndex, range.endRow.rowIndex);
          let endRow = Math.max(range.startRow.rowIndex, range.endRow.rowIndex);
          for (let rowIndex = startRow; rowIndex <= endRow; rowIndex++) {
            let rowNode = params.api.getDisplayedRowAtIndex(rowIndex);
            if (rowNode) {
              // Add an object to the array that has the rowId and the selected column id from the range
              const isGroupedRow = rowNode?.data?._id === undefined;
              if (!isGroupedRow) {
                range.columns.forEach((column) => {
                  selectedCells.push([dataView, rowNode.data._id, column.colId]);
                });
              }
            }
          }
          // Gather the column dataHints to determine which hint to display
          range.columns.forEach((column) => {
            const field = column.colId;
            columnDataHints.add(baseColumns.find((col) => col.field === field)?.dataHint);
          });
        });

        const dataHints = Array.from(columnDataHints);
        let compiledHint = [];

        if (selectedCells.length === 1) {
          compiledHint.push(DATA_GRID_CELL_HINTS.singleCell);
        }
        if (selectedCells.length > 1) {
          compiledHint.push(DATA_GRID_CELL_HINTS.multiCell);
        }

        // Display any DATA_GRID_CELL_HINTS for which ALL dataHints are eligible
        for (const [key, columnData] of Object.entries(dataHintLegend)) {
          if (columnData.context === 'single' && selectedCells.length > 1) continue;
          if (columnData.context === 'multi' && selectedCells.length === 1) continue;

          // Check if all the dataHints are in the columnData array
          if (dataHints.every((hint) => columnData.dataTypes.includes(hint))) {
            compiledHint.push(DATA_GRID_CELL_HINTS[key]);
          }
        }
        dispatchHint(compiledHint.join(' | '));
        updateMyPresence({ selectedCells });
      }
    },
    [dataView, updateMyPresence, dispatchHint]
  );

  const handleHeaderMouseOver = useCallback(
    (params) => {
      dispatchHint(
        'Click to sort | [Shift] click to sort multiple columns | Drag to reorder | Menu icon for options'
      );
    },
    [dispatchHint]
  );
  const handleHeaderMouseLeave = useCallback(() => {
    dispatchHint('');
  }, [dispatchHint]);
  const handleToolPanelVisibility = useCallback(
    (params) => {
      if (params.key === 'columns' && params.visible) {
        dispatchHint(
          'Columns Panel: Check/uncheck for visibility | Drag to reorder | Drag to Row Groups for grouping |  Drag to Values for aggregation if available'
        );
      }
    },
    [dispatchHint]
  );

  // AG GRID DATA
  const rowData = useMemo(() => {
    if (!data || cols?.columnDefs?.length === 0) return [];
    const res = [];
    // Iterate the map data and convert to array
    data.forEach((val, key) => {
      res.push({ _id: key, ...val });
    });

    return res;
  }, [data, cols]);

  const getRowId = useCallback((params) => {
    return params.data._id;
  }, []);

  return (
    <>
      <div className='datagrid-top-menu-container'>
        {topMenuComponent && React.createElement(topMenuComponent, { context: menuContext })}
      </div>
      <div
        className='ag-theme-quartz-dark borderless'
        style={{ width: '100%', height: 'calc(100% - 3rem)' }}
      >
        <AgGridReact
          context={gridContext}
          onGridReady={handleGridReady}
          immutableData={true}
          getRowId={getRowId}
          rowData={rowData}
          defaultColDef={defaultColDef}
          columnDefs={cols}
          fillOperation={genericFillHandler}
          suppressColumnStateEvents={false}
          processDataFromClipboard={processDataFromClipboard}
          getContextMenuItems={(params) => getContextMenuItems(params, deleteLiveRows)}
          rowDragManaged={true}
          onDragStopped={handleDrag}
          onCellClicked={handleDrag}
          onColumnHeaderMouseOver={handleHeaderMouseOver}
          onColumnHeaderMouseLeave={handleHeaderMouseLeave}
          onToolPanelVisibleChanged={handleToolPanelVisibility}
          {...gridConfig}
        />
      </div>
    </>
  );
}

export default LiveGrid;

import {
  Button,
  NumberInput,
  Popover,
  Group,
  Combobox,
  useCombobox,
  InputBase,
  ActionIcon,
} from '@mantine/core';

import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { FIXTURE_CLASS, FIXTURE_TYPE } from '../colDefs/fixtureDefProperties';
import { nanoid } from 'nanoid';
import { setScrollToNewRow } from '../../../features/grid/gridSlice';
import useHint from '../../../hooks/useHint';
import { selectLiveFixDefs, useMutation, useStorage } from '../../../app/liveblocksClient';
import { LiveObject } from '@liveblocks/core';

const NEW_FIXTURE_ROW_HINT = 'Add one or more new fixture rows to the grid';

function ActionCreateNewFixtureRow({ context }) {
  const [value, setValue] = useState(null);
  const [open, setOpen] = useState(false);
  const [count, setCount] = useState(1);

  // General
  const dispatch = useDispatch();
  const { rootBranch, gridId } = context;

  // LIVEBLOCKS
  const addLiveFixtureRow = useMutation(
    ({ storage }) => {
      for (let i = 0; i < count; i++) {
        const newRowId = nanoid(7);
        if (i === 0) {
          dispatch(setScrollToNewRow({ rowId: newRowId, gridId }));
        }
        const branch = storage.get(rootBranch);
        branch.set(newRowId, new LiveObject());
        if (value && value !== null) {
          storage.get(rootBranch).get(newRowId).set(FIXTURE_TYPE, value);
        }
      }

      setOpen(false);
    },
    [value, count]
  );

  // Hints
  const newFixtureRowHint = useHint(NEW_FIXTURE_ROW_HINT, 'ActionCreateNewFixtureRow');

  // Combobox data
  const defs = useStorage(selectLiveFixDefs);

  // Get a list of FIXTURE_CLASS options
  const fixtureClassSet = new Set();

  defs?.forEach((val, key) => {
    if (val[FIXTURE_CLASS]) {
      fixtureClassSet.add(val[FIXTURE_CLASS]);
    }
  });
  const fixtureClassOptions = [...fixtureClassSet, 'Other'];

  const data = fixtureClassOptions.map((label) => {
    return {
      label,
      options: [],
    };
  });

  defs?.forEach((val, key) => {
    const group = val[FIXTURE_CLASS] || 'Other';
    data.forEach((dataGroup) => {
      if (dataGroup.label === group) {
        dataGroup.options.push({
          id: key,
          value: val[FIXTURE_TYPE],
        });
      }
    });
  });

  data
    .find((g) => g.label === 'Other')
    .options.push({
      id: null,
      value: '(No Type)',
    });

  const allItems = data.reduce((acc, group) => [...acc, ...group.options.map((o) => o.value)], []);

  // Initialize Combobox
  const combobox = useCombobox({
    onDropdownClose: () => combobox.resetSelectedOption(),
  });

  const [search, setSearch] = useState('');

  const shouldFilterOptions = allItems.every((item) => item !== search);
  const filteredGroups = data.map((group) => {
    const filteredOptions = shouldFilterOptions
      ? group.options.filter((item) => item?.value?.toLowerCase().startsWith(search.toLowerCase()))
      : group.options;
    return {
      ...group,
      options: filteredOptions,
    };
  });

  const totalOptions = filteredGroups.reduce((acc, group) => acc + group.options.length, 0);

  const groups = filteredGroups.map((group) => {
    const options = group.options.map((item) => (
      <Combobox.Option value={item.id} key={item.id}>
        {item.value}
      </Combobox.Option>
    ));

    return (
      <Combobox.Group label={group.label} key={group.label}>
        {options}
      </Combobox.Group>
    );
  });

  const selectedDefLabel = defs?.get(value)?.[FIXTURE_TYPE] || 'No Type';
  return (
    <>
      <Popover
        withArrow
        withinPortal
        keepMounted={false}
        opened={open}
        onClose={() => {
          setOpen(false);
          setValue(null);
          setSearch('');
          setCount(1);
        }}
        closeOnClickOutside={!combobox.dropdownOpened}
      >
        <Popover.Target>
          <ActionIcon
            className='action-with-dropdown'
            variant='light'
            size='lg'
            onClick={() => setOpen(!open)}
            ref={newFixtureRowHint}
          >
            <span className='material-symbols-rounded'>add_box</span>
          </ActionIcon>
        </Popover.Target>
        <Popover.Dropdown>
          <form
            onSubmit={(e) => {
              e.preventDefault();
              addLiveFixtureRow();
            }}
          >
            <Group>
              <NumberInput
                style={{ width: 64 }}
                value={count}
                onChange={setCount}
                precision={0}
                min={1}
                max={99}
                autoFocus
              />
              <Combobox
                store={combobox}
                onOptionSubmit={(val) => {
                  setValue(val);
                  setSearch(defs?.[val]?.[FIXTURE_TYPE] || '');
                  combobox.closeDropdown();
                }}
              >
                <Combobox.Target>
                  <InputBase
                    rightSection={<Combobox.Chevron />}
                    value={search}
                    onChange={(event) => {
                      combobox.openDropdown();
                      combobox.updateSelectedOptionIndex();
                      setSearch(event.currentTarget.value);
                    }}
                    onClick={() => combobox.openDropdown()}
                    onFocus={() => combobox.openDropdown()}
                    onBlur={() => {
                      combobox.closeDropdown();
                    }}
                    placeholder='Search value'
                    rightSectionPointerEvents='none'
                  />
                </Combobox.Target>

                <Combobox.Dropdown>
                  <Combobox.Options>
                    {totalOptions > 0 ? groups : <Combobox.Empty>Nothing found</Combobox.Empty>}
                  </Combobox.Options>
                </Combobox.Dropdown>
              </Combobox>
            </Group>
          </form>
          <Button fullWidth mt='md' onClick={addLiveFixtureRow}>
            Add{' '}
            {count === 1
              ? `Fixture (${selectedDefLabel})`
              : `${count} Fixtures (${selectedDefLabel})`}
          </Button>
        </Popover.Dropdown>
      </Popover>
    </>
  );
}

export default ActionCreateNewFixtureRow;

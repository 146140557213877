import { configureStore } from '@reduxjs/toolkit';
import { coreApi } from './services/coreApi';
import { setupListeners } from '@reduxjs/toolkit/dist/query';

import userReducer from '../features/user/userSlice';
import gridReducer from '../features/grid/gridSlice';
import hintReducer from '../features/hints/hintsSlice';

import createSagaMiddleware from 'redux-saga';
import rootSaga from '../sagas/saga';

const sagaMiddleware = createSagaMiddleware();

export const store = configureStore({
  reducer: {
    [coreApi.reducerPath]: coreApi.reducer,
    user: userReducer,
    grid: gridReducer,
    hint: hintReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(sagaMiddleware).concat(coreApi.middleware),
});

sagaMiddleware.run(rootSaga);

setupListeners(store.dispatch);

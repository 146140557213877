import { LoadingOverlay } from '@mantine/core';
import { useLocalStorage } from '@mantine/hooks';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  abortPreauth,
  login,
  selectHasUserData,
  setPreauthData,
} from '../../features/user/userSlice';
import { useCreateSessionAuthorizationMutation } from '../../app/services/coreApi';

const DEV_LOGGING = process.env.NODE_ENV === 'development' && false;

function PreauthHandler() {
  const dispatch = useDispatch();
  const [initialized, setInitialized] = useState(false);
  const [userLocal, setUserLocal] = useLocalStorage({ key: 'userData', defaultValue: false });
  const hasUserData = useSelector(selectHasUserData);
  const [createSessionAuthorization, { data, isSuccess, error, isLoading }] =
    useCreateSessionAuthorizationMutation();

  useEffect(() => {
    // If not initialized, set to true and run the hooks again
    // This is necessary because the useLocalStorage hook has not had a chance yet to read the
    // key and defaults to False on first run. This might be something that can be cleaned up
    // with future Mantine updates?
    if (!initialized) {
      if (DEV_LOGGING) console.log('PREAUTH STATUS: INITIALIZE');
      setInitialized(true);
      return;
    }
    // If the env is development and REACT_APP_LOGIN_OVERRIDE is set, we can skip the preauth
    if (process.env.NODE_ENV === 'development' && process.env.REACT_APP_LOGIN_OVERRIDE === 'true') {
      if (DEV_LOGGING) console.warn('PREAUTH STATUS: DEVELOPMENT LOGIN OVERRIDE ACTIVE');
      dispatch(
        login({
          session_token: 'dev',
          session_jwt: 'dev',
          user: { user_id: 'user-test-442fd357-5eb0-4073-be97-5a8b03070f8c' },
        })
      );
      return;
    }
    // If there's no userData in the store, proceed as unauthenticated
    if (!userLocal) {
      if (DEV_LOGGING) console.log('PREAUTH STATUS: ABORT - No Local UserData');
      dispatch(abortPreauth());
      return;
    }
    // If there is user data but it's not in the store yet, dispatch it to the store
    // This ensures the header will be populated with the token on fetch
    if (!hasUserData) {
      if (DEV_LOGGING) console.log('PREAUTH STATUS: DISPATCH TO STORE - Populate UserData');
      dispatch(setPreauthData(userLocal));
      return;
    }
    // If the user data is in the store, let's call the authorization endpoint
    // to ensure the token is still valid
    if (!isLoading) {
      if (DEV_LOGGING) console.log('PREAUTH STATUS: CREATE SESSION AUTH - Call auth endpoint');
      createSessionAuthorization();
    }
    // If the authorization endpoint returns a success, we're good to go
    if (isSuccess) {
      if (DEV_LOGGING) console.log('PREAUTH STATUS: SUCCESS - Proceed to app');
      dispatch(login(data));
    }
    if (error) {
      console.log(`PREAUTH ERROR: ${JSON.stringify(error)}`);
      dispatch(abortPreauth());
    }

    // eslint-disable-next-line
  }, [initialized, userLocal, hasUserData, isSuccess, isLoading, error]);

  return (
    <LoadingOverlay
      visible={true}
      styles={{
        overlay: {
          backgroundColor: '#13141D',
        },
      }}
    />
  );
}

export default PreauthHandler;

import { Center, Loader, Paper, Text } from '@mantine/core';
import React, { useEffect } from 'react';
import { useGetMagicLinkAuthorizationQuery } from '../../app/services/coreApi';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { login } from '../../features/user/userSlice';
import { useLocalStorage } from '@mantine/hooks';

function ValidateMagicLink() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [userLocalStorage, setUserLocalStorage] = useLocalStorage({
    key: 'userData',
    defaultValue: false,
  });

  const token = searchParams.get('token');

  const { data, error, isSuccess } = useGetMagicLinkAuthorizationQuery(token);

  useEffect(() => {
    if (!isSuccess) return;
    dispatch(login(data));
    setUserLocalStorage({
      session_token: data.session_token,
      session_jwt: data.session_jwt,
      user_id: data.user_id,
    });
    navigate('/', { replace: true });
    // eslint-disable-next-line
  }, [data, isSuccess]);

  return (
    <div
      style={{
        width: '100vw',
        height: '100vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Paper radius='md' p='xl' withBorder w='500px' pos='relative'>
        <Text size='lg' align='center'>
          Welcome back!
        </Text>

        <Text mt='xl' align='center'>
          Please wait while we authenticate you
        </Text>

        <Center mt='xl'>
          {error ? (
            <Text align='center' color='red' size='sm'>
              {error?.data?.message || 'General Error'}
            </Text>
          ) : (
            <Loader />
          )}
        </Center>
      </Paper>
    </div>
  );
}

export default ValidateMagicLink;
